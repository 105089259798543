export enum Actions {
  CLICKED = 'clicked',
  TYPED = 'typed',
}

export enum Pages {
  HOMEPAGE = 'homepage',
  SPECIALTY_PAGE = 'specialty list page',
  OPERATORS_PAGE = 'operadoras list page',
  UNIT_PAGE = 'unit page',
  PROFESSIONALS_LIST_PAGE = 'professionals list page',
  PROFESSIONAL_PAGE = 'professional page',
  IDENTIFY_PAGE = 'identify page',
  OPERATOR_IDENTIFY_PAGE = 'operator identify page',
  NAME_CONFIRMATION_PAGE = 'name confirmation page',
  CONTACT_INFORMATION_PAGE = 'contact information page',
  OTP_CONFIRMATION_PAGE = 'OTP confirmation page',
  ADDRESS_PAGE = 'address page',
  CONFIRMATION_PAGE = 'confirmation page',
  APPOINTMENT_ERROR_PAGE = 'appointment error page',
}

export enum Events {
  ADDRESS_CLICK = 'Clique endereco autocomplete',
  HOME_HEADER = 'Clique menu home header',
  CAROUSEL_CONTROL = 'Controle carrossel',
  APPOINTMENT_TYPE = 'Clique tipo consulta',
  HEADER_AGENDAMENTO = 'header agendamento',
  TOKEN_SUCCESS = 'Token confirmado',
  TOKEN_FAIL = 'Token incorreto',
  TOKEN_RESEND = 'Reenvio token',
  CREATE_APPOINTMENT = 'Agendamento criado Acropolis',
  CONFIRM_INFO = 'Confirmacao info contato',
  NAME_SELECT = 'Selecionou nome elegibilidade',
  DATE_SELECT = 'Alterou data busca agendamento',
  TYPED_CPF = 'Digitou cpf valido',
  ELEGIBILITY_SUCCESS = 'Sucesso elegibilidade cpf',
  ELEGIBILITY_FAIL = 'Falha elegibilidade cpf',
  PROFESSIONAL_SELECT = 'Selecionou profissional',
  UNIT_SELECT = 'Selecionou unidade',
  NOT_FOUND_SEARCH = 'Busca sem resultado',
  NOT_FOUND_BUTTON = 'Link Nao encontrei o que buscava',
  SEARCH_UNIT_TYPING = 'Uso campo buscar unidade',
  SEARCH_SPECIALTY_TYPING = 'Uso campo buscar especialidade',
  SEARCH_OPERATOR_TYPING = 'Uso campo buscar operadora',
  SPECIALTY_SELECT = 'Selecionou especialidade',
  SEE_OTHER_SPECIALTIES = 'Botao ver outras especialidades',
  APPOINTMENT_INSTRUCTIONS = 'Ver instrucoes consulta',
  CHANGE_UNIT = 'Selecionou outra unidade',
  SLOT_SELECT = 'Selecionou um slot',
  OPERATOR_SELECT = 'Selecionou uma operadora',
  APPOINTMENT_ERROR_EVENTS = 'Actions erro agendamento',
  BACKLINK = 'Clique voltar pagina anterior',
  LOAD_MORE_SLOTS = 'Load more slots',
  CHANGE_DATE_SLOTS = 'Change date slots',
  CONTACT_LINK = 'Link contato Saludia',
  CONFIRM_OPERATOR_INFO = 'Confirmacao info operadora',
}

export enum Types {
  CONSULTA = 'consulta',
  TELECONSULTA = 'teleconsulta',
}
