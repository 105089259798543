import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgWallet = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#00658A"
        fillRule="evenodd"
        d="M6 3.15A2.85 2.85 0 0 0 3.15 6v12A2.85 2.85 0 0 0 6 20.85h12A1.85 1.85 0 0 0 19.85 19v-2.15H20c.47 0 .85-.38.85-.85v-4a.85.85 0 0 0-.85-.85h-.15V9A1.85 1.85 0 0 0 18 7.15h-.15V5A1.85 1.85 0 0 0 16 3.15zm12.15 8V9a.15.15 0 0 0-.15-.15H6c-.4 0-.791-.084-1.15-.242V18A1.15 1.15 0 0 0 6 19.15h12a.15.15 0 0 0 .15-.15v-2.15H16a2.85 2.85 0 0 1 0-5.7zm.851 1.7h.149v2.3H16a1.15 1.15 0 0 1 0-2.3h2.999zM5.187 5.187A1.15 1.15 0 0 1 6 4.85h10a.15.15 0 0 1 .15.15v2.15H6a1.15 1.15 0 0 1-.813-1.963"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgWallet;
