import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMobile = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m6.897 5.019.001-.003zm.003-.006a.15.15 0 0 1 .028-.029A.431.431 0 0 1 7.2 4.9h9.6a.43.43 0 0 1 .272.084.15.15 0 0 1 .028.029v13.974a.151.151 0 0 1-.028.029.43.43 0 0 1-.272.084H7.2a.431.431 0 0 1-.272-.084.151.151 0 0 1-.028-.029zM6.897 18.98l.001.003zm10.206 0-.001.003zm0-13.962-.001-.003zM5.1 5c0-1.196 1.1-1.9 2.1-1.9h9.6c1 0 2.1.704 2.1 1.9v14c0 1.196-1.1 1.9-2.1 1.9H7.2c-1 0-2.1-.704-2.1-1.9z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 16.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V17a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMobile;
