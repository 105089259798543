import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgShare = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M6 9.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2M2.1 12a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0M18 3.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2M14.1 6a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0M18 15.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2M14.1 18a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M16.1 6.888a.9.9 0 0 1-.388 1.212l-6.6 3.4a.9.9 0 0 1-.824-1.6l6.6-3.4a.9.9 0 0 1 1.212.388M7.9 12.888a.9.9 0 0 1 1.212-.388l6.6 3.4a.9.9 0 0 1-.824 1.6l-6.6-3.4a.9.9 0 0 1-.388-1.212"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgShare;
