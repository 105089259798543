import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgFingerPrint = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M18.436 6.23a.9.9 0 0 1 1.235.306 8.9 8.9 0 0 1 1.229 5.512V13a5.103 5.103 0 0 0 .68 2.551.9.9 0 1 1-1.56.898 6.9 6.9 0 0 1-.92-3.45V12a.89.89 0 0 1 .005-.098 7.1 7.1 0 0 0-.976-4.438.9.9 0 0 1 .307-1.235M12 7.9A3.1 3.1 0 0 0 8.9 11a.9.9 0 1 1-1.8 0 4.9 4.9 0 1 1 9.8 0v1a9.1 9.1 0 0 0 1.82 5.46.9.9 0 0 1-1.44 1.08A10.9 10.9 0 0 1 15.1 12v-1A3.1 3.1 0 0 0 12 7.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 10.1a.9.9 0 0 1 .9.9v2.001a13.1 13.1 0 0 0 2.34 7.486.9.9 0 0 1-1.48 1.026A14.9 14.9 0 0 1 11.1 13V11a.9.9 0 0 1 .9-.9M7.895 14.106a.9.9 0 0 1 .999.79 17.1 17.1 0 0 0 1.71 5.7.9.9 0 0 1-1.608.809 18.9 18.9 0 0 1-1.89-6.3.9.9 0 0 1 .79-.999"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M15.55 4.83A7.1 7.1 0 0 0 4.9 10.997v1.032a21.1 21.1 0 0 0 .863 6.714.9.9 0 0 1-1.726.512 22.9 22.9 0 0 1-.937-7.27V11a8.9 8.9 0 0 1 13.35-7.73.9.9 0 0 1-.9 1.558"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgFingerPrint;
