import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgApple = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M15.241 11.015c.029 3.021 2.65 4.026 2.68 4.04-.023.07-.42 1.432-1.382 2.839-.832 1.216-1.695 2.427-3.056 2.452-1.336.025-1.766-.792-3.294-.792s-2.005.767-3.27.817c-1.314.05-2.313-1.315-3.152-2.527C2.052 15.366.742 10.841 2.5 7.787c.874-1.517 2.436-2.478 4.13-2.502 1.29-.025 2.507.867 3.295.867.788 0 2.267-1.072 3.822-.915.65.027 2.478.263 3.65 1.98-.094.059-2.18 1.273-2.157 3.798M12.73 3.597c.698-.843 1.167-2.018 1.039-3.187-1.005.04-2.22.67-2.94 1.513-.647.747-1.212 1.942-1.06 3.088 1.12.087 2.264-.57 2.961-1.414"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgApple;
