import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLinkedin = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#linkedin_svg__a)">
        <path
          fill="#0C67C2"
          d="M2.627 22.379h3.775V8.415H2.627zM4.515 2.25A2.255 2.255 0 0 0 2.25 4.515a2.255 2.255 0 0 0 2.265 2.264 2.255 2.255 0 0 0 2.264-2.264A2.255 2.255 0 0 0 4.515 2.25m8.303 8.303V8.415H9.044v13.964h3.774v-7.17c0-4.027 5.158-4.278 5.158 0v7.17h3.774v-8.555c0-6.793-7.171-6.542-8.932-3.27"
        />
      </g>
      <defs>
        <clipPath id="linkedin_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgLinkedin;
