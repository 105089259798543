import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgChat = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12.388 4.887c-1.99-.081-3.933.484-5.467 1.573-1.534 1.088-2.545 2.615-2.883 4.28-.336 1.664.015 3.387 1.008 4.856a.9.9 0 0 1 .108.789l-.803 2.407 3.162-.672a.9.9 0 0 1 .58.07c1.734.842 3.763 1.099 5.704.714 1.94-.384 3.635-1.378 4.782-2.77 1.145-1.388 1.67-3.08 1.505-4.761-.165-1.684-1.013-3.28-2.423-4.482-1.412-1.204-3.284-1.924-5.273-2.004M2.95 20.899a.892.892 0 0 0 .252-.022l4.39-.934a10.916 10.916 0 0 0 6.555.727c2.317-.459 4.39-1.654 5.821-3.39 1.434-1.74 2.121-3.902 1.908-6.083-.214-2.18-1.309-4.193-3.047-5.675-1.736-1.48-4-2.338-6.369-2.434-2.368-.096-4.707.574-6.581 1.904-1.876 1.331-3.17 3.242-3.605 5.392-.406 2.007-.032 4.066 1.036 5.838l-1.159 3.479a.894.894 0 0 0 .178.899.894.894 0 0 0 .62.299"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 11.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V12a.9.9 0 0 1 .9-.9M8 11.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V12a.9.9 0 0 1 .9-.9M16 11.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V12a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgChat;
