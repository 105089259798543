import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCheckCircleColored = ({
  title,
  titleId,
  size = 16,
  className,
}: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#check-circle-colored_svg__a)">
        <path fill="#fff" d="M0 8a8 8 0 1 1 16 0A8 8 0 1 1 0 8" />
        <path
          fill="#10B981"
          d="M11.333 2.226a6.667 6.667 0 1 1-9.996 5.99L1.333 8l.004-.216a6.667 6.667 0 0 1 9.996-5.558m-.862 3.969a.667.667 0 0 0-.88-.055l-.062.055L7.333 8.39l-.862-.862-.062-.055a.667.667 0 0 0-.936.935l.056.063 1.333 1.333.063.056a.667.667 0 0 0 .817 0l.063-.056 2.666-2.666.056-.063a.667.667 0 0 0-.056-.88"
        />
      </g>
      <defs>
        <clipPath id="check-circle-colored_svg__a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCheckCircleColored;
