import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgAmex = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#163D9E"
        fillRule="evenodd"
        d="M24 20.858V21l-5.47-.011-1.241-1.397-1.325 1.397H7.75v-8.993H4L7.976 3h4.062l.529 1.057V3h4.75l1.042 2.322L19.377 3H24v9.136l-3.863 4.38zM11.218 4.278h-2.32l-2.789 6.454h1.696l.6-1.446h3.242l.6 1.447h3.166V5.674l2.256 5.059h1.377l2.244-5.059.012 5.059h1.54V4.277h-2.53l-1.878 4.374-2.037-4.374h-2.542v6.1zm-2.26 3.67 1.068-2.578 1.067 2.578zm.184 5.322H15.4l1.914 2.114 1.975-2.114h1.914l-2.908 3.245 2.908 3.206h-2l-1.914-2.138-1.986 2.139h-6.16zm1.545 1.337v1.178h3.414v1.314h-3.414v1.286h3.83l1.779-1.895-1.704-1.884z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgAmex;
