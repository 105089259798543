import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgEyeOff = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.364 2.364a.9.9 0 0 1 1.272 0l18 18a.9.9 0 1 1-1.272 1.272l-18-18a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10.495 9.364a.9.9 0 0 1 0 1.273 2.028 2.028 0 1 0 2.869 2.87.9.9 0 0 1 1.272 1.273 3.83 3.83 0 0 1-5.413-5.417.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11.997 5.9a8.566 8.566 0 0 0-2.386.33.9.9 0 0 1-.496-1.73c.939-.269 1.91-.403 2.887-.4 4.43 0 8.015 2.616 10.78 7.454a.9.9 0 0 1 0 .893c-.806 1.409-1.679 2.63-2.624 3.652a.9.9 0 1 1-1.322-1.222c.74-.8 1.448-1.758 2.12-2.877C18.442 7.814 15.449 5.9 12 5.9zm-4.62.254a.9.9 0 0 1-.24 1.25C5.666 8.402 4.295 9.918 3.043 12c2.516 4.186 5.51 6.1 8.957 6.1 1.76 0 3.369-.496 4.854-1.497a.9.9 0 0 1 1.006 1.492C16.083 19.294 14.124 19.9 12 19.9c-4.432 0-8.017-2.616-10.781-7.453a.9.9 0 0 1 0-.894c1.415-2.476 3.044-4.375 4.908-5.639a.9.9 0 0 1 1.25.24"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgEyeOff;
