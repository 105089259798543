import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDoorExit = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M13 11.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V12a.9.9 0 0 1 .9-.9M2.1 21a.9.9 0 0 1 .9-.9h18a.9.9 0 1 1 0 1.8H3a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7 3.9A1.1 1.1 0 0 0 5.9 5v16a.9.9 0 1 1-1.8 0V5A2.9 2.9 0 0 1 7 2.1h7.5a.9.9 0 1 1 0 1.8zm10 8.7a.9.9 0 0 1 .9.9V21a.9.9 0 1 1-1.8 0v-7.5a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M17.36 3.27a.925.925 0 0 0 0 1.306l1.497 1.5h-4.936A.922.922 0 0 0 13 7c0 .51.412.923.92.923h4.937L17.36 9.424a.925.925 0 0 0 0 1.306c.36.36.943.36 1.302 0l3.068-3.077a.921.921 0 0 0 0-1.306L18.662 3.27a.919.919 0 0 0-1.302 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDoorExit;
