import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPasteList = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4.95 4.95A2.9 2.9 0 0 1 7 4.1h2a.9.9 0 0 1 0 1.8H7A1.1 1.1 0 0 0 5.9 7v12A1.1 1.1 0 0 0 7 20.1h10a1.1 1.1 0 0 0 1.1-1.1V7A1.1 1.1 0 0 0 17 5.9h-2a.9.9 0 1 1 0-1.8h2A2.9 2.9 0 0 1 19.9 7v12a2.9 2.9 0 0 1-2.9 2.9H7A2.9 2.9 0 0 1 4.1 19V7a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11 3.9a1.1 1.1 0 0 0 0 2.2h2a1.1 1.1 0 0 0 0-2.2zM8.1 5A2.9 2.9 0 0 1 11 2.1h2a2.9 2.9 0 0 1 0 5.8h-2A2.9 2.9 0 0 1 8.1 5M8.1 12a.9.9 0 0 1 .9-.9h.01a.9.9 0 1 1 0 1.8H9a.9.9 0 0 1-.9-.9M12.1 12a.9.9 0 0 1 .9-.9h2a.9.9 0 0 1 0 1.8h-2a.9.9 0 0 1-.9-.9M8.1 16a.9.9 0 0 1 .9-.9h.01a.9.9 0 1 1 0 1.8H9a.9.9 0 0 1-.9-.9M12.1 16a.9.9 0 0 1 .9-.9h2a.9.9 0 0 1 0 1.8h-2a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPasteList;
