import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgConnect = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#connect_svg__a)">
        <path
          fillRule="evenodd"
          d="M12 3.875a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25M9.125 5a2.875 2.875 0 1 1 5.75 0 2.875 2.875 0 0 1-5.75 0"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M10.86 5.03a.875.875 0 0 1-.63 1.066 7.118 7.118 0 0 0-3.375 1.973.875.875 0 1 1-1.264-1.21A8.868 8.868 0 0 1 9.795 4.4a.875.875 0 0 1 1.065.63m2.275.001a.875.875 0 0 1 1.065-.63 8.867 8.867 0 0 1 4.15 2.398.875.875 0 0 1-1.253 1.222 7.117 7.117 0 0 0-3.33-1.925.875.875 0 0 1-.632-1.065m-8.54 4.81c.463.14.724.629.585 1.091A7.15 7.15 0 0 0 5.13 14.9a.875.875 0 1 1-1.688.464A8.894 8.894 0 0 1 3.125 13c0-.872.127-1.739.38-2.573a.875.875 0 0 1 1.09-.585M5.64 17.93a.875.875 0 0 1 1.238.022 7.119 7.119 0 0 0 3.347 1.95.875.875 0 0 1-.436 1.695 8.868 8.868 0 0 1-4.17-2.43.875.875 0 0 1 .021-1.237"
          clipRule="evenodd"
        />
        <path d="M13.331 20.92a.888.888 0 0 1 .596-1.106 7.232 7.232 0 0 0 1.92-.9c.557-.368.735-.435 1.153-.914a.888.888 0 1 1 1.338 1.168c-.521.598-.82.77-1.51 1.227a9.006 9.006 0 0 1-2.391 1.12.888.888 0 0 1-1.106-.595M19.591 15.506a.888.888 0 0 1-.674-1.06 7.225 7.225 0 0 0-.225-3.925.888.888 0 0 1 1.679-.58 9 9 0 0 1 .28 4.89.888.888 0 0 1-1.06.675" />
        <path
          fillRule="evenodd"
          d="M5 15.875a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25M2.125 17a2.875 2.875 0 1 1 5.75 0 2.875 2.875 0 0 1-5.75 0M19 15.875a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25M16.125 17a2.875 2.875 0 1 1 5.75 0 2.875 2.875 0 0 1-5.75 0"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="connect_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgConnect;
