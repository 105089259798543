import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDoctor = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.95 3.95A2.9 2.9 0 0 1 5 3.1h1a.9.9 0 0 1 0 1.8H5A1.1 1.1 0 0 0 3.9 6v3.5a4.6 4.6 0 0 0 9.2 0V6A1.1 1.1 0 0 0 12 4.9h-1a.9.9 0 1 1 0-1.8h1A2.9 2.9 0 0 1 14.9 6v3.5a6.4 6.4 0 0 1-12.8 0V6a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M20 11.1a.9.9 0 0 1 .9.9v3a6.9 6.9 0 0 1-13.8 0 .9.9 0 1 1 1.8 0 5.1 5.1 0 0 0 10.2 0v-3a.9.9 0 0 1 .9-.9M11 2.1a.9.9 0 0 1 .9.9v2a.9.9 0 0 1-1.8 0V3a.9.9 0 0 1 .9-.9M6 2.1a.9.9 0 0 1 .9.9v2a.9.9 0 0 1-1.8 0V3a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M20 8.9a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2M17.1 10a2.9 2.9 0 1 1 5.8 0 2.9 2.9 0 0 1-5.8 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDoctor;
