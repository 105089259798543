import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgShieldCheck = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.333 2.2a1 1 0 0 1 1.334 0 10.955 10.955 0 0 0 7.9 2.786 1 1 0 0 1 1.019.748C21.856 6.778 22 7.874 22 9c0 6.059-4.144 11.147-9.75 12.59a1 1 0 0 1-.5 0C6.145 20.147 2 15.06 2 9c0-1.127.144-2.222.414-3.266a1 1 0 0 1 1.019-.749 10.955 10.955 0 0 0 7.9-2.786M4.181 6.998C4.062 7.647 4 8.316 4 9c0 5.034 3.382 9.28 8 10.586 4.618-1.305 8-5.552 8-10.586 0-.684-.062-1.353-.181-2.001a12.946 12.946 0 0 1-7.82-2.751 12.946 12.946 0 0 1-7.818 2.75m11.526 2.294a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414L11 12.586l3.293-3.293a1 1 0 0 1 1.414 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgShieldCheck;
