import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgGoogle = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M20.288 10.635c0-.658-.059-1.283-.159-1.891h-9.417v3.758h5.392c-.241 1.233-.95 2.275-2 2.983v2.5h3.217c1.883-1.741 2.967-4.308 2.967-7.35"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10.713 20.41c2.7 0 4.958-.9 6.608-2.425l-3.217-2.5c-.9.6-2.041.967-3.391.967-2.609 0-4.817-1.759-5.609-4.134H1.787v2.576c1.642 3.266 5.017 5.516 8.926 5.516"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5.104 12.319a5.803 5.803 0 0 1-.316-1.909c0-.666.116-1.308.316-1.908V5.927H1.788a9.884 9.884 0 0 0 0 8.967z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10.713 4.369c1.475 0 2.791.508 3.833 1.5l2.85-2.85C15.67 1.402 13.412.41 10.713.41c-3.909 0-7.284 2.25-8.926 5.517l3.317 2.575c.792-2.375 3-4.133 5.609-4.133"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgGoogle;
