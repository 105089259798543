import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgImageOff = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.364 2.364a.9.9 0 0 1 1.272 0l18 18a.9.9 0 0 1-1.272 1.272l-18-18a.9.9 0 0 1 0-1.272M14.1 8a.9.9 0 0 1 .9-.9h.01a.9.9 0 0 1 0 1.8H15a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7.1 4a.9.9 0 0 1 .9-.9h9A3.9 3.9 0 0 1 20.9 7v9a.9.9 0 0 1-1.8 0V7A2.1 2.1 0 0 0 17 4.9H8a.9.9 0 0 1-.9-.9m-2.846.23a.9.9 0 0 1 1.27.003l14.234 14.253a.9.9 0 0 1 0 1.273A3.9 3.9 0 0 1 17 20.9H7A3.9 3.9 0 0 1 3.1 17V7c0-1.083.443-2.064 1.154-2.77m.772 2.051A2.096 2.096 0 0 0 4.9 7v10A2.1 2.1 0 0 0 7 19.1h10a2.1 2.1 0 0 0 .705-.122z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="m8.63 11.643-3.994 3.993a.9.9 0 1 1-1.272-1.272l4-4 .012-.013c.6-.576 1.332-.92 2.124-.92s1.525.344 2.124.92l.012.013 5 5a.9.9 0 0 1-1.272 1.272l-4.994-4.993c-.326-.312-.628-.413-.87-.413s-.544.1-.87.413M17.37 13.643c-.364-.347-.695-.435-.959-.408a.9.9 0 1 1-.183-1.79c.89-.091 1.726.262 2.396.906l.012.013 2 2a.9.9 0 1 1-1.272 1.272z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgImageOff;
