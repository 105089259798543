import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPaypal = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#023DD1"
        fillRule="evenodd"
        d="m7.884 22.413.375-2.46-.834-.02H3.44L6.21 1.788a.242.242 0 0 1 .076-.143.222.222 0 0 1 .148-.056h6.719c2.23 0 3.77.48 4.573 1.427.377.444.617.908.733 1.419.121.536.123 1.176.005 1.957l-.009.057v.5l.377.221c.317.174.57.374.763.601.322.38.53.863.618 1.435.091.589.061 1.29-.088 2.082-.172.912-.45 1.706-.825 2.356a4.788 4.788 0 0 1-1.307 1.48 5.222 5.222 0 0 1-1.76.822c-.65.175-1.39.263-2.201.263h-.523c-.374 0-.737.139-1.022.388a1.65 1.65 0 0 0-.533.983l-.04.222-.661 4.335-.03.159c-.008.05-.022.075-.042.092a.108.108 0 0 1-.068.026z"
        clipRule="evenodd"
      />
      <path
        fill="#DCE4FA"
        fillRule="evenodd"
        d="M19.189 6.506c-.02.132-.043.268-.069.407-.886 4.702-3.917 6.326-7.788 6.326H9.36a.967.967 0 0 0-.947.838l-1.295 8.49a.516.516 0 0 0 .498.603h3.496a.85.85 0 0 0 .831-.733l.034-.183.659-4.318.042-.237a.85.85 0 0 1 .83-.734h.524c3.387 0 6.039-1.421 6.813-5.535.324-1.718.157-3.153-.7-4.162a3.355 3.355 0 0 0-.957-.762"
        clipRule="evenodd"
      />
      <path
        fill="#133385"
        fillRule="evenodd"
        d="M18.262 6.124a6.76 6.76 0 0 0-.862-.198 10.609 10.609 0 0 0-1.738-.13h-5.266c-.13 0-.253.03-.363.084a.865.865 0 0 0-.467.65l-1.12 7.333-.032.214a.967.967 0 0 1 .946-.838h1.971c3.871 0 6.903-1.625 7.789-6.326.026-.14.048-.275.068-.407a4.627 4.627 0 0 0-.926-.382"
        clipRule="evenodd"
      />
      <path
        fill="#163D9E"
        fillRule="evenodd"
        d="M9.567 6.53a.862.862 0 0 1 .467-.649.813.813 0 0 1 .363-.085h5.266c.624 0 1.206.042 1.737.13a6.798 6.798 0 0 1 1.06.261c.262.09.505.197.73.319.263-1.738-.003-2.92-.912-3.992C17.277 1.334 15.469.83 13.155.83H6.435a.97.97 0 0 0-.949.839L2.688 20.002a.59.59 0 0 0 .57.69h4.147z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPaypal;
