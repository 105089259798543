import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPrinter = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.95 8.95A2.9 2.9 0 0 1 5 8.1h14a2.9 2.9 0 0 1 2.9 2.9v4a2.9 2.9 0 0 1-2.9 2.9h-2a.9.9 0 1 1 0-1.8h2a1.1 1.1 0 0 0 1.1-1.1v-4A1.1 1.1 0 0 0 19 9.9H5A1.1 1.1 0 0 0 3.9 11v4A1.1 1.1 0 0 0 5 16.1h2a.9.9 0 1 1 0 1.8H5A2.9 2.9 0 0 1 2.1 15v-4a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M6.95 2.95A2.9 2.9 0 0 1 9 2.1h6A2.9 2.9 0 0 1 17.9 5v4a.9.9 0 1 1-1.8 0V5A1.1 1.1 0 0 0 15 3.9H9A1.1 1.1 0 0 0 7.9 5v4a.9.9 0 0 1-1.8 0V5a2.9 2.9 0 0 1 .85-2.05M9 13.9A1.1 1.1 0 0 0 7.9 15v4A1.1 1.1 0 0 0 9 20.1h6a1.1 1.1 0 0 0 1.1-1.1v-4a1.1 1.1 0 0 0-1.1-1.1zM6.1 15A2.9 2.9 0 0 1 9 12.1h6a2.9 2.9 0 0 1 2.9 2.9v4a2.9 2.9 0 0 1-2.9 2.9H9A2.9 2.9 0 0 1 6.1 19z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPrinter;
