import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgReferral = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 328 290"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#referral_svg__a)">
        <path
          fill="url(#referral_svg__b)"
          d="M23.5 206c0-77.32 62.68-140 140-140s140 62.68 140 140v89h-280z"
        />
        <path
          fill="#263238"
          d="M67.472 37.342c4.941 13.506-8.195 20.296-16.804 40.192-8.61 19.896 12.16 43.624 44.038 44.142 31.878.517 32.884-30.015 27.943-51.923-4.941-21.908 5.385-25.473 3.195-42.204-2.81-21.982-34.481-38.638-47.055-18.46-11.184-1.051-15.636 16.449-11.317 28.253"
        />
        <path
          fill="#FFB573"
          d="M161.894 219.145v-1.716a5.717 5.717 0 0 0-.606-2.367l-5.355-10.562-6.272-1.184c-4.438 4.778 0 14.305 0 14.305v1.524a5.737 5.737 0 0 0 4.171 5.517l.843.252a5.75 5.75 0 0 0 5.061-1.069 5.757 5.757 0 0 0 2.158-4.7"
        />
        <path
          fill="#295C6C"
          d="m135.312 83.273 2.308 16.184 2.426 16.139c.798 5.399 1.642 10.769 2.5 16.153l2.559 16.036.31 1.582c.104.518.281 1.184.415 1.761l.443 1.819.518 1.879c.666 2.485 1.479 5.059 2.189 7.603 1.48 5.104 3.181 10.266 4.852 15.4 3.403 10.251 6.953 20.547 10.74 30.665l-20.917 7.396-10.473-31.272c-1.731-5.221-3.491-10.443-5.163-15.769-.828-2.677-1.657-5.325-2.441-8.091l-.606-2.056-.577-2.145c-.192-.74-.4-1.391-.577-2.205l-.547-2.426-3.092-16.272a1026.535 1026.535 0 0 0-3.092-16.094l-3.165-16.094-3.299-15.977z"
        />
        <path
          fill="#fff"
          d="m135.312 83.273 2.308 16.184 2.426 16.139c.798 5.399 1.642 10.769 2.5 16.153l2.559 16.036.31 1.582c.104.518.281 1.184.415 1.761l.443 1.819.518 1.879c.666 2.485 1.479 5.059 2.189 7.603 1.48 5.104 3.181 10.266 4.852 15.4 3.403 10.251 6.953 20.547 10.74 30.665l-20.917 7.396-10.473-31.272c-1.731-5.221-3.491-10.443-5.163-15.769-.828-2.677-1.657-5.325-2.441-8.091l-.606-2.056-.577-2.145c-.192-.74-.4-1.391-.577-2.205l-.547-2.426-3.092-16.272a1026.535 1026.535 0 0 0-3.092-16.094l-3.165-16.094-3.299-15.977z"
          opacity={0.6}
        />
        <path
          fill="#263238"
          d="M131.984 82.032c.695 7.884.074 27.499-7.841 76.123H69.41c.873-21.124.932-34.585-8.136-74.896a6.803 6.803 0 0 1 5.548-8.224 149.94 149.94 0 0 1 16.138-1.76 174.398 174.398 0 0 1 24.882 0c6.805.62 14.186 1.848 18.653 2.662a6.772 6.772 0 0 1 5.489 6.095"
        />
        <path
          fill="#FFB573"
          d="M85.372 45.256c1.568 8.003 3.136 22.677-2.47 28.017 0 0 7.01 8.52 17.75 8.52s7.145-8.52 7.145-8.52c-8.875-2.13-8.698-8.757-7.145-14.97z"
        />
        <path
          fill="#000"
          d="m91.6 50.582 9.038 7.707a26.107 26.107 0 0 0-.77 4.438c-3.417-.488-8.771-3.402-9.511-7.115-.34-1.76.947-4.202 1.242-5.03"
          opacity={0.2}
        />
        <path
          fill="#FFB573"
          d="M75.949 29.546c2.085 13.224 2.692 18.86 10.059 25.044 11.094 9.304 26.878 3.846 28.786-9.734 1.716-12.219-1.997-31.804-15.517-35.843a18.269 18.269 0 0 0-23.328 20.533"
        />
        <path
          fill="#263238"
          d="M78.153 39.072c7.573-7.5 10.177-17.928 9.467-22.189 7.293 2.027 12.648-.843 12.322-2.085 5.148 5.03 14.482 9.008 13.432 3.594-1.05-5.414-7.958-15.399-22.367-11.834C76.6 10.123 71.053 24.916 71.26 30.922c.207 6.006 6.894 8.15 6.894 8.15"
        />
        <path
          fill="#263238"
          d="M94.928 31.04c.103 1.095.754 1.908 1.479 1.849.725-.06 1.198-1.006 1.094-2.086-.103-1.08-.769-1.908-1.479-1.849-.71.06-1.095 1.006-1.095 2.086M107.22 29.872c.104 1.08.769 1.908 1.479 1.834.71-.074 1.199-.99 1.095-2.07-.103-1.08-.769-1.909-1.479-1.85-.71.06-1.198 1.006-1.095 2.086"
        />
        <path fill="#263238" d="m108.019 27.874 2.53-.991s-1.154 2.16-2.53.99" />
        <path
          fill="#ED893E"
          d="M103.641 32.18a29.016 29.016 0 0 0 4.512 6.553 4.71 4.71 0 0 1-3.787 1.095z"
        />
        <path
          fill="#263238"
          d="M98.862 44.59c.537.037 1.076.037 1.613 0a.326.326 0 0 0 .281-.355.294.294 0 0 0-.107-.216.305.305 0 0 0-.233-.065 8.653 8.653 0 0 1-7.323-2.692.326.326 0 0 0-.503.4 8.994 8.994 0 0 0 6.272 2.928"
        />
        <path
          fill="#FFB573"
          d="M73.02 40.302a9.837 9.837 0 0 0 5.266 5.31c3.165 1.302 5.162-1.48 4.615-4.63-.488-2.959-2.736-7.056-6.08-6.997a4.438 4.438 0 0 0-3.801 6.316"
        />
        <path
          fill="#263238"
          d="M90.712 25.36a.65.65 0 0 0 .725-.207c1.864-2.293 5.665-2.071 5.695-2.071a.651.651 0 0 0 .654-.841.622.622 0 0 0-.565-.431c-.178 0-4.512-.267-6.775 2.544a.65.65 0 0 0 .088.902zM111.673 24.013a.635.635 0 0 0 .385-1.169 6.378 6.378 0 0 0-5.769-.813.651.651 0 0 0-.201 1.052.644.644 0 0 0 .458.185.642.642 0 0 0 .246-.054 5.058 5.058 0 0 1 4.556.696.648.648 0 0 0 .325.103"
        />
        <path
          fill="#295C6C"
          d="M124.144 158.154s17.145 103.919 24.127 144.436c7.278 42.13 23.668 141.286 23.668 141.286l-18.017 2.958s-24.29-99.259-34.024-140.531c-10.562-45.043-36.153-105.442-35.887-148.03z"
        />
        <path
          fill="#000"
          d="M96.614 190.048c-2.574-3.743-7.53-8.994-11.331-13.314 3.447 26.479 13.313 57.204 22.322 86.138 2.397-22.041 1.036-55.472-10.99-72.824"
          opacity={0.2}
        />
        <path
          fill="#295C6C"
          d="M108.744 158.154s-5.473 105.251-9.171 146.226c-3.861 42.633-8.506 139.244-8.506 139.244H72.813s-3.255-96.241-1.983-138.282c1.376-45.858-9.644-104.599-2.204-147.188z"
        />
        <path
          fill="#263238"
          d="m95.771 29.044 2.53-.991s-1.14 2.16-2.53.99M68.523 154.945l-1.39 4.911c-.193.37.25.77.887.77h56.39c.503 0 .902-.252.932-.562l.503-4.897c0-.34-.4-.636-.932-.636H69.425a1 1 0 0 0-.902.414"
        />
        <path
          fill="#295C6C"
          d="M76.17 161.143h-1.479c-.296 0-.517-.148-.503-.326l.696-6.375c0-.193.28-.34.577-.34h1.479c.296 0 .518.147.503.34l-.71 6.375c-.015.178-.267.326-.562.326M120.238 161.143h-1.479c-.296 0-.533-.148-.503-.326l.695-6.375c0-.193.281-.34.577-.34h1.479c.296 0 .518.147.503.34l-.695 6.375c-.03.178-.281.326-.577.326M98.286 161.143h-1.48c-.295 0-.517-.148-.502-.326l.695-6.375c0-.193.281-.34.577-.34h1.48c.295 0 .517.147.487.34l-.695 6.375c0 .178-.266.326-.562.326"
        />
        <path
          fill="#295C6C"
          d="M80.535 72.83c-8.595.783-21.731-.844-21.332 12.337.4 13.18-2.16 48.091-1.479 64.437.68 16.346-3.58 122.187 3.314 152.868 1.213 5.399 45.739 4.704 51.123 4.186v-4.867s32.352-4.349 40.355-2.958c-.695-18.432-18.609-111.538-19.305-137.691-.695-26.154 2.249-71.094 2.071-78.224-.177-7.13-14.349-10.266-23.032-10.089 1.923 6.435-2.322 18.52-9.112 31.997-16.701-16.272-21.627-24.349-22.604-31.997"
        />
        <path
          fill="#fff"
          d="M80.535 72.83c-8.595.783-21.731-.844-21.332 12.337.4 13.18-2.16 48.091-1.479 64.437.68 16.346-3.58 122.187 3.314 152.868 1.213 5.399 45.739 4.704 51.123 4.186v-4.867s32.352-4.349 40.355-2.958c-.695-18.432-18.609-111.538-19.305-137.691-.695-26.154 2.249-71.094 2.071-78.224-.177-7.13-14.349-10.266-23.032-10.089 1.923 6.435-2.322 18.52-9.112 31.997-16.701-16.272-21.627-24.349-22.604-31.997"
          opacity={0.7}
        />
        <path
          fill="#295C6C"
          d="m64.174 104.086-5.503 3.89c-.43 11.391-1.006 24.157-1.065 34.024 5.118-11.317 8.003-27.648 6.568-37.914"
          opacity={0.2}
        />
        <path
          fill="#295C6C"
          d="M81.703 92.268c-.28.415-.58.815-.902 1.198l-.311.355-.163.178-.296.31-.591.636-1.184 1.317a82.124 82.124 0 0 0-2.337 2.692 125.482 125.482 0 0 0-4.438 5.607 137.262 137.262 0 0 0-8.091 11.834 76.996 76.996 0 0 0-10.444 24.63 41.62 41.62 0 0 0-.695 5.917c.092.988.378 1.948.843 2.825a68.004 68.004 0 0 0 6.745 11.302c2.737 3.905 5.844 7.707 9.024 11.464 1.598 1.894 3.254 3.728 4.926 5.548 1.671 1.819 3.417 3.683 5.044 5.295L62.887 198.79a430.969 430.969 0 0 1-5.444-6.065l-5.222-6.109c-3.461-4.098-6.79-8.358-10.074-12.825a88.317 88.317 0 0 1-9.2-15.119 41.383 41.383 0 0 1-1.909-5.088 25.585 25.585 0 0 1-.725-3.151 16.564 16.564 0 0 1-.237-1.849 18.29 18.29 0 0 1-.073-1.953 63.85 63.85 0 0 1 .813-9.482 84.15 84.15 0 0 1 4.94-17.293 129.885 129.885 0 0 1 7.397-15.354c2.722-4.882 5.71-9.542 8.802-14.098a179.376 179.376 0 0 1 4.837-6.716l2.53-3.299 1.316-1.656.666-.829.34-.4.148-.192-.207.237c-.266.34-.518.68-.755 1.036z"
        />
        <path
          fill="#fff"
          d="M81.703 92.268c-.28.415-.58.815-.902 1.198l-.311.355-.163.178-.296.31-.591.636-1.184 1.317a82.124 82.124 0 0 0-2.337 2.692 125.482 125.482 0 0 0-4.438 5.607 137.262 137.262 0 0 0-8.091 11.834 76.996 76.996 0 0 0-10.444 24.63 41.62 41.62 0 0 0-.695 5.917c.092.988.378 1.948.843 2.825a68.004 68.004 0 0 0 6.745 11.302c2.737 3.905 5.844 7.707 9.024 11.464 1.598 1.894 3.254 3.728 4.926 5.548 1.671 1.819 3.417 3.683 5.044 5.295L62.887 198.79a430.969 430.969 0 0 1-5.444-6.065l-5.222-6.109c-3.461-4.098-6.79-8.358-10.074-12.825a88.317 88.317 0 0 1-9.2-15.119 41.383 41.383 0 0 1-1.909-5.088 25.585 25.585 0 0 1-.725-3.151 16.564 16.564 0 0 1-.237-1.849 18.29 18.29 0 0 1-.073-1.953 63.85 63.85 0 0 1 .813-9.482 84.15 84.15 0 0 1 4.94-17.293 129.885 129.885 0 0 1 7.397-15.354c2.722-4.882 5.71-9.542 8.802-14.098a179.376 179.376 0 0 1 4.837-6.716l2.53-3.299 1.316-1.656.666-.829.34-.4.148-.192-.207.237c-.266.34-.518.68-.755 1.036z"
          opacity={0.7}
        />
        <path
          fill="#295C6C"
          d="M103.153 104.826c1.553 31.568 9.985 141.655 9.023 196.965l2.574-.34s-3.994-85.058-5.799-122.277c-1.804-37.218-5.798-74.348-5.798-74.348M77.59 183.643l-15.798 17.1c0 5.74-4.009 17.219 6.612 21.39 10.621 4.172 10.902-26.094 9.186-38.49M129.099 183.109l6.953 17.426c.207 5.917 2.233 17.751-1.879 22.263-4.112 4.512-5.311-26.863-5.074-39.689"
          opacity={0.3}
        />
        <path
          fill="#fff"
          stroke="#295C6C"
          strokeMiterlimit={10}
          strokeWidth={0.5}
          d="m111.836 115.817 17.352-.118a1.2 1.2 0 0 0 1.243-1.332l-.962-8.224a1.57 1.57 0 0 0-1.479-1.317l-17.367.104a1.201 1.201 0 0 0-.922.385 1.202 1.202 0 0 0-.32.946l.961 8.24a1.582 1.582 0 0 0 1.494 1.316Z"
        />
        <path
          fill="#355A6B"
          d="M216.225 340.464c19.512-60.45 34.56-134.19 34.56-134.19l-58.538-5.672s-3.803 94.808-7.441 136.621c-3.803 43.595-18.686 144.886-18.686 144.886l17.859 1.459c-.165.162 30.427-88.974 32.246-143.104"
        />
        <path
          fill="#295C6C"
          d="M210.246 90.551a208.648 208.648 0 0 1-.522 7.299 302.676 302.676 0 0 1-.699 7.129c-.528 4.754-2.708 23.671-3.455 28.435-1.564 9.52-3.372 19.07-7.01 28.924l-.033.089c-.083.232-.19.462-.283.682-1.19 2.527-3.093 4.63-4.922 5.97-1.843 1.349-3.614 2.166-5.198 2.75l-7.93-18.76s.614.345 1.099.123c.24-.089.248-.157-.038.03-.273.177-.877.743-1.225 1.499l-.316.771c1.245-3.753 2.238-7.822 3.082-12 .833-4.19 1.429-8.522 1.911-12.866.472-4.357 2.455-22.951 2.693-27.368.118-2.209.191-4.423.241-6.64.058-2.18 2.102-17.345 23.359-18.618l-.708 12.556z"
        />
        <path
          fill="#fff"
          d="M210.246 90.552a213.649 213.649 0 0 1-.522 7.299 297.48 297.48 0 0 1-.7 7.129c-.527 4.754-2.707 23.671-3.455 28.435-1.563 9.52-3.372 19.07-7.01 28.924l-.033.089c-.083.232-.189.462-.282.682-1.191 2.527-3.093 4.63-4.923 5.97-1.842 1.35-3.613 2.166-5.198 2.75-3.187 1.12-.164 1.651-.164 1.651l-7.93-18.759s.778-1.307 1.264-1.529c.24-.089.247-.157-.038.03-.273.177-.877.743-1.226 1.499l-.316.771c1.246-3.753 2.239-7.822 3.082-12 .834-4.19 1.43-8.522 1.912-12.866.472-4.357 2.455-22.951 2.692-27.368.119-2.209.192-4.423.242-6.639.058-2.181 2.101-17.345 23.359-18.619l-.709 12.556z"
          opacity={0.7}
        />
        <path
          fill="#000"
          d="m227.8 255.703 8.102 14.262a1362.307 1362.307 0 0 1-14.386 52.833c.827-28.199 3.803-60.612 6.284-67.095"
          opacity={0.2}
        />
        <path
          fill="#355A6B"
          d="M273.109 336.412c-.993-62.233 2.976-103.721-2.481-134.514l-53.577-.81s13.064 98.698 19.678 139.862c6.614 41.327 14.883 142.618 14.883 142.618h16.536c.165 0 14.551-99.022 4.961-147.156"
        />
        <path
          fill="#fff"
          d="M269.552 77.545c-17.456-2.273-42.567-2.11-59.41.974-8.575 1.624-15.312 9.093-16.537 18.186-3.522 26.954-.459 73.88 1.684 121.131 38.28 13.964 78.857-.163 78.857-.163 1.378-9.58-1.685-40.756-3.828-57.48 3.215-17.212 9.952-37.021 14.24-59.916 2.296-11.041-4.594-21.433-15.006-22.732"
        />
        <path
          fill="#FFB573"
          d="m251.447 42.264-23.316 17.34c2.811 7.294 3.638 14.91-8.434 17.666-2.976 3.89 2.315 8.427 7.276 10.534 8.103-.972 19.513-3.565 24.474-10.372-1.654-5.024-2.15-24.31 0-35.168"
        />
        <path
          fill="#2A4856"
          d="M221.682 83.591s1.157 3.565 4.464 6.645c3.308 0 8.103-3.566 8.103-3.566s-1.819-5.186-4.465-6.158c-6.614 1.458-8.102 3.079-8.102 3.079"
        />
        <path
          fill="#2A4856"
          d="M229.288 88.29c-1.984-1.782-3.638-1.134-3.638-1.134-4.299 7.941-11.079 56.561-13.725 70.822 1.158 8.59 2.811 11.507 5.126 21.393 2.15-7.617 5.127-12.155 8.434-20.582-.992-10.372 2.315-57.533 3.803-70.499"
        />
        <path
          fill="#E19D61"
          d="m227.965 59.605.166-.162 13.063-9.724c1.323 0 3.804-.162 2.811 8.914-.661 5.672-9.591 11.83-15.378 13.127 1.984-3.404.992-7.78-.662-12.155 0 .162 0 0 0 0"
        />
        <path
          fill="#263238"
          d="M212.256 21.357c-4.63 7.131-1.488 18.151 2.15 21.717 6.614-11.993 5.126-25.12-2.15-21.717"
        />
        <path
          fill="#FFB573"
          d="M249.462 28.649c.331 13.451 1.323 21.23-4.961 28.523-9.425 11.02-26.788 6.321-31.253-6.806-3.969-11.669-3.969-31.603 8.929-37.924 12.733-6.32 26.954 2.755 27.285 16.207"
        />
        <path
          fill="#263238"
          d="M246.155 39.184c-6.78-.648-8.764-12.155-4.63-21.23-8.434-2.107-16.702 1.296-22.324 3.403s-14.056 2.431-14.056-4.538c2.977 1.621 8.599-.972 9.261-3.727-6.119.81-6.78-8.59-3.308-10.21 0 4.862 16.371 1.782 24.97-1.135 16.371-5.672 25.796 3.566 20.835 11.993 12.733 6.158 4.961 18.151-5.126 27.551-2.48-1.458-5.622-2.107-5.622-2.107"
        />
        <path
          fill="#263238"
          d="M242.021 33.674c.992 9.075-25.301 13.775-29.765 13.451-1.323 7.78-6.946 12.965-1.323 17.827 5.291 4.7 25.135 6.159 32.08-3.727 6.945-9.886 1.488-26.74 1.488-26.74z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M218.708 50.863s2.052.718 6.538-.143c3.497-.646 7.45-3.157 7.45-3.157s-1.977 4.376-5.778 5.883c-3.801 1.507-8.21-2.583-8.21-2.583"
          clipRule="evenodd"
        />
        <path
          fill="#263238"
          d="M252.77 13.415c6.283.973 8.268-5.024 5.953-9.724 8.764 3.89 1.819 14.262-5.953 9.724"
        />
        <path
          fill="#FFB573"
          d="M255.746 38.05c-.827 3.242-2.977 5.673-5.457 7.132-3.638 2.106-6.449-.81-6.449-4.7.165-3.404 1.984-8.752 6.118-9.238 4.134-.486 6.78 3.08 5.788 6.807"
        />
        <path
          fill="#B2C4CB"
          d="m250.95 74.03 6.615 1.782c0 1.62-1.819 4.7-6.449 8.752-4.3 3.727-13.725 10.048-17.859 10.048.496-4.862-.992-11.831-3.307-14.262 5.291.324 15.709-2.755 21-6.32"
        />
        <path
          fill="#D4DEE2"
          d="M216.5 77.5c-8.667 37.715-20.942 169.346-17.003 248.499-7.354-7.446-15.627-24.442-15.495-30.593.131-5.827 3.283-108.937 4.596-124.315.656-25.089.907-61.361 3.402-76.091 2.495-14.568 15.57-18.472 24.5-17.5"
        />
        <path
          fill="#B2C4CB"
          d="M229.784 80.348c-4.63 1.783-9.756 8.59-11.244 13.127-2.481-1.296-5.127-10.534-3.638-16.368 1.984-1.297 4.795-1.62 7.441-1.297.331 1.945 1.819 4.214 7.441 4.538"
        />
        <path
          fill="#D4DEE2"
          d="M258.051 75.527c-16.536 21.717-20.495 146.952-11.235 256.184 24.308 4.052 48.782-29.82 48.782-29.82s-16.04-109.88-11.906-145.048c4.134-35.168 8.929-55.588 7.772-66.123-1.158-10.21-21.507-13.41-33.413-15.193"
        />
        <path
          fill="#FFB573"
          d="m177.989 169.589 4.822 2.452c1.817.928 3.823.873 5.371-.128l7.276-4.712c2.915-1.891 4.631-5.331 4.607-9.257l-.002-2.426c-7.809-4.668-18.665-3.718-18.665-3.718l-3.163-.117c-2.823-.098-4.831 2.132-4.624 5.145l.482 6.932c.162 2.394 1.713 4.711 3.887 5.811z"
        />
        <path
          fill="#2A4856"
          d="m208.532 195.99 40.721-1.23c4.091-.115 6.185-3.112 4.648-6.646l-25.681-59.18c-1.536-3.534-6.146-6.319-10.238-6.204l-40.721 1.229c-4.091.116-6.185 3.112-4.648 6.646l25.681 59.18c1.537 3.535 6.147 6.32 10.238 6.205"
        />
        <path
          fill="#fff"
          d="M208.061 196.25c-4.207 0-8.836-2.862-10.411-6.493l-25.681-59.18c-.768-1.786-.711-3.534.154-4.898.941-1.479 2.727-2.343 4.936-2.42l40.721-1.229c4.284-.135 9.086 2.785 10.68 6.492l25.681 59.18c.769 1.787.711 3.534-.153 4.898-.942 1.479-2.728 2.344-4.937 2.42l-40.721 1.23zm.25-.961v.481zl40.721-1.229c1.882-.058 3.38-.768 4.149-1.978.691-1.095.73-2.517.077-4.015l-25.682-59.18c-1.459-3.38-5.82-6.07-9.776-5.916l-40.722 1.229c-1.882.058-3.38.769-4.148 1.979-.692 1.095-.73 2.516-.077 4.014l25.681 59.18c1.46 3.381 5.82 6.032 9.777 5.916"
        />
        <path
          fill="#FFB573"
          d="M236.399 169.162c.331-.648.496-1.459.331-2.107l-1.158-6.159c-.165-1.134-1.158-2.106-2.315-2.431l-5.292-1.296c-5.126-1.297-10.583-1.459-15.709-.648l-1.654.324c-2.976.486-4.96 3.403-4.299 6.32l1.323 6.321c.496 2.593 2.811 4.376 5.457 4.214l7.772-.325c-.166 0 12.237 1.621 15.544-4.213"
        />
        <path
          fill="#E0E0E0"
          d="M235.406 156.521s-4.465-1.621-7.441-1.621c-2.481 3.404 1.157 16.369 3.803 19.124 2.15 0 7.938-2.917 7.938-2.917-1.819-4.214-3.142-11.345-4.3-14.586"
        />
        <path
          fill="#D4DEE2"
          d="M263.187 99.635c1.819 4.7 3.638 9.562 5.457 14.262a499.393 499.393 0 0 1 5.126 14.424c1.654 4.862 3.142 9.562 4.465 14.261.661 2.269 1.323 4.7 1.654 6.645.165.973.33 1.945.496 2.755v.811c0-.163 0-.325.165-.487l.331-1.296c.165-.324.33-.648.496-.811.165-.162.165.001 0 .163-.496.486-1.819 1.134-3.473 1.782-3.472 1.135-8.103 1.945-12.898 2.269-4.795.324-9.922.486-15.048.324-2.646-.162-5.126-.162-7.772-.486l-3.803-.324-3.638-.324-3.803 23.013c1.653.324 2.976.486 4.464.648l4.3.649c2.811.324 5.787.648 8.598.972 5.788.486 11.741.81 17.86.648 6.118-.162 12.402-.648 19.512-2.593 3.473-.972 7.276-2.431 11.41-5.024 1.984-1.296 4.134-3.079 5.953-5.348 1.819-2.269 3.307-5.024 4.3-7.941v-.162c.165-.324.165-.81.33-1.135.166-.81.331-1.62.331-2.593v-5.186c-.165-1.782-.331-3.403-.661-5.024-.497-3.079-.993-5.834-1.654-8.589-1.158-5.51-2.646-10.697-4.134-16.045-1.488-5.186-2.977-10.372-4.63-15.396-1.654-5.186-4.466-11.563-6.285-16.749z"
        />
        <path
          fill="url(#referral_svg__c)"
          d="M263.187 99.635c1.819 4.7 3.638 9.562 5.457 14.262a499.393 499.393 0 0 1 5.126 14.424c1.654 4.862 3.142 9.562 4.465 14.261.661 2.269 1.323 4.7 1.654 6.645.165.973.33 1.945.496 2.755v.811c0-.163 0-.325.165-.487l.331-1.296c.165-.324.33-.648.496-.811.165-.162.165.001 0 .163-.496.486-1.819 1.134-3.473 1.782-3.472 1.135-8.103 1.945-12.898 2.269-4.795.324-9.922.486-15.048.324-2.646-.162-5.126-.162-7.772-.486l-3.803-.324-3.638-.324-3.803 23.013c1.653.324 2.976.486 4.464.648l4.3.649c2.811.324 5.787.648 8.598.972 5.788.486 11.741.81 17.86.648 6.118-.162 12.402-.648 19.512-2.593 3.473-.972 7.276-2.431 11.41-5.024 1.984-1.296 4.134-3.079 5.953-5.348 1.819-2.269 3.307-5.024 4.3-7.941v-.162c.165-.324.165-.81.33-1.135.166-.81.331-1.62.331-2.593v-5.186c-.165-1.782-.331-3.403-.661-5.024-.497-3.079-.993-5.834-1.654-8.589-1.158-5.51-2.646-10.697-4.134-16.045-1.488-5.186-2.977-10.372-4.63-15.396-1.654-5.186-4.466-11.563-6.285-16.749z"
          opacity={0.7}
        />
        <path
          fill="#263238"
          d="M229.332 32.703c.047 1.287-.559 2.401-1.374 2.483-.814.081-1.51-.88-1.558-2.167-.047-1.287.559-2.401 1.374-2.483.814-.08 1.51.88 1.558 2.167M214.632 34.61c.047 1.287-.559 2.4-1.374 2.482-.815.081-1.51-.88-1.558-2.166-.047-1.288.559-2.402 1.374-2.483.814-.082 1.51.879 1.558 2.166"
        />
        <path
          fill="#ED893E"
          d="M219.147 36.244s-1.892 5.758-4.099 8.689c1.71 1.395 4.458.37 4.458.37z"
        />
        <path
          fill="#263238"
          d="M232.653 24.572a.71.71 0 0 1-.559-.121c-2.537-1.951-5.102-.96-5.136-.953a.687.687 0 0 1-.926-.434.816.816 0 0 1 .474-.986c.128-.059 3.353-1.31 6.5 1.133.32.235.356.712.108 1.051a.908.908 0 0 1-.478.313zM209.024 28.01a.603.603 0 0 1-.543-.039c-.361-.175-.481-.637-.292-1.017 1.96-3.801 5.321-4.318 5.458-4.326a.682.682 0 0 1 .777.652c.024.41-.269.792-.673.85-.134.024-2.733.452-4.285 3.47a.85.85 0 0 1-.446.393z"
        />
        <path fill="url(#referral_svg__d)" d="M0 0h328v295H0z" />
      </g>
      <defs>
        <linearGradient
          id="referral_svg__b"
          x1={66.403}
          x2={138.099}
          y1={49.379}
          y2={281.061}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3BD" />
          <stop offset={0.626} stopColor="#FFF4E5" />
        </linearGradient>
        <linearGradient
          id="referral_svg__c"
          x1={269.159}
          x2={269.159}
          y1={93.673}
          y2={146.253}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4DEE2" stopOpacity={0} />
          <stop offset={1} stopColor="#B2C4CB" />
        </linearGradient>
        <linearGradient
          id="referral_svg__d"
          x1={164}
          x2={164}
          y1={186.031}
          y2={295}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <clipPath id="referral_svg__a">
          <path fill="#fff" d="M0 0h328v290H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgReferral;
