import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgNews = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3.656 3.657A1.9 1.9 0 0 1 5 3.1h10A1.9 1.9 0 0 1 16.9 5v.1H19A1.9 1.9 0 0 1 20.9 7v11a2.9 2.9 0 0 1-2.9 2.9H7A3.9 3.9 0 0 1 3.1 17V5c0-.504.2-.987.556-1.344M15.316 19.1H7A2.1 2.1 0 0 1 4.9 17V5a.1.1 0 0 1 .1-.1h10a.1.1 0 0 1 .1.1v13c0 .381.075.754.217 1.1M16.9 6.9V18a1.1 1.1 0 0 0 2.2 0V7a.1.1 0 0 0-.1-.1z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7.1 8a.9.9 0 0 1 .9-.9h4a.9.9 0 0 1 0 1.8H8a.9.9 0 0 1-.9-.9M7.1 12a.9.9 0 0 1 .9-.9h4a.9.9 0 0 1 0 1.8H8a.9.9 0 0 1-.9-.9M7.1 16a.9.9 0 0 1 .9-.9h4a.9.9 0 0 1 0 1.8H8a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgNews;
