import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBook = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M6 4.9a.1.1 0 0 0-.1.1v14a.1.1 0 0 0 .1.1h2.1V4.9zm3.9 0v14.2H17a1.1 1.1 0 0 0 1.1-1.1V6A1.1 1.1 0 0 0 17 4.9zm0 16H17a2.9 2.9 0 0 0 2.9-2.9V6A2.9 2.9 0 0 0 17 3.1H6A1.9 1.9 0 0 0 4.1 5v14A1.9 1.9 0 0 0 6 20.9h2.1V22a.9.9 0 1 0 1.8 0z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12.1 8a.9.9 0 0 1 .9-.9h2a.9.9 0 0 1 0 1.8h-2a.9.9 0 0 1-.9-.9M12.1 12a.9.9 0 0 1 .9-.9h2a.9.9 0 0 1 0 1.8h-2a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBook;
