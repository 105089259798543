import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgTerminal = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M7.364 8.364a.9.9 0 0 1 1.272 0l3 3a.9.9 0 0 1 0 1.272l-3 3a.9.9 0 1 1-1.272-1.272L9.727 12 7.364 9.636a.9.9 0 0 1 0-1.272M12.1 15a.9.9 0 0 1 .9-.9h3a.9.9 0 0 1 0 1.8h-3a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5 4.9A1.1 1.1 0 0 0 3.9 6v12A1.1 1.1 0 0 0 5 19.1h14a1.1 1.1 0 0 0 1.1-1.1V6A1.1 1.1 0 0 0 19 4.9zM2.1 6A2.9 2.9 0 0 1 5 3.1h14A2.9 2.9 0 0 1 21.9 6v12a2.9 2.9 0 0 1-2.9 2.9H5A2.9 2.9 0 0 1 2.1 18z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgTerminal;
