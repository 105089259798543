import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgAlarm = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 6.9a6.1 6.1 0 1 0 0 12.2 6.1 6.1 0 0 0 0-12.2M4.1 13a7.9 7.9 0 1 1 15.8 0 7.9 7.9 0 0 1-15.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 9.1a.9.9 0 0 1 .9.9v2.1H14a.9.9 0 0 1 0 1.8h-2a.9.9 0 0 1-.9-.9v-3a.9.9 0 0 1 .9-.9M7.728 3.47a.9.9 0 0 1-.199 1.258l-2.75 2a.9.9 0 1 1-1.058-1.456l2.75-2a.9.9 0 0 1 1.257.199M16.272 3.47a.9.9 0 0 1 1.257-.198l2.75 2a.9.9 0 1 1-1.058 1.456l-2.75-2a.9.9 0 0 1-.199-1.257"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgAlarm;
