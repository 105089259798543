import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgColor = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12.95 2.95A2.9 2.9 0 0 1 15 2.1h4A2.9 2.9 0 0 1 21.9 5v12a4.9 4.9 0 1 1-9.8 0V5a2.9 2.9 0 0 1 .85-2.05M15 3.9A1.1 1.1 0 0 0 13.9 5v12a3.1 3.1 0 1 0 6.2 0V5A1.1 1.1 0 0 0 19 3.9z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7.536 4.714a2.9 2.9 0 0 1 4.1 0l2 2a.9.9 0 1 1-1.272 1.272l-2-2a1.1 1.1 0 0 0-1.556 0m0 0c-.206.207-3.15 3.314-3.15 3.606 0 .292.116.571.322.778l9 9a.9.9 0 1 1-1.272 1.272l-9-9a2.9 2.9 0 0 1 0-4.1l2.828-2.828"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M2.95 12.95A2.9 2.9 0 0 1 5 12.1h2.3a.9.9 0 1 1 0 1.8H5A1.1 1.1 0 0 0 3.9 15v4A1.1 1.1 0 0 0 5 20.1h12a.9.9 0 1 1 0 1.8H5A2.9 2.9 0 0 1 2.1 19v-4a2.9 2.9 0 0 1 .85-2.05M17 16.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V17a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgColor;
