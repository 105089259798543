import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgTwitter = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#03A9F4"
        d="M7.548 21.901c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.426-.014-.636A10.018 10.018 0 0 0 24 4.704a9.827 9.827 0 0 1-2.828.775 4.942 4.942 0 0 0 2.164-2.724A9.87 9.87 0 0 1 20.21 3.95a4.928 4.928 0 0 0-8.391 4.492A13.98 13.98 0 0 1 1.67 3.298 4.927 4.927 0 0 0 3.194 9.87 4.888 4.888 0 0 1 .96 9.256v.062a4.926 4.926 0 0 0 3.95 4.826 4.913 4.913 0 0 1-2.223.084 4.93 4.93 0 0 0 4.6 3.42A9.878 9.878 0 0 1 0 19.688a13.94 13.94 0 0 0 7.548 2.208"
      />
    </svg>
  );
};
export default SvgTwitter;
