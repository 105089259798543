import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgSettingsFill = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9.986 22.79c.54.423 1.21.65 1.898.643a3.021 3.021 0 0 0 3.01-2.333.909.909 0 0 1 .198-.397c.102-.112.225-.203.362-.268.144-.058.3-.082.455-.07.149.013.293.06.42.14a3.068 3.068 0 0 0 4.211-4.212.946.946 0 0 1-.07-.863c.06-.14.148-.267.257-.374a1.06 1.06 0 0 1 .397-.198 3.056 3.056 0 0 0 0-5.95.933.933 0 0 1-.397-.21 1.084 1.084 0 0 1-.257-.35.98.98 0 0 1 .07-.875 3.068 3.068 0 0 0-4.211-4.212.945.945 0 0 1-.864.07 1.015 1.015 0 0 1-.373-.256 1.026 1.026 0 0 1-.198-.409 3.069 3.069 0 0 0-5.95 0 .945.945 0 0 1-.56.665.968.968 0 0 1-.91-.07A3.068 3.068 0 0 0 3.262 7.52a.945.945 0 0 1 .07.863 1.015 1.015 0 0 1-.245.268.91.91 0 0 1-.408.21 3.057 3.057 0 0 0 0 5.95.934.934 0 0 1 .396.21c.111.1.199.224.257.362.08.147.115.312.105.478a.945.945 0 0 1-.14.432 3.068 3.068 0 0 0 4.212 4.212.979.979 0 0 1 .875-.07c.123.07.233.161.326.268.094.117.161.252.199.397.156.67.536 1.265 1.077 1.69M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgSettingsFill;
