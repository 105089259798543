import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgServer = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M6 4.9A2.1 2.1 0 0 0 3.9 7v2c0 1.16.94 2.1 2.1 2.1h12A2.1 2.1 0 0 0 20.1 9V7A2.1 2.1 0 0 0 18 4.9zM2.1 7A3.9 3.9 0 0 1 6 3.1h12A3.9 3.9 0 0 1 21.9 7v2a3.9 3.9 0 0 1-3.9 3.9H6A3.9 3.9 0 0 1 2.1 9z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M6 12.9A2.1 2.1 0 0 0 3.9 15v2c0 1.16.94 2.1 2.1 2.1h12a2.1 2.1 0 0 0 2.1-2.1v-2a2.1 2.1 0 0 0-2.1-2.1zM2.1 15A3.9 3.9 0 0 1 6 11.1h12a3.9 3.9 0 0 1 3.9 3.9v2a3.9 3.9 0 0 1-3.9 3.9H6A3.9 3.9 0 0 1 2.1 17zM7 7.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V8a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7 15.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V16a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgServer;
