import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPhone = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5 4.9a1.1 1.1 0 0 0-1.1 1.076A15.1 15.1 0 0 0 18.024 20.1 1.1 1.1 0 0 0 19.1 19v-3.39l-3.723-1.49-1.105 1.843a.9.9 0 0 1-1.17.344 11.9 11.9 0 0 1-5.41-5.409.9.9 0 0 1 .345-1.17L9.88 8.623 8.39 4.9zm-2.05-.95A2.9 2.9 0 0 1 5 3.1h4a.9.9 0 0 1 .836.566l2 5a.9.9 0 0 1-.373 1.106l-1.76 1.056a10.1 10.1 0 0 0 3.47 3.47l1.055-1.761a.9.9 0 0 1 1.106-.373l5 2A.9.9 0 0 1 20.9 15v4a2.9 2.9 0 0 1-2.955 2.898A16.9 16.9 0 0 1 2.1 6a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPhone;
