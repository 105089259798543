import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMask = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M17.103 19.33a28.44 28.44 0 0 1-10.152 0c-1.89-.343-3.265-2.084-3.265-4.112V9.141c0-1.903 1.273-3.545 3.041-3.9a26.82 26.82 0 0 1 10.6 0c1.768.355 3.042 1.997 3.042 3.9v6.077c0 2.028-1.375 3.769-3.266 4.111m-9.909-1.494c3.2.58 6.467.58 9.666 0 1.192-.216 2.072-1.319 2.072-2.618V9.141c0-1.184-.791-2.194-1.874-2.412a25.457 25.457 0 0 0-10.061 0c-1.084.218-1.875 1.228-1.875 2.412v6.077c0 1.3.88 2.402 2.072 2.618"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7.658 14.415a.758.758 0 1 1 0-1.515h8.769a.758.758 0 1 1 0 1.515zM7.658 11.415a.758.758 0 1 1 0-1.515h8.769a.758.758 0 1 1 0 1.515zM20.017 8.876l-.1-.008-.116 1.511.1.008c1.11.086 1.678.947 1.683 1.82.006.873-.55 1.738-1.687 1.831l-.1.008.124 1.51.1-.007c2.04-.167 3.073-1.76 3.079-3.342.006-1.582-1.016-3.171-3.083-3.33M3.983 8.802l.1-.007.116 1.51-.1.008c-1.11.086-1.678.947-1.684 1.82-.005.873.55 1.738 1.688 1.831l.1.008-.124 1.51-.1-.007C1.94 15.308.906 13.715.9 12.134.894 10.55 1.915 8.962 3.983 8.802"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMask;
