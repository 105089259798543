import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMicrophoneOff = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.364 2.364a.9.9 0 0 1 1.272 0l18 18a.9.9 0 1 1-1.272 1.272l-18-18a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 2.9A2.1 2.1 0 0 0 9.9 5a.9.9 0 0 1-1.8 0 3.9 3.9 0 1 1 7.8 0v5c0 .385-.057.768-.169 1.136a.9.9 0 1 1-1.722-.524A2.1 2.1 0 0 0 14.1 10V5A2.1 2.1 0 0 0 12 2.9M9 8.102a.9.9 0 0 1 .9.9v1a2.101 2.101 0 0 0 2.709 2.01.9.9 0 1 1 .522 1.723A3.9 3.9 0 0 1 8.1 10.002v-1a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M18.998 9.1a.9.9 0 0 1 .902.898 7.867 7.867 0 0 1-1.3 4.348.9.9 0 1 1-1.503-.992 6.066 6.066 0 0 0 1.003-3.352.9.9 0 0 1 .898-.902M5 9.1a.9.9 0 0 1 .9.9 6.1 6.1 0 0 0 9.452 5.098.9.9 0 0 1 .988 1.504A7.9 7.9 0 0 1 4.1 10a.9.9 0 0 1 .9-.9M7.1 21a.9.9 0 0 1 .9-.9h8a.9.9 0 1 1 0 1.8H8a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 16.1a.9.9 0 0 1 .9.9v4a.9.9 0 1 1-1.8 0v-4a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMicrophoneOff;
