import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMastercard = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#FB923C" d="m15.128 6.54-5.953.021.18 10.898 5.954-.02z" />
      <path
        fill="#EF4444"
        d="M9.567 12.032A6.896 6.896 0 0 1 12.08 6.57a6.814 6.814 0 0 0-4.241-1.47c-3.78.013-6.786 3.127-6.723 6.962.064 3.836 3.173 6.929 6.953 6.916a6.652 6.652 0 0 0 4.192-1.499 7.069 7.069 0 0 1-2.693-5.446"
      />
      <path
        fill="#FFB11D"
        d="M23.369 11.939c.064 3.835-2.943 6.95-6.722 6.962a6.814 6.814 0 0 1-4.242-1.47c1.575-1.277 2.549-3.247 2.512-5.463a7.098 7.098 0 0 0-2.693-5.446 6.653 6.653 0 0 1 4.192-1.499c3.78-.013 6.89 3.1 6.953 6.916"
      />
    </svg>
  );
};
export default SvgMastercard;
