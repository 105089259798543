import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDiscount = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M15.636 8.364a.9.9 0 0 1 0 1.272l-6 6a.9.9 0 1 1-1.272-1.272l6-6a.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
      <path d="M9.5 10a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
      <path
        fillRule="evenodd"
        d="M9.5 9.9a.4.4 0 1 0 0-.8.4.4 0 0 0 0 .8m-1.4-.4a1.4 1.4 0 1 1 2.8 0 1.4 1.4 0 0 1-2.8 0"
        clipRule="evenodd"
      />
      <path d="M14.5 15a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
      <path
        fillRule="evenodd"
        d="M14.5 14.9a.4.4 0 1 0 0-.8.4.4 0 0 0 0 .8m-1.4-.4a1.4 1.4 0 1 1 2.8 0 1.4 1.4 0 0 1-2.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 3.9a8.1 8.1 0 1 0 0 16.2 8.1 8.1 0 0 0 0-16.2M2.1 12c0-5.468 4.432-9.9 9.9-9.9s9.9 4.432 9.9 9.9-4.432 9.9-9.9 9.9-9.9-4.432-9.9-9.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDiscount;
