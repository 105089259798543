import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgStories = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M8.09 5.9a.1.1 0 0 0-.1.1v12a.1.1 0 0 0 .1.1h8a.1.1 0 0 0 .1-.1V6a.1.1 0 0 0-.1-.1zM6.748 4.657A1.9 1.9 0 0 1 8.091 4.1h8a1.9 1.9 0 0 1 1.9 1.9v12a1.9 1.9 0 0 1-1.9 1.9h-8a1.9 1.9 0 0 1-1.9-1.9V6c0-.504.2-.987.556-1.343"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10.565 8.441a.9.9 0 0 1 .98.195l2.728 2.728a.9.9 0 0 1 0 1.272l-2.727 2.728a.9.9 0 0 1-1.537-.637V9.273a.9.9 0 0 1 .556-.832m1.244 3.004v1.11l.555-.555zM21 7.9a.1.1 0 0 0-.1.1v8a.1.1 0 0 0 .1.1h1a.9.9 0 1 1 0 1.8h-1a1.9 1.9 0 0 1-1.9-1.9V8A1.9 1.9 0 0 1 21 6.1h1a.9.9 0 1 1 0 1.8zM1.1 7a.9.9 0 0 1 .9-.9h1A1.9 1.9 0 0 1 4.9 8v8A1.9 1.9 0 0 1 3 17.9H2a.9.9 0 1 1 0-1.8h1a.1.1 0 0 0 .1-.1V8a.1.1 0 0 0-.1-.1H2a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgStories;
