import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgService = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12.5 3.9a4.6 4.6 0 0 0-4.6 4.6.6.6 0 1 0 1.2 0 3.4 3.4 0 0 1 6.8 0c0 .795-.21 1.459-.624 2.028-.395.543-.936.945-1.495 1.295l-4.424 2.765C8.399 15.187 7.9 15.986 7.9 17v2A1.1 1.1 0 0 0 9 20.1h7a1.1 1.1 0 0 0 1.1-1.1V8.5a4.6 4.6 0 0 0-4.6-4.6m-4 7a2.4 2.4 0 0 1-2.4-2.4 6.4 6.4 0 1 1 12.8 0V19a2.9 2.9 0 0 1-2.9 2.9H9A2.9 2.9 0 0 1 6.1 19v-2c0-1.746.92-3.075 2.303-3.938l4.424-2.765c.495-.31.803-.566.992-.827.171-.235.281-.521.281-.97a1.6 1.6 0 0 0-3.2 0 2.4 2.4 0 0 1-2.4 2.4"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgService;
