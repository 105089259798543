import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgSettings = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11.9 21.8a2.59 2.59 0 0 1-2.55-2 .91.91 0 0 0-.17-.34 1.101 1.101 0 0 0-.28-.23.84.84 0 0 0-.75.06 2.63 2.63 0 0 1-3.61-3.61.81.81 0 0 0 .12-.37.76.76 0 0 0-.09-.41.87.87 0 0 0-.22-.31.8.8 0 0 0-.34-.18 2.62 2.62 0 0 1 0-5.1.78.78 0 0 0 .35-.18.87.87 0 0 0 .21-.23.81.81 0 0 0-.06-.74 2.63 2.63 0 0 1 3.61-3.65.83.83 0 0 0 .78.06.81.81 0 0 0 .48-.57 2.63 2.63 0 0 1 5.1 0 .88.88 0 0 0 .17.35.87.87 0 0 0 .32.22.81.81 0 0 0 .74-.06 2.63 2.63 0 0 1 3.61 3.61.84.84 0 0 0-.06.75.93.93 0 0 0 .22.3.8.8 0 0 0 .34.18 2.62 2.62 0 0 1 0 5.1.91.91 0 0 0-.34.17 1 1 0 0 0-.22.32.81.81 0 0 0 .06.74 2.63 2.63 0 0 1-3.61 3.61.81.81 0 0 0-.36-.12.86.86 0 0 0-.39.06 1 1 0 0 0-.31.23.781.781 0 0 0-.17.34 2.59 2.59 0 0 1-2.58 2m-3.35-4.43a2.53 2.53 0 0 1 1 .2c.385.154.727.398 1 .71a2.7 2.7 0 0 1 .56 1.09.82.82 0 0 0 1.6 0 2.58 2.58 0 0 1 1.56-1.8 2.74 2.74 0 0 1 1.2-.2 2.65 2.65 0 0 1 1.16.38.82.82 0 0 0 1.237-.599.8.8 0 0 0-.117-.521 2.61 2.61 0 0 1-.38-1.17 2.75 2.75 0 0 1 .19-1.2 2.64 2.64 0 0 1 .72-1 2.7 2.7 0 0 1 1.09-.56.82.82 0 0 0 0-1.6 2.7 2.7 0 0 1-1.09-.56 2.58 2.58 0 0 1-.71-1 2.66 2.66 0 0 1-.2-1.21c.04-.413.17-.813.38-1.17a.82.82 0 0 0-1.13-1.13 2.57 2.57 0 0 1-1.17.38 2.75 2.75 0 0 1-1.2-.19 2.56 2.56 0 0 1-1-.72 2.64 2.64 0 0 1-.56-1.09.82.82 0 0 0-1.6 0 2.63 2.63 0 0 1-3.9 1.64.83.83 0 0 0-1.14 1.13c.22.353.35.755.38 1.17a2.61 2.61 0 0 1-.91 2.19c-.316.268-.689.46-1.09.56a.82.82 0 0 0 0 1.6c.401.1.774.292 1.09.56.316.27.56.613.71 1 .161.378.23.79.2 1.2-.04.41-.17.806-.38 1.16a.83.83 0 0 0 1.13 1.14 2.61 2.61 0 0 1 1.17-.38z" />
      <path d="M11.9 15.8a3.9 3.9 0 1 1 3.9-3.9 3.91 3.91 0 0 1-3.9 3.9m0-6a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2" />
    </svg>
  );
};
export default SvgSettings;
