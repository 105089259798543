import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgChart = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4 12.9a.1.1 0 0 0-.1.1v6a.1.1 0 0 0 .1.1h4a.1.1 0 0 0 .1-.1v-6a.1.1 0 0 0-.1-.1zm-1.9.1c0-1.05.85-1.9 1.9-1.9h4c1.05 0 1.9.85 1.9 1.9v6A1.9 1.9 0 0 1 8 20.9H4A1.9 1.9 0 0 1 2.1 19z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10 8.9a.1.1 0 0 0-.1.1v10a.1.1 0 0 0 .1.1h4a.1.1 0 0 0 .1-.1V9a.1.1 0 0 0-.1-.1zM8.1 9c0-1.05.85-1.9 1.9-1.9h4c1.05 0 1.9.85 1.9 1.9v10a1.9 1.9 0 0 1-1.9 1.9h-4A1.9 1.9 0 0 1 8.1 19z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M16 4.9a.1.1 0 0 0-.1.1v14a.1.1 0 0 0 .1.1h4a.1.1 0 0 0 .1-.1V5a.1.1 0 0 0-.1-.1zm-1.9.1c0-1.05.85-1.9 1.9-1.9h4c1.05 0 1.9.85 1.9 1.9v14a1.9 1.9 0 0 1-1.9 1.9h-4a1.9 1.9 0 0 1-1.9-1.9z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3.1 20a.9.9 0 0 1 .9-.9h14a.9.9 0 0 1 0 1.8H4a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgChart;
