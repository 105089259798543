import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgYoutube = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="red"
        fillRule="evenodd"
        d="M24 12.676c0-.058 0-.124-.003-.2a72.053 72.053 0 0 0-.01-.607c-.002-.034-.002-.068-.003-.103a39.132 39.132 0 0 0-.118-2.16c-.08-.953-.198-1.747-.359-2.343a3.668 3.668 0 0 0-.964-1.646 3.75 3.75 0 0 0-1.669-.953c-.758-.2-2.242-.325-4.331-.401-.994-.037-2.057-.06-3.12-.074-.333-.005-.644-.007-.927-.01h-.992c-.283.003-.594.005-.926.01-1.064.013-2.127.037-3.12.074-2.09.08-3.577.203-4.332.401a3.746 3.746 0 0 0-1.67.952 3.664 3.664 0 0 0-.963 1.647c-.164.596-.279 1.39-.36 2.342a39.13 39.13 0 0 0-.13 2.832v.039L0 12.59 0 12.676v.148c0 .058 0 .124.003.2.002.185.006.39.01.607.002.034.002.068.003.103.022.736.059 1.47.118 2.16.08.953.198 1.747.359 2.343a3.705 3.705 0 0 0 2.633 2.599c.755.2 2.242.325 4.331.401.994.037 2.057.06 3.12.074.333.005.644.007.928.01h.99c.284-.003.595-.005.927-.01 1.064-.013 2.127-.037 3.12-.074 2.09-.08 3.577-.203 4.332-.401a3.7 3.7 0 0 0 2.633-2.599c.163-.596.279-1.39.36-2.342a39.129 39.129 0 0 0 .13-2.832v-.039L24 12.91l.001-.086zM9.429 9.215v7.07l6-3.535z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgYoutube;
