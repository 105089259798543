import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMegaphone = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M17 7.878a.9.9 0 0 1 .9-.9 3.9 3.9 0 0 1 0 7.8.9.9 0 1 1 0-1.8 2.1 2.1 0 1 0 0-4.2.9.9 0 0 1-.9-.9M9.9 6.978a.9.9 0 0 1 .9.9v11a1.91 1.91 0 0 1-1.9 1.9h-1a1.91 1.91 0 0 1-1.9-1.9v-5a.9.9 0 1 1 1.8 0v5a.1.1 0 0 0 .1.1h1a.1.1 0 0 0 .1-.1v-11a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path d="M17 18.758h-.23a1.74 1.74 0 0 1-.92-.4l-4.28-3.58H3.9a1.91 1.91 0 0 1-1.9-1.9v-4a1.91 1.91 0 0 1 1.9-1.9h7.67l4.33-3.56c.26-.221.58-.36.92-.4a1.77 1.77 0 0 1 1 .15 1.81 1.81 0 0 1 1 1.63v12.16a1.81 1.81 0 0 1-1 1.63 1.76 1.76 0 0 1-.82.17M3.9 8.778l-.1.1v4l.1.1h8a.9.9 0 0 1 .58.21l4.52 3.77V4.797l-4.52 3.77a.9.9 0 0 1-.58.21z" />
    </svg>
  );
};
export default SvgMegaphone;
