import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgHand = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8.38 13.8a.9.9 0 0 1-.9-.9V5.4a2.46 2.46 0 0 1 .7-1.7 2.48 2.48 0 0 1 3.4 0 2.46 2.46 0 0 1 .7 1.7v6.5a.9.9 0 1 1-1.8 0V5.4a.56.56 0 0 0-.18-.42.6.6 0 0 0-.84 0 .56.56 0 0 0-.18.42v7.5a.9.9 0 0 1-.9.9" />
      <path d="M14.38 12.8a.9.9 0 0 1-.9-.9V3.4a.59.59 0 0 0 0-.23.621.621 0 0 0-.12-.19.63.63 0 0 0-.43-.178.57.57 0 0 0-.23.048.62.62 0 0 0-.32.32.59.59 0 0 0 0 .23v2a.9.9 0 1 1-1.8 0v-2c-.001-.316.06-.628.18-.92.123-.29.3-.555.52-.78.226-.22.49-.397.78-.52a2.44 2.44 0 0 1 1.84 0c.29.123.554.3.78.52.22.225.397.49.52.78.12.292.181.604.18.92v8.5a.9.9 0 0 1-1 .9" />
      <path d="M17.38 12.8a.9.9 0 0 1-.9-.9V5.4a.56.56 0 0 0-.18-.42.6.6 0 0 0-.84 0 .56.56 0 0 0-.18.42.9.9 0 1 1-1.8 0 2.46 2.46 0 0 1 .7-1.7 2.5 2.5 0 0 1 3.4 0 2.46 2.46 0 0 1 .7 1.7v6.5a.9.9 0 0 1-.9.9" />
      <path d="M14.38 22.8h-2.12a6.901 6.901 0 0 1-5.44-3.1l-.16-.25C6.3 18.9 5.21 17 3.31 13.61a2.39 2.39 0 0 1 .86-3.23A2.78 2.78 0 0 1 5.93 10a2.86 2.86 0 0 1 1.62.79l1.47 1.47a.91.91 0 0 1-1.28 1.28l-1.47-1.47a1 1 0 0 0-.56-.28 1 1 0 0 0-.62.13.61.61 0 0 0-.285.591.59.59 0 0 0 .075.219c1.86 3.31 3 5.22 3.25 5.68l.2.3A5.11 5.11 0 0 0 12.59 21h1.79a5.09 5.09 0 0 0 5.1-5.1V7.4a.56.56 0 0 0-.18-.42.6.6 0 0 0-.84 0 .56.56 0 0 0-.18.42.9.9 0 0 1-1.8 0 2.46 2.46 0 0 1 .7-1.7 2.5 2.5 0 0 1 3.4 0 2.46 2.46 0 0 1 .7 1.7v8.5a6.87 6.87 0 0 1-2 4.88 7 7 0 0 1-4.9 2.02" />
    </svg>
  );
};
export default SvgHand;
