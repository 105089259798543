import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgInbox = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3zm3-1a1 1 0 0 0-1 1v6h1.586A2 2 0 0 1 8 12.586L10.414 15h3.172L16 12.586A2 2 0 0 1 17.414 12H19V6a1 1 0 0 0-1-1zm13 9h-1.586L15 16.414a2 2 0 0 1-1.414.586h-3.172A2 2 0 0 1 9 16.414L6.586 14H5v4a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgInbox;
