import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBolt = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M13.278 2.144A.9.9 0 0 1 13.9 3v6.1H19a.9.9 0 0 1 .728 1.43l-8 11A.9.9 0 0 1 10.1 21v-6.1H5a.9.9 0 0 1-.728-1.43l8-11a.9.9 0 0 1 1.006-.326M6.768 13.1H11a.9.9 0 0 1 .9.9v4.232l5.333-7.332H13a.9.9 0 0 1-.9-.9V5.768z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBolt;
