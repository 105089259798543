import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMap = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M21.473 3.234A.9.9 0 0 1 21.9 4v13a.9.9 0 0 1-.497.805l-6 3a.9.9 0 0 1-.806 0L9 18.006l-5.598 2.799A.9.9 0 0 1 2.1 20V7a.9.9 0 0 1 .497-.805l6-3a.9.9 0 0 1 .805 0L15 5.994l5.598-2.799a.9.9 0 0 1 .875.04M3.9 7.556v10.988l4.697-2.349a.9.9 0 0 1 .805 0L15 18.994l5.1-2.55V5.456l-4.698 2.349a.9.9 0 0 1-.804 0L9 5.006z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9 3.1a.9.9 0 0 1 .9.9v13a.9.9 0 1 1-1.8 0V4a.9.9 0 0 1 .9-.9M15 6.1a.9.9 0 0 1 .9.9v13a.9.9 0 0 1-1.8 0V7a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMap;
