import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgUser = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 3.9a8.1 8.1 0 1 0 0 16.2 8.1 8.1 0 0 0 0-16.2M2.1 12c0-5.468 4.432-9.9 9.9-9.9s9.9 4.432 9.9 9.9-4.432 9.9-9.9 9.9-9.9-4.432-9.9-9.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 7.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2M8.1 10a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0M15.853 17.514A3.1 3.1 0 0 0 14 16.9h-4a3.101 3.101 0 0 0-2.97 2.208.9.9 0 1 1-1.724-.518A4.9 4.9 0 0 1 10 15.1h4a4.901 4.901 0 0 1 4.696 3.497.9.9 0 1 1-1.724.516 3.1 3.1 0 0 0-1.12-1.6"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgUser;
