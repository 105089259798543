import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgSchool = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.722 4.304a.75.75 0 0 1 .556 0l10 4A.75.75 0 0 1 22.75 9v6a.75.75 0 0 1-1.5 0v-4.892l-8.971 3.588a.75.75 0 0 1-.557 0l-10-4a.75.75 0 0 1 0-1.392zM19.98 9 12 12.192 4.02 9 12 5.808z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M6 9.85a.75.75 0 0 1 .75.75V16c0 .368.31.934 1.343 1.45.997.5 2.402.8 3.907.8s2.91-.3 3.907-.8c1.032-.516 1.343-1.082 1.343-1.45v-5.4a.75.75 0 0 1 1.5 0V16c0 1.224-.954 2.183-2.172 2.792-1.253.627-2.9.958-4.578.958-1.678 0-3.325-.331-4.578-.958C6.204 18.183 5.25 17.224 5.25 16v-5.4A.75.75 0 0 1 6 9.85"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgSchool;
