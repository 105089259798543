import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCertificate = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M15 12.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2M11.1 15a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M13 16.6a.9.9 0 0 1 .9.9v2.7l.56-.42a.9.9 0 0 1 1.08 0l.56.42v-2.7a.9.9 0 1 1 1.8 0V22a.9.9 0 0 1-1.44.72L15 21.625l-1.46 1.095A.9.9 0 0 1 12.1 22v-4.5a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5 5.9c-.603 0-1.1.497-1.1 1.1v10A1.1 1.1 0 0 0 5 18.1h5a.9.9 0 1 1 0 1.8H5A2.9 2.9 0 0 1 2.1 17V7c0-1.597 1.303-2.9 2.9-2.9h14A2.9 2.9 0 0 1 21.9 7v10a2.903 2.903 0 0 1-1.45 2.51.9.9 0 1 1-.9-1.56 1.1 1.1 0 0 0 .55-.95V7A1.1 1.1 0 0 0 19 5.9z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5.1 9a.9.9 0 0 1 .9-.9h12a.9.9 0 1 1 0 1.8H6a.9.9 0 0 1-.9-.9M5.1 12a.9.9 0 0 1 .9-.9h3a.9.9 0 1 1 0 1.8H6a.9.9 0 0 1-.9-.9M5.1 15a.9.9 0 0 1 .9-.9h2a.9.9 0 1 1 0 1.8H6a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCertificate;
