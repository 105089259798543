import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMedical = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.9 5.867A2.726 2.726 0 0 1 4.8 5.1h4.5a.9.9 0 1 1 0 1.8H4.8a.927.927 0 0 0-.645.258.826.826 0 0 0-.255.592v10.5c0 .217.088.43.255.592a.926.926 0 0 0 .645.258h14.4a.926.926 0 0 0 .645-.258.826.826 0 0 0 .255-.592V7.75a.826.826 0 0 0-.255-.592.927.927 0 0 0-.645-.258h-4.5a.9.9 0 1 1 0-1.8h4.5c.708 0 1.392.273 1.9.767.51.495.8 1.172.8 1.883v10.5c0 .711-.29 1.388-.8 1.883a2.726 2.726 0 0 1-1.9.767H4.8a2.726 2.726 0 0 1-1.9-.767 2.626 2.626 0 0 1-.8-1.883V7.75c0-.711.29-1.388.8-1.883"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11 3.9a1.1 1.1 0 0 0 0 2.2h2a1.1 1.1 0 0 0 0-2.2zM8.1 5A2.9 2.9 0 0 1 11 2.1h2a2.9 2.9 0 0 1 0 5.8h-2A2.9 2.9 0 0 1 8.1 5M8.1 13a.9.9 0 0 1 .9-.9h6a.9.9 0 0 1 0 1.8H9a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 9.1a.9.9 0 0 1 .9.9v6a.9.9 0 0 1-1.8 0v-6a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMedical;
