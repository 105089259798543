import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDatabase = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5.226 5.506c-.294.26-.326.43-.326.494s.032.234.326.494c.293.26.768.535 1.433.785C7.982 7.775 9.87 8.1 12 8.1c2.13 0 4.018-.325 5.34-.821.666-.25 1.141-.525 1.434-.785.294-.26.326-.43.326-.494s-.032-.234-.326-.494c-.293-.26-.768-.535-1.433-.785C16.018 4.225 14.13 3.9 12 3.9c-2.13 0-4.018.325-5.34.821-.666.25-1.141.525-1.434.785m.801-2.47C7.6 2.446 9.711 2.1 12 2.1c2.289 0 4.4.346 5.973.936.783.294 1.479.665 1.995 1.123.516.457.932 1.077.932 1.841s-.416 1.384-.932 1.841c-.516.458-1.212.83-1.995 1.123-1.573.59-3.684.936-5.973.936-2.289 0-4.4-.346-5.973-.936-.783-.294-1.479-.665-1.995-1.123C3.516 7.384 3.1 6.764 3.1 6s.416-1.384.932-1.841c.516-.458 1.212-.83 1.995-1.123"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4 5.1a.9.9 0 0 1 .9.9v6c0 .057.027.222.313.48.285.26.759.54 1.446.799 1.373.514 3.294.821 5.341.821s3.968-.307 5.34-.821c.688-.258 1.162-.54 1.447-.798.286-.259.313-.424.313-.481V6a.9.9 0 1 1 1.8 0v6c0 .739-.394 1.353-.905 1.815-.511.463-1.21.844-2.022 1.149-1.628.61-3.776.936-5.973.936s-4.345-.325-5.973-.936c-.813-.305-1.511-.686-2.022-1.149C3.495 13.353 3.1 12.74 3.1 12V6a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4 11.1a.9.9 0 0 1 .9.9v6c0 .057.027.222.313.48.285.26.759.54 1.446.799 1.373.514 3.294.821 5.341.821s3.968-.307 5.34-.821c.688-.258 1.162-.54 1.447-.798.286-.259.313-.424.313-.481v-6a.9.9 0 1 1 1.8 0v6c0 .739-.394 1.353-.905 1.815-.511.463-1.21.844-2.022 1.149-1.628.61-3.776.936-5.973.936s-4.345-.325-5.973-.936c-.813-.305-1.511-.686-2.022-1.149C3.495 19.353 3.1 18.74 3.1 18v-6a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDatabase;
