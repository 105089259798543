import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgClean = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M13.049 16.675a.9.9 0 0 1 .33 1.23c-1.258 2.178-3.494 3.073-4.51 3.263a.9.9 0 1 1-.331-1.77c.674-.125 2.364-.805 3.281-2.393a.9.9 0 0 1 1.23-.33M9.495 14.904a.9.9 0 0 1 .329 1.23c-1.094 1.894-3.033 2.67-3.922 2.837a.9.9 0 0 1-.331-1.77c.547-.102 1.94-.663 2.694-1.968a.9.9 0 0 1 1.23-.329"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11.418 7.039a.9.9 0 0 1 .705.085l7.088 4.121a.9.9 0 0 1 .445.847c-.095 1.233-.65 3.075-1.694 4.906-1.05 1.842-2.636 3.748-4.85 5.034a.914.914 0 0 1-.075.04c-.854.393-1.885.243-2.794-.056-.949-.311-1.967-.85-2.929-1.5-1.894-1.276-3.784-3.124-4.543-4.83a.9.9 0 0 1 .575-1.231c.92-.263 2.074-.638 3.352-1.604 1.282-.97 2.739-2.574 4.172-5.36a.9.9 0 0 1 .548-.452m.605 2.11c-1.4 2.496-2.854 4.09-4.24 5.138a10.76 10.76 0 0 1-2.853 1.55c.754 1.074 2.01 2.257 3.39 3.187.872.588 1.74 1.037 2.485 1.282.752.247 1.217.233 1.447.144 1.85-1.088 3.217-2.713 4.146-4.343.776-1.362 1.218-2.677 1.39-3.607z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.738 10.252a.9.9 0 0 1 1.23-.325l7.089 4.12a.9.9 0 1 1-.905 1.556l-7.088-4.12a.9.9 0 0 1-.326-1.23M18.735 4.221a.542.542 0 0 0-.726.194l-3.172 5.227a.9.9 0 0 1-1.538-.934L16.47 3.48a2.342 2.342 0 0 1 4.008 2.426L17.41 10.99a.9.9 0 0 1-1.54-.93l3.067-5.084a.542.542 0 0 0-.203-.756"
        clipRule="evenodd"
      />
      <path d="M4.3 11.6a1.3 1.3 0 1 0 0-2.6 1.3 1.3 0 0 0 0 2.6M5.9 8.8a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8M3.6 8.2a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2" />
    </svg>
  );
};
export default SvgClean;
