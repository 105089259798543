import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMailForward = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5 4.75A1.25 1.25 0 0 0 3.75 6v10A1.25 1.25 0 0 0 5 17.25h7a.75.75 0 0 1 0 1.5H5A2.75 2.75 0 0 1 2.25 16V6A2.75 2.75 0 0 1 5 3.25h14A2.75 2.75 0 0 1 21.75 6v7.5a.75.75 0 0 1-1.5 0V6A1.25 1.25 0 0 0 19 4.75z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M2.376 5.584a.75.75 0 0 1 1.04-.208L12 11.099l8.584-5.723a.75.75 0 0 1 .832 1.248l-9 6a.75.75 0 0 1-.832 0l-9-6a.75.75 0 0 1-.208-1.04M14.25 18a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M17.47 14.47a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06L19.94 18l-2.47-2.47a.75.75 0 0 1 0-1.06"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMailForward;
