import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBuyColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#FFF4E5"
        d="M5 21V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16l-3-2-2 2-2-2-2 2-2-2z"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M7 3.5A1.5 1.5 0 0 0 5.5 5v15.066l2.223-1.482a.5.5 0 0 1 .63.062L10 20.293l1.646-1.647a.5.5 0 0 1 .708 0L14 20.293l1.646-1.647a.5.5 0 0 1 .631-.062l2.223 1.482V5A1.5 1.5 0 0 0 17 3.5zm-1.768-.268A2.5 2.5 0 0 1 7 2.5h10A2.5 2.5 0 0 1 19.5 5v16a.5.5 0 0 1-.777.416l-2.66-1.773-1.71 1.71a.5.5 0 0 1-.707 0L12 19.708l-1.646 1.647a.5.5 0 0 1-.708 0l-1.71-1.71-2.659 1.772A.5.5 0 0 1 4.5 21V5a2.5 2.5 0 0 1 .732-1.768"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12 6a.5.5 0 0 1 .5.5v1H14a.5.5 0 0 1 0 1h-2.5a1 1 0 1 0 0 2h1a2 2 0 0 1 0 4v1a.5.5 0 0 1-1 0v-1H10a.5.5 0 0 1 0-1h2.5a1 1 0 0 0 0-2h-1a2 2 0 0 1 0-4v-1A.5.5 0 0 1 12 6"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBuyColored;
