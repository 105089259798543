import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCalendarTime = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5 5.75A1.25 1.25 0 0 0 3.75 7v12A1.25 1.25 0 0 0 5 20.25h6.795a.75.75 0 0 1 0 1.5H5A2.75 2.75 0 0 1 2.25 19V7A2.75 2.75 0 0 1 5 4.25h12A2.75 2.75 0 0 1 19.75 7v4a.75.75 0 0 1-1.5 0V7A1.25 1.25 0 0 0 17 5.75z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M18 14.75a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5M13.25 18a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0M15 2.1a.9.9 0 0 1 .9.9v4a.9.9 0 1 1-1.8 0V3a.9.9 0 0 1 .9-.9M7 2.1a.9.9 0 0 1 .9.9v4a.9.9 0 0 1-1.8 0V3a.9.9 0 0 1 .9-.9M2.1 11a.9.9 0 0 1 .9-.9h16a.9.9 0 1 1 0 1.8H3a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M18 15.596a.9.9 0 0 1 .9.9v1.131l.736.737a.9.9 0 1 1-1.272 1.272l-1-1A.9.9 0 0 1 17.1 18v-1.504a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCalendarTime;
