import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgSofaFill = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5.283 10.74V7a4.55 4.55 0 0 1 4.55-4.55h9.334A4.55 4.55 0 0 1 23.717 7v3.74a4.55 4.55 0 0 1 0 8.854v.24a3.383 3.383 0 0 1-3.384 3.383H8.667a3.383 3.383 0 0 1-3.384-3.384v-.24a4.55 4.55 0 0 1 0-8.853"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M10.95 14.05c0-.58.47-1.05 1.05-1.05h5a1.05 1.05 0 1 1 0 2.1h-5c-.58 0-1.05-.47-1.05-1.05"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M8.667 21.117c.58 0 1.05.47 1.05 1.05V24.5a1.05 1.05 0 1 1-2.1 0v-2.333c0-.58.47-1.05 1.05-1.05M20.333 21.117c.58 0 1.05.47 1.05 1.05V24.5a1.05 1.05 0 1 1-2.1 0v-2.333c0-.58.47-1.05 1.05-1.05"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M20.16 9.115a6.55 6.55 0 0 1 3.558-.413v2.038a4.55 4.55 0 0 0-5.602 4.427V16.5a1 1 0 0 1-2 0v-1.333a6.55 6.55 0 0 1 4.044-6.052M11.883 17.5a1 1 0 0 0 1-1v-1.333A6.55 6.55 0 0 0 5.285 8.7v2.038c.053-.013.107-.024.16-.035a4.55 4.55 0 0 1 5.438 4.463V16.5a1 1 0 0 0 1 1"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgSofaFill;
