import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCircleColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#circle-colored_svg__a)">
        <mask
          id="circle-colored_svg__b"
          width={24}
          height={24}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path fill="#fff" d="M24 .75H0v22.5h24z" />
        </mask>
        <g mask="url(#circle-colored_svg__b)">
          <mask id="circle-colored_svg__c" fill="#fff">
            <path d="M12.095 23.249a9.833 9.833 0 0 1-3.73-.737.454.454 0 0 1-.252-.602.478.478 0 0 1 .62-.245 8.872 8.872 0 0 0 3.362.665h.023a8.84 8.84 0 0 0 4.295-1.134 9.358 9.358 0 0 0 1.998-1.471.484.484 0 0 1 .67-.002.45.45 0 0 1 .002.65c-.882.861-3.326 2.866-6.962 2.876z" />
          </mask>
          <path
            fill="#F5AE45"
            d="M12.095 23.249a9.833 9.833 0 0 1-3.73-.737.454.454 0 0 1-.252-.602.478.478 0 0 1 .62-.245 8.872 8.872 0 0 0 3.362.665h.023a8.84 8.84 0 0 0 4.295-1.134 9.358 9.358 0 0 0 1.998-1.471.484.484 0 0 1 .67-.002.45.45 0 0 1 .002.65c-.882.861-3.326 2.866-6.962 2.876z"
          />
          <path
            fill="#F5AE45"
            d="m8.366 22.512.378-.926h-.001zm-.253-.602.918.398v-.001zm.62-.245.377-.926zm3.385.665v1h.003zm4.295-1.134.484.875zm1.998-1.471-.699-.715zm.67-.002.694-.72h-.001zm.002.65.699.714zm-6.962 2.876v1h.003zm-.026-1a8.834 8.834 0 0 1-3.351-.663l-.756 1.852c1.318.537 2.701.81 4.107.81zm-3.352-.664c.26.106.417.423.288.723l-1.836-.795a1.454 1.454 0 0 0 .794 1.925zm.288.722c-.123.284-.432.383-.676.284l.754-1.853a1.478 1.478 0 0 0-1.914.776zm-.676.284c1.2.49 2.46.74 3.74.74v-2a7.874 7.874 0 0 1-2.985-.592zm3.74.74H12.119v-2H12.094zm.026 0a9.841 9.841 0 0 0 4.776-1.26l-.967-1.75a7.842 7.842 0 0 1-3.814 1.01zm4.776-1.26a10.357 10.357 0 0 0 2.213-1.63l-1.398-1.431a8.36 8.36 0 0 1-1.782 1.311zm2.213-1.63a.517.517 0 0 1-.723.002l1.387-1.44a1.483 1.483 0 0 0-2.062.007zm-.724.001a.55.55 0 0 1-.003-.784l1.4 1.429a1.45 1.45 0 0 0-.008-2.084zm-.002-.785c-.8.781-3.005 2.583-6.266 2.592l.006 2c4.012-.01 6.693-2.219 7.658-3.162zM12.12 22.25H12.094v2H12.122z"
            mask="url(#circle-colored_svg__c)"
          />
          <path
            fill="#FFF4E5"
            d="M22.563 15.948a3.13 3.13 0 0 1 0 4.518 3.363 3.363 0 0 1-4.655 0 3.13 3.13 0 0 1 0-4.519 3.363 3.363 0 0 1 4.655 0"
          />
          <mask id="circle-colored_svg__d" fill="#fff">
            <path d="M20.235 21.862a3.8 3.8 0 0 1-2.662-1.07 3.583 3.583 0 0 1 0-5.169 3.798 3.798 0 0 1 2.662-1.07 3.8 3.8 0 0 1 2.663 1.07 3.578 3.578 0 0 1 0 5.169 3.798 3.798 0 0 1-2.663 1.07m0-6.39c-.752 0-1.46.284-1.993.8a2.682 2.682 0 0 0 0 3.87c.533.517 1.24.801 1.993.801.753 0 1.46-.285 1.993-.801a2.678 2.678 0 0 0 0-3.87 2.843 2.843 0 0 0-1.993-.8" />
          </mask>
          <path
            fill="#F5AE45"
            d="M20.235 21.862a3.8 3.8 0 0 1-2.662-1.07 3.583 3.583 0 0 1 0-5.169 3.798 3.798 0 0 1 2.662-1.07 3.8 3.8 0 0 1 2.663 1.07 3.578 3.578 0 0 1 0 5.169 3.798 3.798 0 0 1-2.663 1.07m0-6.39c-.752 0-1.46.284-1.993.8a2.682 2.682 0 0 0 0 3.87c.533.517 1.24.801 1.993.801.753 0 1.46-.285 1.993-.801a2.678 2.678 0 0 0 0-3.87 2.843 2.843 0 0 0-1.993-.8"
          />
          <path
            fill="#F5AE45"
            d="m17.573 20.792-.696.717zm0-5.169.697.718zm5.325 0 .696-.717zm0 5.169.696.717zm-4.656-4.52-.696-.717zm0 3.87-.696.718zm3.986 0 .697.718zm0-3.87.697-.717zm-1.993 4.59a2.8 2.8 0 0 1-1.965-.788l-1.393 1.435a4.8 4.8 0 0 0 3.358 1.353zm-1.965-.788a2.584 2.584 0 0 1 0-3.733l-1.393-1.435a4.584 4.584 0 0 0 0 6.603zm0-3.733a2.798 2.798 0 0 1 1.965-.788v-2a4.798 4.798 0 0 0-3.358 1.353zm1.965-.788a2.8 2.8 0 0 1 1.966.788l1.393-1.435a4.8 4.8 0 0 0-3.359-1.353zm1.966.788c.52.505.799 1.165.799 1.867h2a4.578 4.578 0 0 0-1.406-3.302zM23 18.208a2.58 2.58 0 0 1-.8 1.867l1.394 1.434A4.58 4.58 0 0 0 25 18.208zm-.799 1.866a2.798 2.798 0 0 1-1.966.788v2c1.261 0 2.458-.478 3.359-1.353zm-1.966-5.603a3.845 3.845 0 0 0-2.689 1.084l1.393 1.435c.343-.332.8-.519 1.296-.519zm-2.69 1.084a3.683 3.683 0 0 0 0 5.305l1.394-1.436a1.683 1.683 0 0 1 0-2.434zm0 5.305a3.844 3.844 0 0 0 2.69 1.083v-2c-.497 0-.953-.186-1.296-.519zm2.69 1.083a3.845 3.845 0 0 0 2.69-1.083l-1.393-1.436c-.343.333-.8.52-1.297.52zm2.69-1.084a3.678 3.678 0 0 0 1.129-2.652h-2c0 .456-.18.886-.522 1.217zm1.129-2.652a3.68 3.68 0 0 0-1.129-2.652l-1.393 1.435c.341.332.522.762.522 1.217zm-1.13-2.652a3.843 3.843 0 0 0-2.689-1.084v2c.497 0 .954.186 1.297.52z"
            mask="url(#circle-colored_svg__d)"
          />
          <mask id="circle-colored_svg__e" fill="#fff">
            <path d="M21.32 12.741a.468.468 0 0 1-.468-.401A8.226 8.226 0 0 0 19.75 9.16c-.752-1.254-1.816-2.278-3.162-3.041a9.56 9.56 0 0 0-2.312-.942.458.458 0 0 1-.337-.561.474.474 0 0 1 .578-.327c1.21.31 4.222 1.359 6.05 4.41a9.124 9.124 0 0 1 1.223 3.524.462.462 0 0 1-.47.517" />
          </mask>
          <path
            fill="#F5AE45"
            d="M21.32 12.741a.468.468 0 0 1-.468-.401A8.226 8.226 0 0 0 19.75 9.16c-.752-1.254-1.816-2.278-3.162-3.041a9.56 9.56 0 0 0-2.312-.942.458.458 0 0 1-.337-.561.474.474 0 0 1 .578-.327c1.21.31 4.222 1.359 6.05 4.41a9.124 9.124 0 0 1 1.223 3.524.462.462 0 0 1-.47.517"
          />
          <path
            fill="#F5AE45"
            d="m20.852 12.34.991-.13zM19.75 9.16l.858-.514zM16.588 6.12l-.493.87zm-2.312-.942.249-.969zm-.337-.561.965.262zm.578-.327.248-.97zm6.05 4.41.858-.515zm1.223 3.524.992-.13zm-.41.514-.122-.993h-.001zm-.06-.997c.238 0 .485.174.523.47l-1.983.258a1.468 1.468 0 0 0 1.46 1.272zm.523.469a9.227 9.227 0 0 0-1.235-3.563l-1.716 1.028c.51.851.836 1.79.968 2.795zm-1.235-3.563c-.846-1.411-2.04-2.553-3.526-3.397l-.987 1.74c1.204.683 2.139 1.587 2.797 2.685zM17.082 5.25a10.558 10.558 0 0 0-2.557-1.04l-.497 1.936a8.56 8.56 0 0 1 2.067.844zM14.524 4.21a.542.542 0 0 1 .38.67l-1.93-.525a1.458 1.458 0 0 0 1.054 1.792zm.38.67a.526.526 0 0 1-.635.38l.496-1.938a1.474 1.474 0 0 0-1.79 1.034zm-.635.38c1.109.283 3.812 1.237 5.441 3.955l1.715-1.029c-2.028-3.383-5.348-4.528-6.66-4.864zm5.441 3.955c.574.957.94 2.012 1.089 3.14l1.983-.26a10.123 10.123 0 0 0-1.357-3.909zm1.089 3.14a.538.538 0 0 1 .46-.609l.244 1.985a1.462 1.462 0 0 0 1.279-1.636zm.458-.609a.523.523 0 0 1 .063-.004v2c.058 0 .12-.003.185-.011z"
            mask="url(#circle-colored_svg__e)"
          />
          <path
            fill="#FFF4E5"
            d="M8.829 3.58c.47-1.705 2.273-2.718 4.03-2.263 1.756.456 2.799 2.207 2.33 3.912-.47 1.705-2.274 2.718-4.03 2.262-1.756-.455-2.8-2.206-2.33-3.911"
          />
          <mask id="circle-colored_svg__f" fill="#fff">
            <path d="M12.015 8.061a3.9 3.9 0 0 1-.978-.125A3.728 3.728 0 0 1 8.75 6.235a3.538 3.538 0 0 1-.378-2.773C8.908 1.515 10.975.354 12.981.874c2.005.52 3.2 2.527 2.665 4.474a3.645 3.645 0 0 1-1.752 2.22 3.831 3.831 0 0 1-1.879.493M9.286 3.699a2.65 2.65 0 0 0 .283 2.076 2.79 2.79 0 0 0 1.713 1.273 2.874 2.874 0 0 0 2.138-.275 2.732 2.732 0 0 0 1.312-1.662c.401-1.458-.494-2.96-1.995-3.35-1.502-.389-3.05.48-3.451 1.938" />
          </mask>
          <path
            fill="#F5AE45"
            d="M12.015 8.061a3.9 3.9 0 0 1-.978-.125A3.728 3.728 0 0 1 8.75 6.235a3.538 3.538 0 0 1-.378-2.773C8.908 1.515 10.975.354 12.981.874c2.005.52 3.2 2.527 2.665 4.474a3.645 3.645 0 0 1-1.752 2.22 3.831 3.831 0 0 1-1.879.493M9.286 3.699a2.65 2.65 0 0 0 .283 2.076 2.79 2.79 0 0 0 1.713 1.273 2.874 2.874 0 0 0 2.138-.275 2.732 2.732 0 0 0 1.312-1.662c.401-1.458-.494-2.96-1.995-3.35-1.502-.389-3.05.48-3.451 1.938"
          />
          <path
            fill="#F5AE45"
            d="m11.037 7.936-.25.968zM8.75 6.235l-.859.511zm-.378-2.773.964.265zM12.981.874l.25-.968zm2.665 4.474.964.265zm-1.752 2.22-.49-.872zm-4.608-3.87-.964-.265zm.283 2.077-.859.512zm1.713 1.273-.251.968zm2.138-.275.49.872zm1.312-1.662.964.265zm-1.995-3.35-.252.968zm-.722 5.3c-.241 0-.485-.03-.727-.093l-.501 1.936c.405.105.817.157 1.228.157zm-.727-.093A2.728 2.728 0 0 1 9.61 5.723L7.891 6.747a4.728 4.728 0 0 0 2.895 2.157zM9.61 5.723a2.538 2.538 0 0 1-.273-1.996l-1.928-.53a4.537 4.537 0 0 0 .483 3.55zm-.273-1.996c.385-1.397 1.894-2.274 3.394-1.885l.502-1.936C10.72-.744 8.095.7 7.408 3.196zm3.393-1.885c1.49.386 2.334 1.856 1.953 3.24l1.928.531c.69-2.508-.857-5.053-3.378-5.707zm1.953 3.24a2.646 2.646 0 0 1-1.278 1.614l.98 1.744a4.646 4.646 0 0 0 2.226-2.827zm-1.278 1.614a2.831 2.831 0 0 1-1.389.365v2c.825 0 1.638-.211 2.368-.621zM8.322 3.433a3.65 3.65 0 0 0 .388 2.854l1.718-1.024a1.65 1.65 0 0 1-.178-1.3zm.388 2.854a3.79 3.79 0 0 0 2.32 1.73l.503-1.937a1.79 1.79 0 0 1-1.105-.817zm2.32 1.73c.976.252 2.001.122 2.88-.372l-.98-1.744a1.874 1.874 0 0 1-1.397.18zm2.88-.372c.879-.493 1.52-1.3 1.786-2.269l-1.928-.53A1.732 1.732 0 0 1 12.93 5.9zm1.786-2.269c.556-2.02-.69-4.06-2.708-4.583l-.502 1.936c.985.256 1.528 1.221 1.282 2.116zM12.988.793c-2.008-.52-4.114.633-4.666 2.64l1.928.531c.25-.908 1.24-1.493 2.235-1.235z"
            mask="url(#circle-colored_svg__f)"
          />
          <mask id="circle-colored_svg__g" fill="#fff">
            <path d="M2.99 16.514a.472.472 0 0 1-.458-.34C2.21 15 1.654 11.94 3.475 8.885a9.402 9.402 0 0 1 2.546-2.779.483.483 0 0 1 .663.09.45.45 0 0 1-.093.644 8.484 8.484 0 0 0-2.297 2.505c-.749 1.256-1.136 2.66-1.152 4.174a8.86 8.86 0 0 0 .304 2.416.458.458 0 0 1-.335.562.485.485 0 0 1-.122.016" />
          </mask>
          <path
            fill="#F5AE45"
            d="M2.99 16.514a.472.472 0 0 1-.458-.34C2.21 15 1.654 11.94 3.475 8.885a9.402 9.402 0 0 1 2.546-2.779.483.483 0 0 1 .663.09.45.45 0 0 1-.093.644 8.484 8.484 0 0 0-2.297 2.505c-.749 1.256-1.136 2.66-1.152 4.174a8.86 8.86 0 0 0 .304 2.416.458.458 0 0 1-.335.562.485.485 0 0 1-.122.016"
          />
          <path
            fill="#F5AE45"
            d="m2.532 16.173-.964.266v.001zm.943-7.287-.859-.512zm2.546-2.779-.59-.807zm.663.09-.79.614zm-.093.644.59.807zM4.294 9.346l-.859-.512zM3.142 13.52l-1-.01zm.304 2.416.965-.265zm-.335.562.253.968zm-.122-.984c.213 0 .437.138.507.392l-1.927.534c.184.665.79 1.074 1.42 1.074zm.507.394c-.292-1.062-.78-3.796.838-6.51L2.616 8.374c-2.024 3.396-1.402 6.78-1.048 8.065zm.838-6.51a8.402 8.402 0 0 1 2.278-2.484L5.43 5.3a10.402 10.402 0 0 0-2.815 3.074zm2.277-2.484a.517.517 0 0 1-.717-.103l1.58-1.227A1.483 1.483 0 0 0 5.43 5.3zm-.717-.103A.55.55 0 0 1 6 6.034l1.182 1.613a1.45 1.45 0 0 0 .291-2.064zM6 6.034a9.484 9.484 0 0 0-2.565 2.8l1.718 1.024a7.484 7.484 0 0 1 2.028-2.21zm-2.565 2.8c-.843 1.413-1.276 2.992-1.293 4.675l2 .02c.014-1.342.356-2.572 1.011-3.67zm-1.293 4.675a9.86 9.86 0 0 0 .34 2.692l1.928-.53a7.862 7.862 0 0 1-.268-2.141zm.34 2.692a.542.542 0 0 1 .377-.67l.505 1.935a1.458 1.458 0 0 0 1.047-1.795zm.377-.67a.518.518 0 0 1 .13-.017v2c.12 0 .246-.015.375-.048z"
            mask="url(#circle-colored_svg__g)"
          />
          <path
            fill="#FFF4E5"
            d="M4.606 21.297c-1.757.45-3.558-.567-4.022-2.274-.464-1.706.585-3.454 2.342-3.905 1.758-.45 3.559.568 4.023 2.274.464 1.707-.585 3.455-2.343 3.905"
          />
          <mask id="circle-colored_svg__h" fill="#fff">
            <path d="M3.767 21.863c-1.668 0-3.196-1.085-3.64-2.722a3.538 3.538 0 0 1 .386-2.772 3.728 3.728 0 0 1 2.292-1.694c2.008-.515 4.071.652 4.6 2.6.53 1.95-.671 3.952-2.678 4.467-.32.082-.643.121-.96.121m-.002-6.391a2.9 2.9 0 0 0-.718.09c-1.503.386-2.403 1.885-2.006 3.344.396 1.459 1.942 2.332 3.444 1.948 1.503-.386 2.403-1.886 2.006-3.344-.333-1.226-1.477-2.038-2.726-2.038" />
          </mask>
          <path
            fill="#F5AE45"
            d="M3.767 21.863c-1.668 0-3.196-1.085-3.64-2.722a3.538 3.538 0 0 1 .386-2.772 3.728 3.728 0 0 1 2.292-1.694c2.008-.515 4.071.652 4.6 2.6.53 1.95-.671 3.952-2.678 4.467-.32.082-.643.121-.96.121m-.002-6.391a2.9 2.9 0 0 0-.718.09c-1.503.386-2.403 1.885-2.006 3.344.396 1.459 1.942 2.332 3.444 1.948 1.503-.386 2.403-1.886 2.006-3.344-.333-1.226-1.477-2.038-2.726-2.038"
          />
          <path
            fill="#F5AE45"
            d="m.126 19.14-.965.263zm.387-2.77.857.514zm2.292-1.695-.248-.969zm4.6 2.6-.964.263zm-2.678 4.467-.249-.969zm-1.68-6.18-.248-.968zM1.04 18.907l-.965.263zm3.444 1.948.248.968zm2.006-3.344-.965.262zm-2.724 3.353c-1.247 0-2.357-.811-2.676-1.985l-1.93.525c.571 2.101 2.517 3.46 4.606 3.46zM1.09 18.878a2.538 2.538 0 0 1 .28-1.994l-1.716-1.03a4.538 4.538 0 0 0-.494 3.549zm.28-1.994a2.729 2.729 0 0 1 1.683-1.24l-.497-1.938a4.728 4.728 0 0 0-2.902 2.149zm1.682-1.24c1.502-.385 3.008.496 3.388 1.894l1.93-.524c-.68-2.5-3.3-3.952-5.814-3.308zm3.388 1.894c.377 1.386-.472 2.853-1.963 3.235l.497 1.938c2.523-.647 4.079-3.187 3.396-5.698zm-1.963 3.235c-.237.061-.476.09-.711.09v2c.399 0 .805-.049 1.209-.153zm-.713-6.301a3.9 3.9 0 0 0-.966.122l.495 1.938c.159-.041.317-.06.471-.06zm-.967.122C.78 15.111-.474 17.147.076 19.169l1.93-.525c-.244-.897.303-1.86 1.289-2.113zM.076 19.169c.546 2.008 2.649 3.168 4.657 2.653l-.496-1.937c-.996.255-1.984-.333-2.231-1.241zm4.658 2.653c2.018-.517 3.272-2.554 2.722-4.575l-1.93.525c.244.896-.303 1.86-1.289 2.113zm2.722-4.574c-.459-1.69-2.021-2.776-3.69-2.776v2c.827 0 1.553.539 1.76 1.3z"
            mask="url(#circle-colored_svg__h)"
          />
        </g>
      </g>
      <defs>
        <clipPath id="circle-colored_svg__a">
          <rect fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCircleColored;
