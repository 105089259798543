import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgShieldColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#FFF4E5"
        d="M12 3a12 12 0 0 0 8.5 3A12.003 12.003 0 0 1 12 21 12 12 0 0 1 3.5 6 12 12 0 0 0 12 3"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M15.354 9.646a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L11 13.293l3.646-3.647a.5.5 0 0 1 .708 0"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M11.669 2.626a.5.5 0 0 1 .662 0A11.5 11.5 0 0 0 20.477 5.5a.5.5 0 0 1 .503.358 12.5 12.5 0 0 1-8.854 15.625.501.501 0 0 1-.252 0A12.501 12.501 0 0 1 3.02 5.859a.5.5 0 0 1 .503-.358 11.5 11.5 0 0 0 8.146-2.875M3.877 6.51A11.5 11.5 0 0 0 12 20.483 11.5 11.5 0 0 0 20.122 6.51 12.5 12.5 0 0 1 12 3.657a12.5 12.5 0 0 1-8.123 2.854"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgShieldColored;
