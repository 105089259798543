import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgChatColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#FFF4E5"
        stroke="#FFF4E5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M2 23V6.75A3.75 3.75 0 0 1 5.75 3h12.5A3.75 3.75 0 0 1 22 6.75v7.5A3.75 3.75 0 0 1 18.25 18H7z"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M5.75 3.5A3.25 3.25 0 0 0 2.5 6.75v15.043l4.146-4.147A.5.5 0 0 1 7 17.5h11.25a3.25 3.25 0 0 0 3.25-3.25v-7.5a3.25 3.25 0 0 0-3.25-3.25zm-3.005.245A4.25 4.25 0 0 1 5.75 2.5h12.5a4.25 4.25 0 0 1 4.25 4.25v7.5a4.25 4.25 0 0 1-4.25 4.25H7.207l-4.853 4.854A.5.5 0 0 1 1.5 23V6.75a4.25 4.25 0 0 1 1.245-3.005"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M6.5 8a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H7a.5.5 0 0 1-.5-.5M6.5 13a.5.5 0 0 1 .5-.5h7.5a.5.5 0 0 1 0 1H7a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgChatColored;
