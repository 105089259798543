import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBuildingColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <mask id="building-colored_svg__a" fill="#fff">
        <path d="M4.222 22V6.444L13.112 2v20" />
      </mask>
      <path fill="#FFF4E5" d="M4.222 22V6.444L13.112 2v20" />
      <path
        fill="#FFF4E5"
        d="m4.222 6.444-.447-.894-.553.276v.618zM13.112 2h1V.382l-1.448.724zm-7.89 20V6.444h-2V22zM4.67 7.339l8.89-4.445-.895-1.788L3.775 5.55zM12.111 2v20h2V2z"
        mask="url(#building-colored_svg__a)"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M1.5 22a.5.5 0 0 1 .5-.5h20a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M13.374 1.575A.5.5 0 0 1 13.61 2v20a.5.5 0 0 1-1 0V2.809L4.722 6.753V22a.5.5 0 0 1-1 0V6.444A.5.5 0 0 1 4 5.997l8.889-4.444a.5.5 0 0 1 .486.022"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12.695 6.167a.5.5 0 0 1 .693-.139l6.667 4.445a.5.5 0 0 1 .223.416V22a.5.5 0 1 1-1 0V11.157L12.834 6.86a.5.5 0 0 1-.139-.693M8.667 8.167a.5.5 0 0 1 .5.5v.01a.5.5 0 0 1-1 0v-.01a.5.5 0 0 1 .5-.5M8.667 11.5a.5.5 0 0 1 .5.5v.011a.5.5 0 1 1-1 0V12a.5.5 0 0 1 .5-.5M8.667 14.833a.5.5 0 0 1 .5.5v.011a.5.5 0 0 1-1 0v-.01a.5.5 0 0 1 .5-.5M8.667 18.167a.5.5 0 0 1 .5.5v.01a.5.5 0 1 1-1 0v-.01a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBuildingColored;
