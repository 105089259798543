import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgImage = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M14.1 8a.9.9 0 0 1 .9-.9h.01a.9.9 0 0 1 0 1.8H15a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7 4.9A2.1 2.1 0 0 0 4.9 7v10c0 1.16.94 2.1 2.1 2.1h10a2.1 2.1 0 0 0 2.1-2.1V7A2.1 2.1 0 0 0 17 4.9zM3.1 7A3.9 3.9 0 0 1 7 3.1h10A3.9 3.9 0 0 1 20.9 7v10a3.9 3.9 0 0 1-3.9 3.9H7A3.9 3.9 0 0 1 3.1 17z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="m8.63 11.643-3.994 3.993a.9.9 0 1 1-1.272-1.272l4-4 .012-.013c.588-.565 1.32-.92 2.124-.92.805 0 1.536.355 2.124.92l.012.013 5 5a.9.9 0 0 1-1.272 1.272l-4.994-4.993c-.322-.308-.623-.413-.87-.413s-.548.105-.87.413"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="m15.63 13.643-.994.993a.9.9 0 1 1-1.272-1.272l1-1 .012-.013c.588-.565 1.32-.92 2.124-.92.805 0 1.536.355 2.124.92l.012.013 2 2a.9.9 0 0 1-1.272 1.272l-1.994-1.993c-.322-.308-.623-.413-.87-.413s-.548.105-.87.413"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgImage;
