import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCalendarDay = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M6 5.9A1.1 1.1 0 0 0 4.9 7v12A1.1 1.1 0 0 0 6 20.1h12a1.1 1.1 0 0 0 1.1-1.1V7A1.1 1.1 0 0 0 18 5.9zM3.1 7A2.9 2.9 0 0 1 6 4.1h12A2.9 2.9 0 0 1 20.9 7v12a2.9 2.9 0 0 1-2.9 2.9H6A2.9 2.9 0 0 1 3.1 19z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M16 2.1a.9.9 0 0 1 .9.9v4a.9.9 0 1 1-1.8 0V3a.9.9 0 0 1 .9-.9M8 2.1a.9.9 0 0 1 .9.9v4a.9.9 0 0 1-1.8 0V3a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCalendarDay;
