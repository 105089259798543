import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgUserColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#F5AE45"
        d="M11.974 22c-2.882 0-5.598-.467-7.648-1.314-2.178-.9-3.377-2.15-3.377-3.52 0-.943.572-1.841 1.654-2.6.157-.11.325-.218.502-.322a.422.422 0 0 1 .589.176.468.468 0 0 1-.168.62 6.434 6.434 0 0 0-.443.282c-.579.406-1.269 1.055-1.269 1.843 0 .955 1.031 1.93 2.828 2.673 1.952.807 4.556 1.251 7.332 1.251 2.777 0 5.38-.444 7.332-1.251 1.798-.743 2.829-1.718 2.829-2.673 0-.725-.608-1.48-1.713-2.126a.468.468 0 0 1-.167-.62.422.422 0 0 1 .588-.175C22.254 15.07 23 16.08 23 17.164c0 1.37-1.2 2.62-3.377 3.521-2.05.848-4.766 1.314-7.649 1.314z"
      />
      <path
        fill="#FFF4E5"
        d="M14.103 3.382c1.176 1.237 1.176 3.241 0 4.478a2.908 2.908 0 0 1-4.258 0c-1.175-1.236-1.175-3.241 0-4.478a2.908 2.908 0 0 1 4.258 0"
      />
      <path
        fill="#F5AE45"
        d="M11.974 9.242a3.34 3.34 0 0 1-2.435-1.06 3.693 3.693 0 0 1-1.008-2.56c0-.968.358-1.878 1.008-2.562A3.338 3.338 0 0 1 11.974 2c.92 0 1.785.377 2.435 1.06a3.694 3.694 0 0 1 1.009 2.561c0 .967-.359 1.877-1.009 2.56a3.337 3.337 0 0 1-2.435 1.061m0-6.332a2.5 2.5 0 0 0-1.823.794 2.765 2.765 0 0 0-.755 1.917c0 .724.269 1.405.755 1.917a2.499 2.499 0 0 0 1.823.794 2.5 2.5 0 0 0 1.823-.794 2.765 2.765 0 0 0 .755-1.917c0-.724-.268-1.405-.755-1.917a2.499 2.499 0 0 0-1.823-.794"
      />
      <path
        fill="#FFF4E5"
        d="M5.093 16.09v.904c0 .5 2.611 1.797 7.081 1.797 4.47 0 6.184-.899 6.43-1.158a.925.925 0 0 0 .252-.64v-.904c0-2.737-3.4-4.516-6.882-4.516-3.482 0-6.881 1.779-6.881 4.516"
      />
      <path
        fill="#F5AE45"
        d="M12.174 19.246c-2.524 0-4.298-.398-5.343-.731-.597-.19-1.108-.41-1.477-.636-.474-.288-.694-.57-.694-.885v-.904c0-1.444.835-2.75 2.35-3.678 1.343-.822 3.152-1.293 4.965-1.293 1.812 0 3.622.471 4.964 1.293 1.516.928 2.35 2.234 2.35 3.678v.904c0 .363-.134.704-.378.962-.425.446-2.378 1.29-6.736 1.29zm-6.648-2.347c.13.13.641.485 1.843.831.954.276 2.563.605 4.806.605 4.179 0 5.892-.807 6.125-1.025a.46.46 0 0 0 .124-.316v-.905c0-1.106-.682-2.133-1.92-2.891-1.214-.744-2.865-1.17-4.53-1.17-1.663 0-3.314.426-4.528 1.17-1.238.758-1.92 1.785-1.92 2.891z"
      />
    </svg>
  );
};
export default SvgUserColored;
