import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCoffee = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.713 19.28c0-.639.518-1.157 1.157-1.157h14.87c.639 0 1.157.518 1.157 1.157a3.987 3.987 0 0 1-3.987 3.987H6.7a3.987 3.987 0 0 1-3.987-3.987m1.896.643a2.188 2.188 0 0 0 2.09 1.544h9.21c.985 0 1.817-.65 2.092-1.544z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5.054 10.38c0-.896.725-1.621 1.62-1.621h9.261c.895 0 1.62.725 1.62 1.62v3.293a6.25 6.25 0 0 1-12.501 0zm1.8.179v3.113a4.45 4.45 0 0 0 8.902 0V10.56z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M15.756 12.666c0-1.05.85-1.9 1.9-1.9H19c1.05 0 1.9.85 1.9 1.9v2.013a1.9 1.9 0 0 1-1.9 1.9h-1.344a1.9 1.9 0 0 1-1.9-1.9zm1.9-.1a.1.1 0 0 0-.1.1v2.013a.1.1 0 0 0 .1.1H19a.1.1 0 0 0 .1-.1v-2.013a.1.1 0 0 0-.1-.1zM12.515 1.1a.9.9 0 0 1 .9.9c0 .083-.004.173-.007.242l-.001.028c-.004.082-.006.15-.005.215a.75.75 0 0 0 .023.214c.032.016.155.068.455.068 1.358 0 2.119 1.187 2.009 2.235a1.938 1.938 0 0 1-.9 1.445c-.536.34-1.218.44-1.978.333-.703-.098-1.005-.017-1.106.025a.9.9 0 0 1-1.757.391c-.105-.471-.08-1.223.57-1.758.592-.489 1.473-.59 2.542-.44.478.066.693-.025.762-.07.057-.036.072-.072.077-.114a.255.255 0 0 0-.055-.18c-.029-.035-.071-.067-.164-.067-.498 0-.95-.085-1.33-.296a1.756 1.756 0 0 1-.792-.894 2.398 2.398 0 0 1-.156-.873 5.67 5.67 0 0 1 .007-.312v-.029c.004-.077.006-.123.006-.163a.9.9 0 0 1 .9-.9M11.9 6.761v.007z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCoffee;
