import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgFemaleDoctorWithClipboard = ({
  title,
  titleId,
  size = 16,
  className,
}: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 328 317"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#female-doctor-with-clipboard_svg__a)">
        <path
          fill="url(#female-doctor-with-clipboard_svg__b)"
          d="M24 233c0-77.32 62.68-140 140-140s140 62.68 140 140v89H24z"
        />
        <path
          fill="#263238"
          d="M170.965 37.662c20.766-1.16 32.954 19.151 20.892 32.075-12.062 12.925-3.482 19.262 1.365 21.614-5.302 15.277-47.745 17.379-56.764 4.486-7.56-10.822 21.912-24.735 14.838-36.546-7.074-11.81 3.498-22.005 19.653-21.63z"
        />
        <path
          fill="#295C6C"
          d="M139.096 122.318a204.17 204.17 0 0 1-.507 7.075c-.2 2.31-.423 4.617-.677 6.91-.512 4.608-1.055 9.201-1.78 13.818-1.515 9.228-3.268 18.485-6.794 28.036l-.032.086c-.081.225-.184.448-.274.661-1.154 2.45-2.998 4.488-4.771 5.787-1.786 1.308-3.503 2.099-5.039 2.665-3.089 1.086-8.1 1.601-8.1 1.601l-7.686-18.183s8.695-1.267 9.165-1.482c.233-.086.24-.152-.036.029-.265.171-.85.72-1.188 1.453l-.306.747c1.207-3.638 2.169-7.582 2.987-11.631.808-4.062 1.385-8.26 1.853-12.471.457-4.223.81-8.503 1.04-12.784.114-2.141.185-4.287.234-6.435.056-2.114 2.037-16.813 22.641-18.047l-.686 12.17z"
        />
        <path
          fill="#fff"
          d="M139.096 122.318a204.17 204.17 0 0 1-.507 7.075c-.2 2.31-.423 4.617-.677 6.91-.512 4.608-1.055 9.201-1.78 13.818-1.515 9.228-3.268 18.485-6.794 28.036l-.032.086c-.081.225-.184.448-.274.661-1.154 2.45-2.998 4.488-4.771 5.787-1.786 1.308-3.503 2.099-5.039 2.665-3.089 1.086-8.1 1.601-8.1 1.601l-7.686-18.183s8.695-1.267 9.165-1.482c.233-.086.24-.152-.036.029-.265.171-.85.72-1.188 1.453l-.306.747c1.207-3.638 2.169-7.582 2.987-11.631.808-4.062 1.385-8.26 1.853-12.471.457-4.223.81-8.503 1.04-12.784.114-2.141.185-4.287.234-6.435.056-2.114 2.037-16.813 22.641-18.047l-.686 12.17z"
          opacity={0.7}
        />
        <path
          fill="#FFB573"
          d="m105.522 186.156 4.673 2.376c1.762.9 3.706.847 5.207-.124l7.052-4.567c2.826-1.833 4.489-5.168 4.466-8.973l-.002-2.352c-7.569-4.524-18.091-3.603-18.091-3.603l-3.067-.113c-2.736-.096-4.683 2.066-4.482 4.987l.468 6.719c.157 2.32 1.66 4.566 3.767 5.632z"
        />
        <path
          fill="#355A6B"
          d="M124.694 200.426s-5.741 77.107-3.466 126.075c-2.432 45.972-5.945 151.249-5.945 151.249.251 2.353 12.626 1.412 14.775 0 0 0 26.68-76.683 19.92-151.249 29.299-77.766 26.021-126.075 26.021-126.075z"
        />
        <path
          fill="#355A6B"
          d="M140.081 200.426s22.759 87.035 31.809 129.777c9.489 44.89 22.319 117.746 19.794 147.563 2.51 1.49 11.497 1.568 16.563 0 0 0 19.559-102.36-5.113-145.571 8.627-106.328-19.575-131.769-19.575-131.769z"
        />
        <path
          fill="#fff"
          d="M121.369 131.127c-.173 5.788-.424 13.113.392 26.931.455 11.686 1.255 23.198 2.948 42.365h58.866c-1.177-16.579.753-35.479 11.324-72.793 2.447-8.643-3.011-16.956-11.748-17.85a129.09 129.09 0 0 0-7.34-.549c-9.866-.47-17.959-.8-26.727 0-2.902.267-5.913.628-8.799 1.035-10.384 1.443-18.587 10.494-18.901 20.861z"
        />
        <path
          fill="#FFB573"
          d="M173.913 79.128c-2.321 8.595-5.176 24.359.408 30.099 0 0-8.14-1.223-25.441 9.474-5.003-7.184-1.286-9.474-1.286-9.474 9.756-2.29 10.038-9.41 8.878-16.093l17.457-14.006z"
        />
        <path
          fill="#B2C4CB"
          d="M152.519 116.557c3.247 2.149 4.768 10.571 4.768 10.571s21.504-8.14 20.72-17.661c-2.023-1.066-5.003-3.168-5.003-3.168s-17.175 6.964-20.501 10.258zM147.108 115.63s-2.18 3.654-3.545 9.364c-8.642-7.153 1.396-15.795 1.396-15.795s1.788-1.27 5.145-.737c-4.517 2.384-2.996 7.168-2.996 7.168"
        />
        <path
          fill="#E19D61"
          d="m166.808 84.857-10.336 8.281c.282 1.584.47 3.184.47 4.737 3.702-.533 9.035-4.549 9.709-8.391.33-1.914.392-3.75.141-4.643z"
        />
        <path
          fill="#FFB573"
          d="M181.254 61.876c-2.604 13.881-3.42 19.81-11.341 26.46-11.92 10.023-28.295 4.722-29.864-9.442-1.411-12.736 3.09-33.314 17.411-37.894 14.1-4.517 26.413 6.98 23.794 20.876"
        />
        <path
          fill="#263238"
          d="M171.215 43.839c-4.235 11.23 4.815 17.928 6.823 27.636 10.462-6.948 11.215-35.996-6.823-27.636"
        />
        <path
          fill="#fff"
          d="M173.333 62.612c-.377 0-.769-.095-1.114-.267a2.45 2.45 0 0 1-1.223-1.427 2.346 2.346 0 0 1 .157-1.867c.298-.58.8-1.02 1.427-1.223l7.34-2.385a2.349 2.349 0 0 1 1.867.157c.58.298 1.019.8 1.223 1.428a2.438 2.438 0 0 1-1.584 3.09l-7.34 2.384a2.523 2.523 0 0 1-.753.125zm7.34-6.525a1.97 1.97 0 0 0-.517.078l-7.341 2.384c-.878.282-1.364 1.24-1.066 2.102.141.423.439.768.831.972a1.7 1.7 0 0 0 1.27.11l7.341-2.384c.878-.282 1.365-1.239 1.067-2.102a1.703 1.703 0 0 0-1.585-1.16"
        />
        <path
          fill="#FFB573"
          d="M182.822 73.676c-1.835 2.604-4.391 4-6.603 4.235-3.325.377-4.156-3.498-2.557-6.995 1.459-3.153 4.925-7.137 7.937-5.882 2.964 1.24 3.309 5.662 1.223 8.642"
        />
        <path
          fill="#fff"
          d="M181.097 78.475a3.394 3.394 0 0 1-3.388 3.388 3.394 3.394 0 0 1-3.388-3.388 3.394 3.394 0 0 1 3.388-3.388 3.394 3.394 0 0 1 3.388 3.388"
        />
        <path
          fill="#295C6C"
          d="M178.634 106.278c-6.556 4.471-15.292 52.717-17.818 124.491-2.525 71.774 4.439 129.965 4.439 129.965s28.656-31.495 46.569-40.78c.329-66.975-13.929-112.728-12.266-129.636 1.663-16.908 10.948-63.665 3.811-72.448-7.121-8.784-24.735-11.607-24.735-11.607z"
        />
        <path
          fill="#fff"
          d="M178.634 106.278c-6.556 4.471-15.292 52.717-17.818 124.491-2.525 71.774 4.439 129.965 4.439 129.965s28.656-31.495 46.569-40.78c.329-66.975-13.929-112.728-12.266-129.636 1.663-16.908 10.948-63.665 3.811-72.448-7.121-8.784-24.735-11.607-24.735-11.607z"
          opacity={0.8}
        />
        <path
          fill="#295C6C"
          d="M145.477 108.74c-7.623 11.309-24.5 152.206-8.58 252.009-12.595-6.964-21.63-26.194-21.63-26.194s-1.254-86.925 4.722-134.137c-1.239-55.415-7.827-92.964 25.488-91.678"
        />
        <path
          fill="#fff"
          d="M145.477 108.74c-7.623 11.309-24.5 152.206-8.58 252.009-12.595-6.964-21.63-26.194-21.63-26.194s-1.254-86.925 4.722-134.137c-1.239-55.415-7.827-92.964 25.488-91.678"
          opacity={0.8}
        />
        <path
          fill="#2A4856"
          d="m129.094 211.114 33.252-1.004c3.341-.094 5.05-2.541 3.795-5.427l-20.97-48.325c-1.255-2.886-5.019-5.16-8.36-5.066l-33.252 1.004c-3.341.094-5.05 2.541-3.796 5.427l20.971 48.325c1.255 2.886 5.019 5.16 8.36 5.066"
        />
        <path
          fill="#fff"
          d="M128.709 211.326c-3.435 0-7.215-2.337-8.501-5.302l-20.97-48.325c-.628-1.458-.58-2.886.125-3.999.768-1.208 2.227-1.914 4.031-1.977l33.252-1.004c3.498-.109 7.419 2.275 8.721 5.302l20.97 48.325c.628 1.459.581 2.886-.125 4-.769 1.207-2.227 1.913-4.031 1.976l-33.252 1.004zm.204-.784v.392zl33.252-1.004c1.537-.047 2.761-.628 3.388-1.616.565-.894.596-2.054.063-3.278l-20.971-48.325c-1.192-2.761-4.752-4.956-7.983-4.831l-33.252 1.004c-1.538.047-2.761.627-3.388 1.615-.565.894-.596 2.055-.063 3.279l20.971 48.325c1.192 2.76 4.752 4.925 7.983 4.831"
        />
        <path
          fill="#FFB573"
          d="m148.425 173.21 3.953-1.93a5.12 5.12 0 0 1 4.674.094l6.792 3.686a9.513 9.513 0 0 1 4.909 7.231l.235 1.898c-6.321 3.639-15.842 2.917-15.842 2.917l-2.729.094c-2.431.079-4.407-1.662-4.517-4.015l-.251-5.427c-.094-1.866 1.02-3.686 2.808-4.548z"
        />
        <path
          fill="#D4DEE2"
          d="M204.077 119.141c1.948 2.435 3.041 6.493 3.731 8.767a253.51 253.51 0 0 1 1.976 6.745c1.255 4.517 2.463 9.05 3.482 13.661 1.992 9.254 3.765 18.665 3.733 29.08 0 .235.001.486-.031.721v.095c-.219 2.87-1.615 6.038-3.388 8.015-1.772 2.054-3.623 3.215-5.191 4.046-3.2 1.6-5.788 2.149-8.235 2.604-4.831.8-9.082.847-13.285.784-4.172-.063-8.235-.329-12.25-.706-4.062-.376-7.905-.784-12.093-1.427l3.137-18.79a172.65 172.65 0 0 0 10.728 1.443c3.624.329 7.215.549 10.713.596 3.466 0 6.917-.189 9.631-.722 1.317-.251 2.494-.659 2.697-.831.126-.047-.109-.016-.658.564-.518.534-1.161 1.851-1.192 2.808l-.032.8c-.11-3.78-.611-7.811-1.302-11.874-.705-4.078-1.694-8.203-2.807-12.328a213.483 213.483 0 0 0-3.78-12.328 259.138 259.138 0 0 0-2.18-6.086 150.414 150.414 0 0 0-2.306-5.882z"
        />
        <path
          fill="url(#female-doctor-with-clipboard_svg__c)"
          d="M205.597 121.069a162.867 162.867 0 0 1 2.211 6.839c.69 2.243 1.349 4.486 1.976 6.744 1.255 4.518 2.463 9.051 3.482 13.662 1.992 9.254 3.765 18.665 3.733 29.08 0 .235.001.486-.031.721v.094c-.219 2.871-1.615 6.039-3.388 8.015-1.772 2.055-3.623 3.216-5.191 4.047-3.2 1.6-5.788 2.149-8.235 2.604-4.831.8-9.082.847-13.285.784-4.172-.063-8.235-.329-12.25-.706-4.062-.376-7.905-.784-12.093-1.427l3.137-18.791c3.388.565 7.152 1.083 10.728 1.443 3.624.33 7.215.549 10.713.596 3.466 0 6.917-.188 9.631-.721 1.317-.251 2.494-.659 2.697-.831.126-.048-.109-.016-.658.564-.518.533-1.161 1.851-1.192 2.808l-.032.8c-.11-3.78-.611-7.811-1.302-11.874-.705-4.078-1.694-8.203-2.807-12.328a213.003 213.003 0 0 0-3.78-12.328 259.138 259.138 0 0 0-2.18-6.086 150.414 150.414 0 0 0-2.306-5.882l18.415-10.243z"
          opacity={0.7}
        />
        <path
          fill="#263238"
          d="M175.45 47.856c-7.074 7.074-38.788-4.392-37.549 7.372-4.894-11.027 2.823-22.806 22.382-22.79 23.919 0 19.481 11.104 15.167 15.418M159.001 64.182c-.172 1.176-.91 2.086-1.662 2.023-.753-.063-1.224-1.051-1.051-2.227.172-1.177.909-2.087 1.662-2.024.753.063 1.224 1.051 1.051 2.227M144.822 63.353c-.173 1.177-.91 2.086-1.663 2.024-.753-.063-1.223-1.051-1.051-2.227.173-1.177.91-2.087 1.663-2.024.753.063 1.223 1.051 1.051 2.227"
        />
        <path fill="#263238" d="m144.085 61.204-2.651-1.02s1.098 2.306 2.651 1.02" />
        <path
          fill="#ED893E"
          d="M148.492 65.565s-2.682 4.91-5.176 7.2c1.318 1.553 3.984 1.082 3.984 1.082z"
        />
        <path
          fill="#263238"
          d="M153.292 78.676c-.612.063-1.271.078-1.961.031-.188 0-.314-.172-.282-.376a.364.364 0 0 1 .392-.314c5.05.377 7.921-3.058 7.952-3.09.126-.157.345-.188.486-.062.142.11.157.329.032.486-.11.14-2.431 2.901-6.619 3.34zM163.377 57.359a.653.653 0 0 1-.486-.204c-1.976-2.196-4.47-1.726-4.501-1.726a.635.635 0 0 1-.769-.549.754.754 0 0 1 .596-.815c.126-.032 3.263-.627 5.709 2.117.251.267.204.706-.078.973a.838.838 0 0 1-.486.204zM140.838 56.423a.556.556 0 0 1-.486-.126c-.298-.22-.33-.658-.094-.972 2.415-3.122 5.552-3.027 5.678-3.012a.63.63 0 0 1 .596.722c-.047.376-.377.674-.753.659-.126 0-2.557-.047-4.47 2.43a.787.787 0 0 1-.471.283zM158.264 62.052l-2.651-1.02s1.098 2.306 2.651 1.02"
        />
        <path fill="url(#female-doctor-with-clipboard_svg__d)" d="M0 51h328v268H0z" />
      </g>
      <defs>
        <linearGradient
          id="female-doctor-with-clipboard_svg__b"
          x1={66.903}
          x2={138.599}
          y1={76.379}
          y2={308.061}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3BD" />
          <stop offset={0.626} stopColor="#FFF4E5" />
        </linearGradient>
        <linearGradient
          id="female-doctor-with-clipboard_svg__c"
          x1={189.983}
          x2={189.983}
          y1={125.725}
          y2={167.318}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4DEE2" stopOpacity={0} />
          <stop offset={1} stopColor="#B2C4CB" />
        </linearGradient>
        <linearGradient
          id="female-doctor-with-clipboard_svg__d"
          x1={164}
          x2={164}
          y1={220.004}
          y2={319}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <clipPath id="female-doctor-with-clipboard_svg__a">
          <path fill="#fff" d="M0 0h328v317H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgFemaleDoctorWithClipboard;
