import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDownloadCloud = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M7.364 15.364a.9.9 0 0 1 1.272 0L12 18.727l3.364-3.363a.9.9 0 1 1 1.272 1.272l-4 4a.9.9 0 0 1-1.272 0l-4-4a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 11.1a.9.9 0 0 1 .9.9v8a.9.9 0 1 1-1.8 0v-8a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M8.977 3.105a8.52 8.52 0 0 1 3.576.652 8.15 8.15 0 0 1 2.931 2.077 7.706 7.706 0 0 1 1.48 2.441h.489m0 0c1.145 0 2.266.344 3.2.99a5.22 5.22 0 0 1 1.988 2.629 4.99 4.99 0 0 1-.032 3.264 5.233 5.233 0 0 1-2.04 2.591.9.9 0 1 1-.998-1.498 3.433 3.433 0 0 0 1.343-1.697 3.19 3.19 0 0 0 .02-2.09 3.422 3.422 0 0 0-1.307-1.719 3.815 3.815 0 0 0-2.173-.67H16.31a.9.9 0 0 1-.869-.665 5.867 5.867 0 0 0-1.297-2.376 6.35 6.35 0 0 0-2.285-1.617 6.72 6.72 0 0 0-5.592.19 6.27 6.27 0 0 0-2.153 1.766A5.805 5.805 0 0 0 3 9.828a5.637 5.637 0 0 0 .132 2.658c.255.866.716 1.67 1.351 2.35a.9.9 0 1 1-1.315 1.229 7.686 7.686 0 0 1-1.763-3.07 7.437 7.437 0 0 1-.172-3.505 7.605 7.605 0 0 1 1.455-3.217A8.07 8.07 0 0 1 5.458 4a8.484 8.484 0 0 1 3.519-.894"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDownloadCloud;
