import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgRocket = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M16.951 3.101a3.9 3.9 0 0 1 3.948 3.948 9.9 9.9 0 0 1-6.046 8.586 6.902 6.902 0 0 1-3.403 5.145.9.9 0 0 1-1.344-.674 7.1 7.1 0 0 0-6.194-6.21.9.9 0 0 1-.681-1.364 6.9 6.9 0 0 1 5.134-3.385 9.9 9.9 0 0 1 8.586-6.046M5.574 12.408a8.899 8.899 0 0 1 6.018 6.018A5.1 5.1 0 0 0 13.1 14.97a.9.9 0 0 1 .601-.819A8.1 8.1 0 0 0 19.1 6.977 2.1 2.1 0 0 0 17.023 4.9 8.1 8.1 0 0 0 9.849 10.3a.9.9 0 0 1-.82.6 5.1 5.1 0 0 0-3.455 1.509"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7.784 13.558a.9.9 0 0 1-.342 1.226A5.1 5.1 0 0 0 4.85 19.15a5.1 5.1 0 0 0 4.366-2.592.9.9 0 0 1 1.568.884 6.9 6.9 0 0 1-6.9 3.45.9.9 0 0 1-.776-.776 6.9 6.9 0 0 1 3.45-6.9.9.9 0 0 1 1.226.341M15 8.9a.1.1 0 1 0 0 .2.1.1 0 0 0 0-.2m-1.9.1a1.9 1.9 0 1 1 3.8 0 1.9 1.9 0 0 1-3.8 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgRocket;
