import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgEdit1 = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M13.864 4.864a3.728 3.728 0 1 1 5.272 5.272l-10.5 10.5A.9.9 0 0 1 8 20.9H4a.9.9 0 0 1-.9-.9v-4a.9.9 0 0 1 .264-.636zm2.636.708c-.511 0-1.002.203-1.364.564L4.9 16.373V19.1h2.727L17.864 8.864A1.929 1.929 0 0 0 16.5 5.572"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12.864 5.864a.9.9 0 0 1 1.272 0l4 4a.9.9 0 1 1-1.272 1.272l-4-4a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgEdit1;
