import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgShieldX = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12.605 2.333A.892.892 0 0 0 12 2.1a.899.899 0 0 0-.605.233 11.1 11.1 0 0 1-7.854 2.768.9.9 0 0 0-.904.645 12.9 12.9 0 0 0 9.137 16.125.901.901 0 0 0 .452 0 12.9 12.9 0 0 0 9.137-16.125.9.9 0 0 0-.904-.645 11.1 11.1 0 0 1-7.854-2.768M12 4.171a12.9 12.9 0 0 1-7.808 2.74A11.1 11.1 0 0 0 12 20.069a11.101 11.101 0 0 0 7.808-13.156A12.9 12.9 0 0 1 12 4.17"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.364 9.364a.9.9 0 0 1 1.272 0L12 10.727l1.364-1.363a.9.9 0 0 1 1.272 1.272L13.273 12l1.363 1.364a.9.9 0 0 1-1.272 1.272L12 13.273l-1.364 1.363a.9.9 0 1 1-1.272-1.272L10.727 12l-1.363-1.364a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgShieldX;
