import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgGlobalColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#FFF4E5" d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18" />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12 3.4a8.6 8.6 0 1 0 0 17.2 8.6 8.6 0 0 0 0-17.2M2.6 12a9.4 9.4 0 1 1 18.8 0 9.4 9.4 0 0 1-18.8 0"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M3.2 9c0-.22.18-.4.4-.4h16.8a.4.4 0 0 1 0 .8H3.6a.4.4 0 0 1-.4-.4M3.2 15c0-.22.18-.4.4-.4h16.8a.4.4 0 0 1 0 .8H3.6a.4.4 0 0 1-.4-.4"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M11.712 2.66a.4.4 0 0 1 .127.552 16.6 16.6 0 0 0 0 17.576.4.4 0 0 1-.678.424 17.4 17.4 0 0 1 0-18.424.4.4 0 0 1 .55-.127M12.288 2.66a.4.4 0 0 1 .551.128 17.4 17.4 0 0 1 0 18.424.4.4 0 0 1-.678-.424 16.6 16.6 0 0 0 0-17.576.4.4 0 0 1 .127-.551"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgGlobalColored;
