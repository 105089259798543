import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgTrash = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3.1 7a.9.9 0 0 1 .9-.9h16a.9.9 0 1 1 0 1.8H4a.9.9 0 0 1-.9-.9M10 10.1a.9.9 0 0 1 .9.9v6a.9.9 0 1 1-1.8 0v-6a.9.9 0 0 1 .9-.9M14 10.1a.9.9 0 0 1 .9.9v6a.9.9 0 1 1-1.8 0v-6a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4.925 6.103a.9.9 0 0 1 .972.822l1 12L6.9 19A1.1 1.1 0 0 0 8 20.1h8a1.1 1.1 0 0 0 1.1-1.1l.003-.075 1-12a.9.9 0 0 1 1.794.15L18.9 19.04A2.9 2.9 0 0 1 16 21.9H8a2.9 2.9 0 0 1-2.9-2.86L4.103 7.076a.9.9 0 0 1 .822-.972"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10 3.9a.1.1 0 0 0-.1.1v3a.9.9 0 0 1-1.8 0V4A1.9 1.9 0 0 1 10 2.1h4A1.9 1.9 0 0 1 15.9 4v3a.9.9 0 1 1-1.8 0V4a.1.1 0 0 0-.1-.1z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgTrash;
