import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLocationColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#FFF4E5"
        d="M17.657 16.657 13.414 20.9a2 2 0 0 1-2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12 8.438a2.563 2.563 0 1 0 0 5.125 2.563 2.563 0 0 0 0-5.126M8.563 11a3.437 3.437 0 1 1 6.874 0 3.437 3.437 0 0 1-6.874 0"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12 3.438a7.563 7.563 0 0 0-5.348 12.91l4.244 4.243a1.563 1.563 0 0 0 2.209 0l4.243-4.243A7.562 7.562 0 0 0 12 3.438m-4.688.547a8.437 8.437 0 0 1 10.654 12.981l-4.243 4.243a2.438 2.438 0 0 1-3.445 0l-4.244-4.243A8.438 8.438 0 0 1 7.312 3.985"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgLocationColored;
