import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCamera = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9 4.9a.1.1 0 0 0-.1.1A2.9 2.9 0 0 1 6 7.9H5A1.1 1.1 0 0 0 3.9 9v9A1.1 1.1 0 0 0 5 19.1h14a1.1 1.1 0 0 0 1.1-1.1V9A1.1 1.1 0 0 0 19 7.9h-1A2.9 2.9 0 0 1 15.1 5a.1.1 0 0 0-.1-.1zM5 6.1A2.9 2.9 0 0 0 2.1 9v9A2.9 2.9 0 0 0 5 20.9h14a2.9 2.9 0 0 0 2.9-2.9V9A2.9 2.9 0 0 0 19 6.1h-1A1.1 1.1 0 0 1 16.9 5 1.9 1.9 0 0 0 15 3.1H9A1.9 1.9 0 0 0 7.1 5 1.1 1.1 0 0 1 6 6.1z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 10.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2M8.1 13a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCamera;
