import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgOneLivLogo = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#one-liv-logo_svg__a)">
        <path
          fill="url(#one-liv-logo_svg__b)"
          d="M0 7.2c0-3.394 0-5.091 1.054-6.146C2.11 0 3.806 0 7.2 0h9.6c3.394 0 5.091 0 6.146 1.054C24 2.11 24 3.806 24 7.2v9.6c0 3.394 0 5.091-1.054 6.146C21.89 24 20.194 24 16.8 24H7.2c-3.394 0-5.091 0-6.146-1.054C0 21.89 0 20.194 0 16.8z"
        />
        <path
          fill="#fff"
          d="M15.698 13.61h1.42v.685h-2.284V9.987l.864.215zM17.961 10.612a.544.544 0 0 1-.383-.142.495.495 0 0 1-.148-.364c0-.144.05-.264.148-.358a.531.531 0 0 1 .383-.148.51.51 0 0 1 .377.148.465.465 0 0 1 .154.358.485.485 0 0 1-.154.364.522.522 0 0 1-.377.142m.426.264v3.419h-.864V10.66zM20.523 13.499s.864-.634.864-2.623h.848c.052 2.623-1.41 3.419-1.41 3.419h-.833l-1.259-3.42h.926zM3.982 14.339c-.403 0-.773-.095-1.11-.284a2.126 2.126 0 0 1-.803-.784 2.218 2.218 0 0 1-.296-1.142c0-.42.099-.796.296-1.13.198-.337.465-.6.803-.79.337-.189.707-.283 1.11-.283.408 0 .778.094 1.111.284.338.189.603.452.796.79.198.333.297.71.297 1.13 0 .423-.1.803-.297 1.14a2.081 2.081 0 0 1-.796.785c-.337.189-.707.284-1.11.284m0-.772c.26 0 .488-.058.685-.173.198-.12.352-.288.463-.506a1.65 1.65 0 0 0 .167-.759c0-.288-.056-.539-.167-.753a1.186 1.186 0 0 0-.463-.5 1.335 1.335 0 0 0-.685-.173c-.259 0-.49.058-.69.173a1.186 1.186 0 0 0-.464.5 1.613 1.613 0 0 0-.166.753c0 .288.055.541.166.76.111.217.266.386.463.505.202.115.432.173.691.173M8.508 10.827c.407 0 .736.13.987.389.251.255.377.613.377 1.074v2.005h-.864v-1.888c0-.272-.068-.48-.204-.624-.136-.148-.32-.222-.555-.222-.239 0-.428.074-.568.222-.136.144-.204.352-.204.624v1.888h-.864v-3.419l.864.216v.21a1.17 1.17 0 0 1 .438-.346c.181-.086.379-.13.593-.13M13.728 12.512c0 .123-.008.234-.024.333h-2.5c.02.247.107.44.26.58a.8.8 0 0 0 .561.21c.32 0 .55-.138.685-.414h.932c-.099.33-.288.601-.568.815-.28.21-.623.315-1.03.315-.33 0-.626-.072-.89-.216a1.59 1.59 0 0 1-.61-.623 1.922 1.922 0 0 1-.216-.926c0-.354.072-.665.216-.932.144-.268.345-.473.605-.617.259-.144.557-.216.895-.216.324 0 .615.07.87.21.259.14.458.339.598.598.144.255.216.55.216.883m-.895-.247a.675.675 0 0 0-.24-.53.844.844 0 0 0-.574-.205.788.788 0 0 0-.543.198.848.848 0 0 0-.266.537z"
        />
      </g>
      <defs>
        <linearGradient
          id="one-liv-logo_svg__b"
          x1={13.289}
          x2={32.624}
          y1={5.064}
          y2={20.338}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006081" />
          <stop offset={1} stopColor="#00698F" />
        </linearGradient>
        <clipPath id="one-liv-logo_svg__a">
          <rect width={24} height={24} fill="#fff" rx={12} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgOneLivLogo;
