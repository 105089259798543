import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgListSearch = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M15 11.9a3.1 3.1 0 1 0 0 6.2 3.1 3.1 0 0 0 0-6.2M10.1 15a4.9 4.9 0 1 1 9.8 0 4.9 4.9 0 0 1-9.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M17.864 17.864a.9.9 0 0 1 1.272 0l2.5 2.5a.9.9 0 0 1-1.272 1.272l-2.5-2.5a.9.9 0 0 1 0-1.272M3.1 6a.9.9 0 0 1 .9-.9h16a.9.9 0 0 1 0 1.8H4a.9.9 0 0 1-.9-.9M3.1 12a.9.9 0 0 1 .9-.9h4a.9.9 0 1 1 0 1.8H4a.9.9 0 0 1-.9-.9M3.1 18a.9.9 0 0 1 .9-.9h4a.9.9 0 1 1 0 1.8H4a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgListSearch;
