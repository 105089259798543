import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPhoneOff = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M21.636 2.364a.9.9 0 0 1 0 1.272l-18 18a.9.9 0 1 1-1.272-1.272l18-18a.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5 4.9a1.1 1.1 0 0 0-1.1 1.077 15.046 15.046 0 0 0 2.67 7.67.9.9 0 1 1-1.478 1.028A16.846 16.846 0 0 1 2.1 6 2.9 2.9 0 0 1 5 3.1h4a.9.9 0 0 1 .836.566l2 5a.9.9 0 0 1-.373 1.106L9.69 10.835a.9.9 0 0 1-1.623.762 11.34 11.34 0 0 1-.375-.7.9.9 0 0 1 .345-1.169L9.88 8.623 8.39 4.9zm9.228 7.637a.9.9 0 0 1 1.106-.373l5 2A.9.9 0 0 1 20.9 15v4a2.9 2.9 0 0 1-2.954 2.898 16.863 16.863 0 0 1-10.927-4.92.9.9 0 1 1 1.274-1.27 15.063 15.063 0 0 0 9.73 4.392A1.1 1.1 0 0 0 19.1 19v-3.39l-3.723-1.49-1.105 1.843a.9.9 0 0 1-1.17.344A11.927 11.927 0 0 1 9.96 14.06a.9.9 0 1 1 1.27-1.276 10.136 10.136 0 0 0 1.941 1.513z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPhoneOff;
