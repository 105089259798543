import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgAddFill = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4.858 4.858C6.766 2.948 9.808 2.45 14 2.45c4.192 0 7.234.499 9.142 2.408 1.91 1.908 2.408 4.95 2.408 9.142 0 4.192-.499 7.233-2.408 9.142-1.908 1.91-4.95 2.408-9.142 2.408-4.192 0-7.234-.499-9.142-2.408C2.948 21.234 2.45 18.193 2.45 14c0-4.193.499-7.234 2.408-9.142"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9.45 14c0-.58.47-1.05 1.05-1.05h7a1.05 1.05 0 1 1 0 2.1h-7c-.58 0-1.05-.47-1.05-1.05"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14 9.45c.58 0 1.05.47 1.05 1.05v7a1.05 1.05 0 1 1-2.1 0v-7c0-.58.47-1.05 1.05-1.05"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgAddFill;
