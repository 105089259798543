import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgKey = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M8 11.9a3.1 3.1 0 1 0 0 6.2 3.1 3.1 0 0 0 0-6.2M3.1 15a4.9 4.9 0 1 1 9.8 0 4.9 4.9 0 0 1-9.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M19.636 3.364a.9.9 0 0 1 0 1.272l-8.15 8.15a.9.9 0 1 1-1.272-1.272l8.15-8.15a.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M17.364 4.364a.9.9 0 0 1 1.272 0l2 2a.9.9 0 0 1-1.272 1.272l-2-2a.9.9 0 0 1 0-1.272M14.364 7.364a.9.9 0 0 1 1.272 0l2 2a.9.9 0 0 1-1.272 1.272l-2-2a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgKey;
