import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDoctorWithStethoAndClipboard = ({
  title,
  titleId,
  size = 16,
  className,
}: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 328 317"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#doctor-with-stetho-and-clipboard_svg__a)">
        <path
          fill="url(#doctor-with-stetho-and-clipboard_svg__b)"
          d="M24 233c0-77.32 62.68-140 140-140s140 62.68 140 140v89H24z"
        />
        <path
          fill="#fff"
          d="M202.186 132.617c-11.109 49.979-8.157 99.306-9.348 109.987l-75.975-5.015c-7.935-60.31.921-98.782 5.952-114.558a14.28 14.28 0 0 1 11.808-9.888 218.747 218.747 0 0 1 34.028-1.063c7.686.46 15.314 1.618 22.791 3.46a14.285 14.285 0 0 1 10.744 17.077"
        />
        <path
          fill="#A0B7BF"
          d="M136.412 129.076c-.482 2.82-.964 5.304-1.499 7.906a331.451 331.451 0 0 1-1.672 7.698c-1.181 5.126-2.397 10.23-3.82 15.339-2.919 10.2-6.11 20.402-11.339 30.703l-.047.092c-.121.243-.266.481-.396.709-1.622 2.61-3.968 4.664-6.137 5.895-2.184 1.239-4.223 1.906-6.028 2.342-3.623.818-9.339.74-9.339.74l-6.273-21.501s9.965-.285 10.524-.467c.273-.066.29-.139-.046.029-.32.158-1.052.7-1.53 1.481l-.442.802c1.838-3.941 3.44-8.259 4.894-12.715 1.444-4.471 2.646-9.127 3.726-13.811a269.499 269.499 0 0 0 2.851-14.271c.41-2.397.772-4.806 1.109-7.221.341-2.375 4.503-18.679 27.886-17.364l-2.373 13.625z"
          opacity={0.7}
        />
        <path
          fill="#B2C4CB"
          d="M136.412 129.076c-.482 2.82-.964 5.304-1.499 7.906a331.451 331.451 0 0 1-1.672 7.698c-1.181 5.126-2.397 10.23-3.82 15.339-2.919 10.2-6.11 20.402-11.339 30.703l-.047.092c-.121.243-.266.481-.396.709-1.622 2.61-3.968 4.664-6.137 5.895-2.184 1.239-4.223 1.906-6.028 2.342-3.623.818-9.339.74-9.339.74l-6.273-21.501s9.965-.285 10.524-.467c.273-.066.29-.139-.046.029-.32.158-1.052.7-1.53 1.481l-.442.802c1.838-3.941 3.44-8.259 4.894-12.715 1.444-4.471 2.646-9.127 3.726-13.811a269.499 269.499 0 0 0 2.851-14.271c.41-2.397.772-4.806 1.109-7.221.341-2.375 4.503-18.679 27.886-17.364l-2.373 13.625z"
          opacity={0.2}
        />
        <path
          fill="#F89E63"
          d="m90.193 196.608 4.954 3.291c1.867 1.246 4.065 1.441 5.884.545l8.547-4.221c3.425-1.695 5.737-5.235 6.211-9.526l.307-2.65c-7.936-6.093-19.915-6.437-19.915-6.437l-3.441-.53c-3.071-.468-5.548 1.714-5.705 5.031l-.356 7.633c-.128 2.636 1.272 5.364 3.506 6.842zM173.912 76.047c-1.665 9.96-3.079 28.164 4.128 34.596-5.284 5.493-12.214 11.86-24.097 12.579-11.882.719-10.047-7.426-7.035-11.778 11.091-2.925 10.555-11.115 8.378-18.765z"
        />
        <path
          fill="#DC834C"
          d="M156.904 98.028a23.048 23.048 0 0 0-.045-.349c-.16-1.224-.446-2.468-.8-3.702 1.313.312 2.67.475 4.045.475 1.358 0 2.731-.159 4.096-.494-2.03 2.072-4.906 3.693-7.296 4.07"
        />
        <path
          fill="#17282F"
          d="M151.851 45.258c-9.626 3.81-10.263 11.822-9.311 20.59.953 8.766 10.283 2.956 12.373-2.507s10.28-23.361-3.062-18.083"
        />
        <path
          fill="#F89E63"
          d="M182.55 62.285c-2.656 13.463-3.53 21.462-11.278 27.663-11.656 9.325-27.461 1.263-28.855-12.626-1.253-12.504 3.301-32.421 17.226-36.348 13.72-3.866 25.563 7.844 22.907 21.31"
        />
        <path
          fill="#17282F"
          d="M181.725 73.566c-11.336.599-8.931-5.796-6.87-9.461 2.061-3.666-2.147-9.72.601-12.873-4.895 1.932-10.033.34-16.572-4.177-5.074-3.506-11.42 10.534-17.602 3.494-5.839-6.649-.414-16.667 13.61-18.285 14.023-1.617 16.584 8.251 25.802 7.375 9.217-.878 6.01 6.649 4.207 8.183 7.728.511 11.935 7.843-3.176 25.744"
        />
        <path
          fill="#17282F"
          d="M182.926 48.914s5.587-2.913 5.67-10.317c2.896 5.608 2.573 11.971-5.67 10.317M153.634 76.733c4.244.69 7.056-1.616 7.186-1.724a.338.338 0 0 0 .05-.47.323.323 0 0 0-.463-.04c-.033.026-3.312 2.696-7.986 1.26a.338.338 0 0 0-.417.224.327.327 0 0 0 .21.416c.489.15.963.259 1.42.334"
        />
        <path
          fill="#F89E63"
          d="M184.754 77.514c-2.211 2.951-5.515 4.609-8.438 4.993-4.398.578-5.852-3.622-4.006-7.543 1.661-3.529 5.952-8.082 10.065-6.853 4.053 1.21 4.909 6.028 2.379 9.403"
        />
        <path
          fill="#17282F"
          d="M161.733 63.574c-.137 1.122-.783 1.964-1.443 1.884-.661-.082-1.085-1.057-.948-2.176.137-1.121.783-1.966 1.444-1.884.66.081 1.084 1.055.947 2.176M149.521 62.327c-.137.895-.783 1.57-1.443 1.505-.66-.065-1.085-.843-.948-1.74.136-.898.783-1.571 1.444-1.508.66.065 1.084.845.947 1.743"
        />
        <path
          fill="#F57739"
          d="M152.803 63.836s-3.385 5.25-6.513 7.666c1.603 1.697 4.926 1.23 4.926 1.23z"
        />
        <path
          fill="#A3C3EA"
          d="M143.032 65.463c-1.123-.626-1.762-1.49-1.605-2.442l1.355.087c.058.776.144 1.562.25 2.355"
        />
        <path
          fill="#265396"
          d="m142.569 65.463-.152-.102a50.396 50.396 0 0 1-.198-2.34l.814.066c-.176.79-.33 1.583-.464 2.376"
        />
        <path
          fill="#B08EA4"
          d="M146.207 66.277c-.227 0-.455-.013-.687-.042-.958-.12-1.817-.419-2.487-.841.135-.792.289-1.583.466-2.373l3.459.277c.15.561.498.962.951 1.01a.674.674 0 0 0 .094.006c.437 0 .859-.33 1.137-.841l1.22.098c-.201 1.508-2.047 2.706-4.153 2.706"
        />
        <path
          fill="#265396"
          d="M147.884 63.836a.466.466 0 0 1-.07-.005c-.338-.039-.598-.36-.71-.81l1.629.14c-.208.41-.523.675-.849.675"
        />
        <path
          fill="#B08EA4"
          d="M160.928 67.905c-.339 0-.685-.026-1.032-.08-2.411-.386-4.129-1.712-3.796-3.176l3.789.454a1.015 1.015 0 0 0 .742.325c.201 0 .399-.058.584-.165l3.799.457c-.285 1.255-2.066 2.185-4.086 2.185"
        />
        <path
          fill="#265396"
          d="M160.584 65.463a.181.181 0 0 1-.058-.01c-.155-.059-.291-.348-.397-.804l.814.4c-.113.267-.235.414-.359.414"
        />
        <path
          fill="#17282F"
          d="M161.064 68.72c-.274 0-.549-.017-.827-.055-1.246-.172-2.376-.717-3.182-1.53-.84-.846-1.234-1.884-1.109-2.924-.102-.087-.971-.457-2.469-.663-1.507-.21-2.439-.09-2.596.06-.215 2.003-2.55 3.398-5.163 3.051-1.247-.172-2.377-.715-3.183-1.528-.84-.847-1.234-1.885-1.109-2.923l.735.099c-.094.78.22 1.578.881 2.244.694.7 1.678 1.169 2.77 1.32 2.203.311 4.173-.796 4.371-2.455.109-.914 1.899-.862 3.38-.659 1.483.205 3.227.64 3.118 1.555-.094.78.22 1.576.88 2.245.693.698 1.678 1.168 2.769 1.317 1.092.154 2.155-.035 2.992-.523.796-.466 1.287-1.15 1.381-1.93l.048-.394 18.987 2.62-.095.788-18.279-2.522c-.217.872-.806 1.62-1.687 2.136-.755.442-1.662.672-2.613.672M166.802 58.95l.32-.104c.315-.211.426-.672.25-1.028-1.777-3.564-4.791-3.744-4.917-3.751-.363-.016-.673.306-.697.72-.022.413.25.761.608.78.1.006 2.447.175 3.865 3.018.121.242.342.373.571.366M145.271 56.506c.22.015.45-.06.605-.22 1.959-2.025 4.425-1.863 4.528-1.855.378.03.719-.209.765-.53.044-.322-.222-.608-.599-.64-.136-.01-3.292-.246-5.748 2.29a.528.528 0 0 0 .084.827z"
        />
        <path
          fill="#2A4856"
          d="m158.636 119.922-2.429.238-4.11 2.714a7.442 7.442 0 0 0 .825 4.761 29.07 29.07 0 0 0 7.253.492 7.661 7.661 0 0 0 3.666-3.777l-3.015-3.857z"
        />
        <path
          fill="#B2C4CB"
          d="M158.636 119.921a40.783 40.783 0 0 1 6.904 12.792c11.284-5.301 17.855-17.966 17.855-17.966l-5.444-4.762s-13.11 4.508-19.315 9.936M158.636 119.921a41.724 41.724 0 0 0-11.237 10.411c-7.936-4.872-4.904-17.998-4.904-17.998l6.427-1.27s3.397 3.571 9.714 8.857"
        />
        <path
          fill="#355A6B"
          d="m73.313 534.253 23.537 5.19s22.886-89.688 29.679-133.524c2.079-13.332 6.904-32.362 11.3-52.375 5.539-25.235 12.507-52.534 18.221-73.912 6.031-22.521 10.506-38.71 10.506-38.71l-49.692-3.285S93.739 354.94 89.074 398.126c-4.873 44.883-15.76 136.127-15.76 136.127"
        />
        <path
          fill="#000"
          d="M137.829 353.512c5.539-25.235 12.506-52.534 18.22-73.912l-5.222-11.11c-12.332 14.729-15.49 60.295-12.998 85.022"
          opacity={0.2}
        />
        <path
          fill="#355A6B"
          d="M140.543 239.161s10.284 119.875 14.284 161.378c4.444 45.455 16.363 138.571 16.363 138.571h23.902s1.079-99.163-3.174-143.063c-.603-86.307.905-153.426.905-153.426z"
        />
        <path
          fill="#295C6C"
          d="M179.523 111.477a1.134 1.134 0 0 0-.944.286 1.133 1.133 0 0 0-.357.92c.555 8.507-12.697 23.807-15.872 69.627-1.888 27.87-3.618 114.764-2.348 159.965a3.894 3.894 0 0 0 1.597 3.03 3.905 3.905 0 0 0 3.37.605c23.426-6.539 34.488 9.316 38.996-9.38-.238-22.394-7.015-102.797-5.27-121.859 1.746-19.061 8.999-70.848 10.809-84.656 1.603-11.999-23.426-17.348-29.981-18.538"
        />
        <path
          fill="#fff"
          d="M179.523 111.477a1.134 1.134 0 0 0-.944.286 1.133 1.133 0 0 0-.357.92c.555 8.507-12.697 23.807-15.872 69.627-1.888 27.87-3.618 114.764-2.348 159.965a3.894 3.894 0 0 0 1.597 3.03 3.905 3.905 0 0 0 3.37.605c23.426-6.539 34.488 9.316 38.996-9.38-.238-22.394-7.015-102.797-5.27-121.859 1.746-19.061 8.999-70.848 10.809-84.656 1.603-11.999-23.426-17.348-29.981-18.538"
          opacity={0.7}
        />
        <path
          fill="#295C6C"
          d="M194.964 150.314c-1.031 7.079-1.428 35.679 5.381 49.439 1.143-9.523 2.634-20.839 4.079-31.742z"
          opacity={0.2}
        />
        <path
          fill="#295C6C"
          d="M148.653 110.287a1.844 1.844 0 0 1 1.843 2.054 1.85 1.85 0 0 1-.398.946c-3.778 4.38-6.158 10.395-10.634 29.695-5.396 23.283-16.887 140.428-19.045 197.834a3.306 3.306 0 0 1-2.475 3.122 3.307 3.307 0 0 1-1.414.052c-11.205-2.063-18.886 3.365-20.109-3.825-1.349-7.936 3.683-83.736 13.094-119.383-1.476-31.615 3.857-95.036 14.506-103.464 5.777-4.682 18.062-6.92 24.632-7.031"
        />
        <path
          fill="#fff"
          d="M148.653 110.287a1.844 1.844 0 0 1 1.843 2.054 1.85 1.85 0 0 1-.398.946c-3.778 4.38-6.158 10.395-10.634 29.695-5.396 23.283-16.887 140.428-19.045 197.834a3.306 3.306 0 0 1-2.475 3.122 3.307 3.307 0 0 1-1.414.052c-11.205-2.063-18.886 3.365-20.109-3.825-1.349-7.936 3.683-83.736 13.094-119.383-1.476-31.615 3.857-95.036 14.506-103.464 5.777-4.682 18.062-6.92 24.632-7.031"
          opacity={0.7}
        />
        <path
          fill="#2A4856"
          d="M154.826 126.016s-13.712 38.519-18.537 70.801c-.381 8.777 3.793 25.775 7.174 33.186 7.062-15.379 11.475-24.441 12.173-33.186 1.127-13.157 2.984-70.547 2.984-70.547zM182.856 122.667a.621.621 0 0 1-.587-.667c.19-3.095-.254-8.729-3.175-10.189a.638.638 0 0 1-.269-.857.632.632 0 0 1 .857-.27c4.221 2.175 3.84 10.459 3.777 11.396a.62.62 0 0 1-.603.587M171.349 169.645c-.159 1.19-.905 2.063-1.682 1.952-.778-.111-1.286-1.127-1.127-2.301.159-1.175.889-2.064 1.666-1.953.778.111 1.286 1.127 1.143 2.302M134.45 143.917c-1 2.524-3.174 4.048-4.761 3.397-1.587-.651-2.206-3.174-1.207-5.761 1-2.587 3.175-4.048 4.762-3.397 1.587.651 2.19 3.238 1.206 5.761"
        />
        <path
          fill="#2A4856"
          d="M129.227 142.283h-.127a.63.63 0 0 1-.399-.272.63.63 0 0 1-.093-.474c2.095-10.427 10.761-26.981 18.395-31.742a.622.622 0 0 1 .509-.147.629.629 0 0 1 .535.546.633.633 0 0 1-.378.664c-7.38 4.651-15.776 20.633-17.823 30.901a.637.637 0 0 1-.619.524"
        />
        <path
          fill="#fff"
          d="M136.258 144.442c-.73 2.174-2.602 3.523-4.19 3-1.587-.524-2.301-2.73-1.587-4.92.715-2.191 2.603-3.524 4.19-3 1.587.524 2.318 2.73 1.587 4.92"
        />
        <path
          fill="#2A4856"
          d="M136.368 144.665c-.968 2.682-2.761 4.507-3.983 4.063-1.222-.444-1.429-2.984-.445-5.682.984-2.698 2.762-4.507 3.984-4.063 1.222.444 1.428 2.984.444 5.682M184.936 171.391c-.143 1.174-.905 2.047-1.682 1.952-.778-.095-1.286-1.143-1.127-2.317.158-1.175.904-2.048 1.682-1.952.778.095 1.286 1.126 1.127 2.317"
        />
        <path
          fill="#fff"
          d="m153.248 239.386-46.73-7.266c-1.834-.285-3.425-2.061-3.535-3.946l-4.407-74.639c-.11-1.884 1.299-3.192 3.131-2.907l46.729 7.266c1.834.286 3.426 2.06 3.537 3.944l4.406 74.64c.111 1.884-1.299 3.193-3.131 2.908"
        />
        <path
          fill="#2A4856"
          d="m151.483 239.935-47.272-7.351c-1.855-.288-3.466-2.082-3.578-3.984l-4.473-75.407c-.114-1.903 1.312-3.224 3.168-2.936l47.272 7.351c1.855.289 3.465 2.082 3.577 3.984L154.651 237c.112 1.902-1.312 3.224-3.168 2.935"
        />
        <path
          fill="#F89E63"
          d="m163.575 199.437-11.955-2.316a10.475 10.475 0 0 0-4.347.073l-6.59 1.513c-2.7.62-4.295 3.278-3.535 5.891l1.664 5.737c.664 2.284 2.928 3.805 5.348 3.593l7.144-.624s13.076.984 14.58-5.647z"
        />
        <path
          fill="#BECED3"
          d="M208.331 124.363c2.21 4.95 4.197 9.603 6.13 14.479 1.928 4.834 3.797 9.711 5.495 14.716 1.682 5.017 3.255 10.127 4.538 15.565.62 2.738 1.161 5.564 1.496 8.635.294 3.098.539 6.368-.286 10.662l-.079.396-.04.197a3.498 3.498 0 0 1-.071.295c-.146.54-.315 1.079-.506 1.603-.191.467-.399.927-.624 1.38-.223.445-.442.791-.68 1.184a17.177 17.177 0 0 1-2.791 3.34c-1.804 1.684-3.369 2.642-4.87 3.548-2.981 1.723-5.685 2.839-8.357 3.855-5.317 1.981-10.436 3.373-15.549 4.654-5.096 1.298-10.166 2.409-15.237 3.416a480.677 480.677 0 0 1-15.317 2.852l-2.807-20.065c4.695-.554 9.636-1.35 14.415-2.193 4.798-.894 9.565-1.88 14.226-3.01 4.634-1.166 9.212-2.497 13.307-4.062 2.023-.776 3.955-1.648 5.321-2.465.653-.379 1.204-.817 1.213-.877.057-.043-.125.066-.372.458-.082.119-.117.206-.21.369a2.911 2.911 0 0 0-.231.52 3.693 3.693 0 0 0-.229.702l-.019.067.002-.037.003-.07c.111-.659.111-2.48-.167-4.366-.261-1.924-.707-4.041-1.256-6.196-1.115-4.335-2.65-8.81-4.38-13.256a236.004 236.004 0 0 0-5.661-13.321c-2.04-4.367-4.225-8.873-6.445-12.953z"
        />
        <path
          fill="url(#doctor-with-stetho-and-clipboard_svg__c)"
          d="m161.583 215.14-.486-3.471-1.871-13.388-.45-3.206c4.695-.554 9.636-1.35 14.415-2.193 4.799-.893 9.565-1.88 14.227-3.01 4.634-1.166 9.211-2.497 13.307-4.062 2.022-.776 3.954-1.648 5.32-2.465.077-.044.153-.09.227-.136a22.42 22.42 0 0 0-.236-3.097c-.261-1.925-.707-4.042-1.256-6.196a94.842 94.842 0 0 0-1.697-5.785c-.806-2.48-1.714-4.98-2.684-7.471a235.72 235.72 0 0 0-5.661-13.322c-2.04-4.367-4.225-8.873-6.445-12.953l20.038-10.022c2.211 4.95 4.197 9.603 6.13 14.479 1.929 4.834 3.797 9.711 5.495 14.716 1.682 5.018 3.255 10.127 4.539 15.565.62 2.738 1.161 5.564 1.496 8.635.267 2.819.494 5.783-.09 9.528a33.46 33.46 0 0 1-.197 1.134l-.078.396-.04.198a3.513 3.513 0 0 1-.071.294c-.146.54-.316 1.079-.506 1.603-.191.467-.4.927-.624 1.38-.223.445-.442.791-.681 1.184a17.14 17.14 0 0 1-2.79 3.34c-1.804 1.685-3.37 2.643-4.871 3.548-2.981 1.723-5.685 2.839-8.357 3.855-5.316 1.981-10.435 3.373-15.548 4.654-5.097 1.298-10.166 2.409-15.238 3.416a480.677 480.677 0 0 1-15.317 2.852m45.686-32.682c-.001 0-.063.047-.156.157.091-.076.143-.129.146-.147.007-.005.01-.008.01-.01"
          opacity={0.7}
        />
        <path
          fill="#2A4856"
          d="M183.522 171.836a.64.64 0 0 1-.596-.487.637.637 0 0 1 .311-.704l2.603-1.365a13.44 13.44 0 0 0 7.205-13.49l-2.825-25.584a8.538 8.538 0 0 0-16.474-2.095l-9.142 24.044a13.463 13.463 0 0 0 3.571 14.872l2.19 1.984a.63.63 0 0 1 0 .888.63.63 0 0 1-.889 0l-2.19-1.968a14.729 14.729 0 0 1-3.856-16.268l9.221-23.997a9.805 9.805 0 0 1 10.398-6.213 9.81 9.81 0 0 1 8.504 8.626l2.825 25.568a14.722 14.722 0 0 1-7.935 14.76l-2.603 1.349a.51.51 0 0 1-.318.08"
        />
        <path
          fill="#2A4856"
          d="M191.394 135.793a.617.617 0 0 1-.619-.54 9.399 9.399 0 0 0-8.587-7.713c-3.523-.445-8.316.666-10.236 5.301a.646.646 0 0 1-.337.374.623.623 0 0 1-.504.012.643.643 0 0 1-.353-.359.63.63 0 0 1 .019-.504 11.399 11.399 0 0 1 17.785-3.2 11.401 11.401 0 0 1 3.451 5.914.623.623 0 0 1-.524.699z"
        />
        <path fill="url(#doctor-with-stetho-and-clipboard_svg__d)" d="M0 51h328v268H0z" />
      </g>
      <defs>
        <linearGradient
          id="doctor-with-stetho-and-clipboard_svg__b"
          x1={66.903}
          x2={138.599}
          y1={76.379}
          y2={308.061}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3BD" />
          <stop offset={0.626} stopColor="#FFF4E5" />
        </linearGradient>
        <linearGradient
          id="doctor-with-stetho-and-clipboard_svg__c"
          x1={201.243}
          x2={195.137}
          y1={149.726}
          y2={186.769}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4DEE2" stopOpacity={0} />
          <stop offset={1} stopColor="#B2C4CB" />
        </linearGradient>
        <linearGradient
          id="doctor-with-stetho-and-clipboard_svg__d"
          x1={164}
          x2={164}
          y1={220.004}
          y2={319}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <clipPath id="doctor-with-stetho-and-clipboard_svg__a">
          <path fill="#fff" d="M0 0h328v317H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgDoctorWithStethoAndClipboard;
