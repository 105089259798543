import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDoor = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M14 11.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V12a.9.9 0 0 1 .9-.9M2.1 21a.9.9 0 0 1 .9-.9h18a.9.9 0 0 1 0 1.8H3a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M8 3.9A1.1 1.1 0 0 0 6.9 5v16a.9.9 0 1 1-1.8 0V5A2.9 2.9 0 0 1 8 2.1h8A2.9 2.9 0 0 1 18.9 5v16a.9.9 0 0 1-1.8 0V5A1.1 1.1 0 0 0 16 3.9z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDoor;
