import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPin = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M15.636 3.864a.9.9 0 0 1 0 1.272l-4 4a.9.9 0 0 1-.32.207l-3.817 1.431-.726.726 5.727 5.727.726-.726 1.431-3.817a.901.901 0 0 1 .207-.32l4-4a.9.9 0 0 1 1.272 1.272L16.274 13.5l-1.431 3.817a.9.9 0 0 1-.207.32l-1.5 1.5a.9.9 0 0 1-1.272 0l-7-7a.9.9 0 0 1 0-1.272l1.5-1.5a.9.9 0 0 1 .32-.207l3.818-1.431 3.862-3.862a.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.636 14.364a.9.9 0 0 1 0 1.272l-4.5 4.5a.9.9 0 1 1-1.272-1.272l4.5-4.5a.9.9 0 0 1 1.272 0M13.864 3.364a.9.9 0 0 1 1.272 0l5.5 5.5a.9.9 0 1 1-1.272 1.272l-5.5-5.5a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPin;
