import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgChartPie = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9.895 2.306A1.8 1.8 0 0 1 11.9 4v7a1.1 1.1 0 0 0 1.1 1.1h6.8a1.9 1.9 0 0 1 1.9 1.9.897.897 0 0 1-.023.203 9.9 9.9 0 1 1-11.88-11.88.902.902 0 0 1 .098-.017m.205 1.795a8.1 8.1 0 1 0 9.786 9.848.1.1 0 0 0-.086-.049H13a2.9 2.9 0 0 1-2.9-2.9z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M14.48 2.766a.9.9 0 0 1 .819-.115 9.9 9.9 0 0 1 6.05 6.05A.9.9 0 0 1 20.5 9.9H16A1.9 1.9 0 0 1 14.1 8V3.5a.9.9 0 0 1 .38-.735m1.42 2.118V8a.1.1 0 0 0 .1.1h3.116A8.1 8.1 0 0 0 15.9 4.884"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgChartPie;
