import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLink1 = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M13.364 4.364a4.435 4.435 0 1 1 6.272 6.272l-3.997 3.997a4.399 4.399 0 0 1-6.282-.003.9.9 0 0 1 1.286-1.26 2.6 2.6 0 0 0 3.714 0l.007-.006 4-4a2.636 2.636 0 0 0-3.728-3.728l-.5.5a.9.9 0 0 1-1.272-1.272z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.795 8.393a4.4 4.4 0 0 1 4.848.977.9.9 0 1 1-1.286 1.26 2.6 2.6 0 0 0-3.714 0l-.007.006-4 4a2.636 2.636 0 0 0 3.728 3.728l.5-.5a.9.9 0 1 1 1.272 1.272l-.5.5a4.436 4.436 0 0 1-6.272-6.272L8.36 9.367a4.4 4.4 0 0 1 1.434-.974"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgLink1;
