import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLockOpen = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M7 11.9A1.1 1.1 0 0 0 5.9 13v6A1.1 1.1 0 0 0 7 20.1h10a1.1 1.1 0 0 0 1.1-1.1v-6a1.1 1.1 0 0 0-1.1-1.1zM4.1 13A2.9 2.9 0 0 1 7 10.1h10a2.9 2.9 0 0 1 2.9 2.9v6a2.9 2.9 0 0 1-2.9 2.9H7A2.9 2.9 0 0 1 4.1 19z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 15.9a.1.1 0 1 0 0 .2.1.1 0 0 0 0-.2m-1.9.1a1.9 1.9 0 1 1 3.8 0 1.9 1.9 0 0 1-3.8 0M12 2.9A3.1 3.1 0 0 0 8.9 6v5a.9.9 0 1 1-1.8 0V6a4.9 4.9 0 1 1 9.8 0 .9.9 0 0 1-1.8 0A3.1 3.1 0 0 0 12 2.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgLockOpen;
