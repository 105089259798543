import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgHeartColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#FFF4E5"
        d="M19.5 13.572 12 21l-7.5-7.428m0 0A5 5 0 1 1 12 7.006a5 5 0 1 1 7.5 6.572"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M8.464 5.514a4.5 4.5 0 0 0-3.616 7.7l.003.003L12 20.297l7.149-7.08a.488.488 0 0 1 .048-.042 4.502 4.502 0 0 0-1.836-7.452A4.5 4.5 0 0 0 12.4 7.307a.5.5 0 0 1-.801-.003 4.5 4.5 0 0 0-3.134-1.79m11.338 8.462-7.45 7.38a.5.5 0 0 1-.704 0L4.15 13.93l-.002-.001a5.5 5.5 0 1 1 7.853-7.7 5.498 5.498 0 0 1 7.618-.355 5.5 5.5 0 0 1 .23 8.06.52.52 0 0 1-.048.042"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgHeartColored;
