import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPaint = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M7 3.9A1.1 1.1 0 0 0 5.9 5v2A1.1 1.1 0 0 0 7 8.1h10A1.1 1.1 0 0 0 18.1 7V5A1.1 1.1 0 0 0 17 3.9zM4.1 5A2.9 2.9 0 0 1 7 2.1h10A2.9 2.9 0 0 1 19.9 5v2A2.9 2.9 0 0 1 17 9.9H7A2.9 2.9 0 0 1 4.1 7z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M18.1 6a.9.9 0 0 1 .9-.9h1A2.9 2.9 0 0 1 22.9 8a5.9 5.9 0 0 1-5.9 5.9h-4.1V15a.9.9 0 1 1-1.8 0v-2a.9.9 0 0 1 .9-.9h5A4.1 4.1 0 0 0 21.1 8 1.1 1.1 0 0 0 20 6.9h-1a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11 15.9a.1.1 0 0 0-.1.1v4a.1.1 0 0 0 .1.1h2a.1.1 0 0 0 .1-.1v-4a.1.1 0 0 0-.1-.1zm-1.9.1c0-1.05.85-1.9 1.9-1.9h2c1.05 0 1.9.85 1.9 1.9v4a1.9 1.9 0 0 1-1.9 1.9h-2A1.9 1.9 0 0 1 9.1 20z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPaint;
