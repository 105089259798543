import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgFemaleDoctorWithSmartphone = ({
  title,
  titleId,
  size = 16,
  className,
}: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 328 317"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#female-doctor-with-smartphone_svg__a)">
        <path
          fill="url(#female-doctor-with-smartphone_svg__b)"
          d="M24 233c0-77.32 62.68-140 140-140s140 62.68 140 140v89H24z"
        />
        <path
          fill="#263238"
          d="M193.907 63.997c-4.895 13.38 8.119 20.108 16.649 39.82 8.53 19.712-12.047 43.22-43.63 43.733-31.584.513-32.58-29.737-27.685-51.442 4.895-21.705-5.335-25.238-3.166-41.813 2.785-21.78 34.163-38.282 46.62-18.291 11.08-1.04 15.492 16.297 11.212 27.993"
        />
        <path
          fill="#BECED3"
          fillRule="evenodd"
          d="m123.69 125.538 2.287-16.034s-.168-5.31 9.526-8.182c9.694-2.873 14.935 12.359 14.935 12.359l-3.269 15.828-3.136 15.946c-.197.981-.392 1.963-.586 2.944l-16.92-5.788-4.931-3.138z"
          clipRule="evenodd"
        />
        <path
          fill="#BECED3"
          d="M122.219 135.07c-2.154 15.08-3.088 13.891-10.06 46.36-1.287 5.991-.374 13.579 5.034 16.472 10.053 4.308 17.606-1.606 20.824-6.822l3.949-45.912z"
        />
        <path
          fill="#263238"
          d="M129.992 108.273c-.689 7.811-.073 27.245 7.768 75.419h54.227c-.865-20.929-.924-34.266 8.06-74.203a6.747 6.747 0 0 0-5.496-8.149 148.721 148.721 0 0 0-15.989-1.744 172.8 172.8 0 0 0-24.652 0c-6.741.616-14.055 1.832-18.481 2.638a6.716 6.716 0 0 0-5.437 6.039"
        />
        <path
          fill="#FFB573"
          d="M176.174 71.838c-1.554 7.929-3.107 22.467 2.447 27.758 0 0-6.947 8.442-17.587 8.442s-7.079-8.442-7.079-8.442c8.794-2.11 8.618-8.676 7.079-14.832z"
        />
        <path
          fill="#000"
          d="m170.003 77.113-8.955 7.636c.38 1.44.635 2.912.762 4.397 3.386-.484 8.691-3.371 9.424-7.05.337-1.744-.938-4.162-1.231-4.983"
          opacity={0.2}
        />
        <path
          fill="#FFB573"
          d="M185.509 56.273c-2.066 13.103-2.667 18.686-9.966 24.813-10.992 9.218-26.63 3.81-28.52-9.644-1.7-12.106 1.978-31.51 15.374-35.511a18.098 18.098 0 0 1 23.112 20.342"
        />
        <path
          fill="#263238"
          d="M183.326 65.712c-7.504-7.43-10.083-17.763-9.38-21.984-7.225 2.008-12.531-.835-12.208-2.066-5.1 4.983-14.348 8.925-13.308 3.561 1.041-5.364 7.885-15.257 22.16-11.725s19.771 18.188 19.566 24.139c-.206 5.95-6.83 8.075-6.83 8.075M153.791 58.996c.609.886.632 1.939.046 2.335-.586.396-1.541.01-2.15-.875-.609-.886-.631-1.938-.055-2.346.577-.407 1.55 0 2.159.886"
        />
        <path fill="#263238" d="m151.912 57.99-2.551.86s2.251.904 2.551-.86" />
        <path
          fill="#ED893E"
          d="M158.073 58.882a28.755 28.755 0 0 1-4.47 6.492 4.661 4.661 0 0 0 3.752 1.085z"
        />
        <path
          fill="#263238"
          d="M161.58 75.728c-.524.097-1.055.159-1.587.184a.322.322 0 0 1-.317-.317.295.295 0 0 1 .182-.294.292.292 0 0 1 .121-.022 8.573 8.573 0 0 0 6.898-3.485.326.326 0 0 1 .413-.062.32.32 0 0 1 .128.397 8.909 8.909 0 0 1-5.838 3.599"
        />
        <path
          fill="#FFB573"
          d="M188.411 66.928a9.743 9.743 0 0 1-5.217 5.261c-3.137 1.29-5.115-1.465-4.573-4.587.484-2.931 2.711-6.991 6.024-6.932a4.4 4.4 0 0 1 4.176 4.164 4.382 4.382 0 0 1-.41 2.094"
        />
        <path
          fill="#263238"
          d="M170.883 52.126a.645.645 0 0 1-.718-.206c-1.847-2.271-5.613-2.051-5.643-2.051a.646.646 0 0 1-.648-.833.622.622 0 0 1 .32-.367.611.611 0 0 1 .24-.06c.176 0 4.47-.265 6.713 2.52a.649.649 0 0 1-.088.894zM150.116 50.791a.63.63 0 0 1-.381-1.157 6.314 6.314 0 0 1 5.716-.806.644.644 0 0 1 .198 1.042.631.631 0 0 1-.697.13 5.01 5.01 0 0 0-4.514.689.644.644 0 0 1-.322.102"
        />
        <path
          fill="#295C6C"
          d="M137.76 183.691s-16.986 102.958-23.904 143.101c-7.211 41.74-23.45 139.978-23.45 139.978l17.852 2.931s24.065-98.341 33.708-139.231c10.464-44.627 35.819-104.467 35.555-146.661z"
        />
        <path
          fill="#000"
          d="M165.035 215.29c2.55-3.708 7.46-8.911 11.227-13.19-3.415 26.234-13.191 56.674-22.116 85.341-2.374-21.837-1.026-54.96 10.889-72.151"
          opacity={0.2}
        />
        <path
          fill="#295C6C"
          d="M153.017 183.691s5.423 104.277 9.087 144.874c3.825 42.238 8.427 137.956 8.427 137.956h18.086s3.224-95.351 1.964-137.003c-1.363-45.434 9.555-103.632 2.183-145.827z"
        />
        <path
          fill="#263238"
          d="M165.96 60.138c.618.897.643 1.929.055 2.346-.588.416-1.55 0-2.159-.886-.609-.886-.632-1.939-.055-2.346.576-.407 1.471.066 2.159.886"
        />
        <path
          fill="#263238"
          d="m164.047 59.16-2.551.86s2.24.913 2.551-.86M192.867 180.512l1.377 4.865c.191.367-.249.763-.879.763h-55.868c-.499 0-.894-.25-.924-.557l-.498-4.851c0-.338.396-.631.923-.631h54.975a1.006 1.006 0 0 1 .894.411"
        />
        <path
          fill="#295C6C"
          d="M185.289 186.652h1.466c.293 0 .513-.147.498-.322l-.689-6.317c0-.191-.278-.337-.571-.337h-1.466c-.293 0-.513.146-.498.337l.703 6.317c.015.175.264.322.557.322M141.629 186.652h1.466c.293 0 .528-.147.498-.322l-.689-6.317c0-.191-.278-.337-.571-.337h-1.466c-.293 0-.513.146-.498.337l.689 6.317c.029.175.278.322.571.322M163.378 186.652h1.466c.293 0 .513-.147.498-.322l-.689-6.317c0-.191-.278-.337-.571-.337h-1.466c-.293 0-.513.146-.484.337l.689 6.317c0 .175.264.322.557.322"
        />
        <path
          fill="#91ADB4"
          d="M180.248 99.156c8.515.777 21.529-.835 21.133 12.223-.395 13.059 2.14 47.647 1.466 63.841-.674 16.195 3.547 121.058-3.283 151.454-1.202 5.35-41.726 2.507-47.06 1.994v-4.822s-32.053-4.309-39.982-2.931c.689-18.261 14.847-108.351 15.536-134.263.689-25.912-1.707-71.086-1.531-78.15.142-5.677 13.658-9.152 22.261-8.976-1.906 6.375 2.338 17.979 9.065 31.331 16.547-16.122 21.427-24.124 22.395-31.7"
        />
        <path
          fill="#295C6C"
          d="M180.966 99.157c8.515.776 21.53-.836 21.134 12.222-.396 13.059 2.14 47.647 1.466 63.842-.675 16.194 3.546 121.057-3.283 151.454-1.202 5.349-45.316 4.66-50.651 4.147V326s-32.053-4.308-39.981-2.931c.688-18.261 18.437-110.505 19.126-136.417.688-25.911-2.228-70.436-2.052-77.5.176-7.064 14.216-10.171 22.819-9.996-1.905 6.376 2.301 18.35 9.028 31.701 16.547-16.121 21.427-24.123 22.394-31.7"
        />
        <path
          fill="#fff"
          d="M180.966 99.157c8.515.776 21.53-.836 21.134 12.222-.396 13.059 2.14 47.647 1.466 63.842-.675 16.194 3.546 121.057-3.283 151.454-1.202 5.349-45.316 4.66-50.651 4.147v-4.821s-32.053-4.309-39.981-2.932c.688-18.261 18.437-110.505 19.126-136.417.688-25.911-2.228-70.436-2.052-77.5.176-7.064 14.216-10.171 22.819-9.996-1.905 6.376 2.301 18.35 9.028 31.701 16.547-16.121 21.427-24.123 22.394-31.7"
          opacity={0.7}
        />
        <path
          fill="#295C6C"
          d="m197.175 130.124 5.452 3.855c.425 11.285.997 23.933 1.056 33.708-5.071-11.212-7.929-27.392-6.508-37.563"
          opacity={0.2}
        />
        <path
          fill="#295C6C"
          d="M158.557 130.856c-1.539 31.276-9.893 140.345-8.94 195.144l-2.551-.337s3.957-84.272 5.746-121.146c1.788-36.874 5.745-73.661 5.745-73.661M183.882 208.943l15.653 16.943c0 5.686 3.971 17.059-6.552 21.192-10.523 4.133-10.801-25.853-9.101-38.135M132.851 208.416l-6.889 17.265c-.205 5.862-2.213 17.587 1.862 22.057 4.074 4.47 5.261-26.615 5.027-39.322"
          opacity={0.3}
        />
        <path
          fill="#FFB573"
          d="m180.672 144.338-4.968-5.162c-1.683-1.75-4.394-2.093-6.403-.813l-5.048 3.215c-2.301 1.468-2.857 4.513-1.234 6.754l2.275 3.137a24.06 24.06 0 0 0 7.409 6.69l8.042 4.666 7.172-4.661c3.254-5.973-7.245-13.826-7.245-13.826"
        />
        <path
          fill="#91ADB4"
          d="M220.95 179.943c.125-.123-1.779-2.178-4.591-4.174-2.804-2.006-5.376-3.142-5.454-2.985-.091.172 2.302 1.553 5.061 3.527 2.764 1.963 4.848 3.772 4.984 3.632M132.815 196.529l6.611-35.55c1.8.365 3.613.292 5.43.028 2.292-.333 4.587-.969 6.862-1.413.409-.081.825-.152 1.246-.213 3.772-.548 7.896-.255 10.47 2.419 1.649 1.713 2.34 4.095 2.976 6.377.472 1.693.925 3.586.084 5.136-.657 1.211-1.961 1.921-3.225 2.49a36.596 36.596 0 0 1-9.378 2.787 48.38 48.38 0 0 1-.711.107l-.469.067-.466.068c-2.614.38-5.277.85-7.079 2.684-1.587 1.617-2.123 3.936-3.027 6.001-1.77 4.046-5.196 7.357-9.324 9.012"
        />
        <path
          fill="#17282F"
          stroke="#fff"
          d="m165.033 134.303 2.262 32.326a9.532 9.532 0 0 1-.38 3.373c-.262.864-.604 1.499-.897 1.837l-20.065.427c-.942-.623-1.565-1.736-1.724-3.432l-3.165-33.772c-.106-1.135.045-2.242.338-3.044.148-.403.321-.702.493-.891.172-.188.302-.226.383-.224m22.755 3.4.499-.035c-.136-1.942-.86-3.346-1.735-3.367l-21.508-.498-.011.5m22.755 3.4c-.064-.925-.268-1.69-.536-2.21-.282-.548-.557-.688-.711-.692l-21.508-.498m22.755 3.4-22.755-3.4"
        />
        <path
          fill="#BECED3"
          fillRule="evenodd"
          d="M146.205 169.12c-.059.805-.162 1.501-.325 1.986-.436 1.293-.881 2.884-1.36 4.597-1.07 3.825-2.311 8.26-3.999 11.356-4.564 8.368-6.435 9.842-14.152 12.106-7.717 2.264-10.254-18.475-10.254-18.475l16.516-16.538.387-.527z"
          clipRule="evenodd"
        />
        <path
          fill="#91ADB4"
          d="M118.252 189.285c.133-.013-.739-2.077-.205-5.453.265-1.666.885-3.604 1.959-5.537 1.068-1.935 2.604-3.832 4.38-5.638 1.784-1.807 3.619-3.387 5.255-4.873 1.64-1.481 3.232-3.519 4.348-4.786 1.116-1.268 1.764-2.828 2.261-3.638.5-.81.715-1.296.69-1.311-.04-.025-.316.42-.864 1.181-.547.764-1.229 2.269-2.368 3.486-1.138 1.218-2.743 3.209-4.395 4.664-1.648 1.459-3.501 3.032-5.308 4.862-1.801 1.832-3.361 3.78-4.437 5.789-1.082 2.004-1.682 4.016-1.905 5.739-.218 1.729-.083 3.153.127 4.094.109.472.209.833.305 1.069.086.236.139.358.157.352"
        />
        <path
          fill="#FFB573"
          d="m135.866 165.348 1.873-11.122a9.698 9.698 0 0 1 1.474-3.747l3.453-5.226c1.413-2.14 4.237-2.661 6.257-1.154l4.436 3.307c1.766 1.317 2.351 3.775 1.381 5.805l-2.86 5.995s-3.392 11.662-9.633 10.814z"
        />
        <path
          fill="#BECED3"
          d="M149.865 166.307c-2.873 3.159-6.104 10.771-6.822 14.361-4.548-3.71-14.075-11.848-11.489-14.72 2.585-2.873 4.667-6.463 5.385-7.899 3.232 2.872 8.617 6.463 12.926 8.258"
        />
        <path
          fill="#BECED3"
          fillRule="evenodd"
          d="M178.9 116.789c.251.428.524.841.819 1.24l.286.37.15.185.274.326.546.665 1.09 1.374a82.953 82.953 0 0 1 2.147 2.805 124.71 124.71 0 0 1 4.046 5.815 135.763 135.763 0 0 1 7.279 12.196c4.378 7.752 7.859 15.467 9.32 24.25.316 1.705.462 2.165.583 2.547.032.1.062.195.093.306l.669 3.375-15.114-17.51-2.569.064-8.645 8.011s6.149 15.506 9.904 21.628c4.885 7.963 12.592 20.119 24.184 19.853 10.715-.245 14.351-15.23 13.538-26.866-.453-6.485-.808-12.218-.808-12.218l-.123.021a59.83 59.83 0 0 0-.087-.95 83.386 83.386 0 0 0-3.83-17.402 128.734 128.734 0 0 0-6.378-15.635c-2.393-4.994-5.064-9.784-7.843-14.478a177.562 177.562 0 0 0-4.374-6.936l-2.3-3.417-1.201-1.718-.607-.86-.072-.095c-.061-.091-.122-.182-.185-.273l-.19-.247.134.199.241.321c.174.262.341.526.498.797z"
          clipRule="evenodd"
        />
        <path
          fill="url(#female-doctor-with-smartphone_svg__c)"
          fillRule="evenodd"
          d="M178.9 116.789c.251.428.524.841.819 1.24l.286.37.15.185.274.326.546.665 1.09 1.374a82.953 82.953 0 0 1 2.147 2.805 124.71 124.71 0 0 1 4.046 5.815 135.763 135.763 0 0 1 7.279 12.196c4.378 7.752 7.859 15.467 9.32 24.25.316 1.705.462 2.165.583 2.547.032.1.062.195.093.306l.669 3.375-15.114-17.51-2.569.064-8.645 8.011s6.149 15.506 9.904 21.628c4.885 7.963 12.592 20.119 24.184 19.853 10.715-.245 14.351-15.23 13.538-26.866-.453-6.485-.808-12.218-.808-12.218l-.123.021a59.83 59.83 0 0 0-.087-.95 83.386 83.386 0 0 0-3.83-17.402 128.734 128.734 0 0 0-6.378-15.635c-2.393-4.994-5.064-9.784-7.843-14.478a177.562 177.562 0 0 0-4.374-6.936l-2.3-3.417-1.201-1.718-.607-.86-.072-.095c-.061-.091-.122-.182-.185-.273l-.19-.247.134.199.241.321c.174.262.341.526.498.797z"
          clipRule="evenodd"
          opacity={0.7}
        />
        <path fill="url(#female-doctor-with-smartphone_svg__d)" d="M0 51h328v268H0z" />
      </g>
      <defs>
        <linearGradient
          id="female-doctor-with-smartphone_svg__b"
          x1={66.903}
          x2={138.599}
          y1={76.379}
          y2={308.061}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3BD" />
          <stop offset={0.626} stopColor="#FFF4E5" />
        </linearGradient>
        <linearGradient
          id="female-doctor-with-smartphone_svg__c"
          x1={205.055}
          x2={202.648}
          y1={133.652}
          y2={156.89}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4DEE2" stopOpacity={0} />
          <stop offset={1} stopColor="#B2C4CB" />
        </linearGradient>
        <linearGradient
          id="female-doctor-with-smartphone_svg__d"
          x1={164}
          x2={164}
          y1={220.004}
          y2={319}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <clipPath id="female-doctor-with-smartphone_svg__a">
          <path fill="#fff" d="M0 0h328v317H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgFemaleDoctorWithSmartphone;
