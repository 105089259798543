import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLocationMarker = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M16.95 6.05a7 7 0 1 0-9.9 9.9l4.244 4.243a.998.998 0 0 0 1.413 0l4.243-4.243a7 7 0 0 0 0-9.9M5.636 4.636a9 9 0 0 1 12.728 12.728l-3.502 3.502-.018.018-.723.723a2.998 2.998 0 0 1-4.241 0l-4.244-4.243a9 9 0 0 1 0-12.728M12 9a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgLocationMarker;
