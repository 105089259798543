import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLoad = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M14.794 5.439a7.131 7.131 0 0 0-5.458-.054.9.9 0 0 1-.672-1.67A8.931 8.931 0 0 1 17.418 19.1H20a.9.9 0 0 1 0 1.8h-5a.9.9 0 0 1-.9-.9v-5a.9.9 0 1 1 1.8 0v2.97A7.132 7.132 0 0 0 14.794 5.44M5.63 6.26a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0v-.01a.9.9 0 0 1 .9-.9M4.06 10.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V11a.9.9 0 0 1 .9-.9M4.63 14.2a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0v-.01a.9.9 0 0 1 .9-.9M7.16 17.47a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0v-.01a.9.9 0 0 1 .9-.9M11 19.04a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0v-.01a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgLoad;
