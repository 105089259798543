import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgAdjustments = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9 4.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2M5.1 7a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3.1 7a.9.9 0 0 1 .9-.9h2a.9.9 0 0 1 0 1.8H4a.9.9 0 0 1-.9-.9M11.1 7a.9.9 0 0 1 .9-.9h8a.9.9 0 0 1 0 1.8h-8a.9.9 0 0 1-.9-.9M15 14.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2M11.1 17a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3.1 17a.9.9 0 0 1 .9-.9h8a.9.9 0 0 1 0 1.8H4a.9.9 0 0 1-.9-.9M17.1 17a.9.9 0 0 1 .9-.9h2a.9.9 0 0 1 0 1.8h-2a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgAdjustments;
