import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgEdit = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3.95 6.95A2.9 2.9 0 0 1 6 6.1h3a.9.9 0 0 1 0 1.8H6A1.1 1.1 0 0 0 4.9 9v9A1.1 1.1 0 0 0 6 19.1h9a1.1 1.1 0 0 0 1.1-1.1v-3a.9.9 0 1 1 1.8 0v3a2.9 2.9 0 0 1-2.9 2.9H6A2.9 2.9 0 0 1 3.1 18V9a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M16.864 2.864a3.021 3.021 0 0 1 4.272 4.272l-8.5 8.5A.9.9 0 0 1 12 15.9H9a.9.9 0 0 1-.9-.9v-3a.9.9 0 0 1 .264-.636zM19 3.779c-.324 0-.635.128-.864.357L9.9 12.373V14.1h1.727l8.237-8.236A1.221 1.221 0 0 0 19 3.779"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgEdit;
