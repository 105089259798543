import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgQrcode = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5 4.9a.1.1 0 0 0-.1.1v4a.1.1 0 0 0 .1.1h4a.1.1 0 0 0 .1-.1V5a.1.1 0 0 0-.1-.1zM3.1 5c0-1.05.85-1.9 1.9-1.9h4c1.05 0 1.9.85 1.9 1.9v4A1.9 1.9 0 0 1 9 10.9H5A1.9 1.9 0 0 1 3.1 9zM7 16.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V17a.9.9 0 0 1 .9-.9M15 4.9a.1.1 0 0 0-.1.1v4a.1.1 0 0 0 .1.1h4a.1.1 0 0 0 .1-.1V5a.1.1 0 0 0-.1-.1zm-1.9.1c0-1.05.85-1.9 1.9-1.9h4c1.05 0 1.9.85 1.9 1.9v4a1.9 1.9 0 0 1-1.9 1.9h-4A1.9 1.9 0 0 1 13.1 9z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7 6.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V7a.9.9 0 0 1 .9-.9M5 14.9a.1.1 0 0 0-.1.1v4a.1.1 0 0 0 .1.1h4a.1.1 0 0 0 .1-.1v-4a.1.1 0 0 0-.1-.1zm-1.9.1c0-1.05.85-1.9 1.9-1.9h4c1.05 0 1.9.85 1.9 1.9v4A1.9 1.9 0 0 1 9 20.9H5A1.9 1.9 0 0 1 3.1 19zM17 6.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V7a.9.9 0 0 1 .9-.9M13.1 14a.9.9 0 0 1 .9-.9h3a.9.9 0 0 1 0 1.8h-3a.9.9 0 0 1-.9-.9M20 13.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V14a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M14 13.1a.9.9 0 0 1 .9.9v3a.9.9 0 0 1-1.8 0v-3a.9.9 0 0 1 .9-.9M13.1 20a.9.9 0 0 1 .9-.9h3a.9.9 0 0 1 0 1.8h-3a.9.9 0 0 1-.9-.9M16.1 17a.9.9 0 0 1 .9-.9h3a.9.9 0 0 1 0 1.8h-3a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M20 16.1a.9.9 0 0 1 .9.9v3a.9.9 0 0 1-1.8 0v-3a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgQrcode;
