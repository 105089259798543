import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMicrophone = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 2.9A2.1 2.1 0 0 0 9.9 5v5a2.1 2.1 0 0 0 4.2 0V5A2.1 2.1 0 0 0 12 2.9M8.1 5a3.9 3.9 0 0 1 7.8 0v5a3.9 3.9 0 0 1-7.8 0z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5 9.1a.9.9 0 0 1 .9.9 6.1 6.1 0 1 0 12.2 0 .9.9 0 1 1 1.8 0 7.9 7.9 0 1 1-15.8 0 .9.9 0 0 1 .9-.9M7.1 21a.9.9 0 0 1 .9-.9h8a.9.9 0 0 1 0 1.8H8a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 16.1a.9.9 0 0 1 .9.9v4a.9.9 0 0 1-1.8 0v-4a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMicrophone;
