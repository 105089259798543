import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgSad = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 3.9a8.1 8.1 0 1 0 0 16.2 8.1 8.1 0 0 0 0-16.2M2.1 12c0-5.468 4.432-9.9 9.9-9.9s9.9 4.432 9.9 9.9-4.432 9.9-9.9 9.9-9.9-4.432-9.9-9.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M8.1 10a.9.9 0 0 1 .9-.9h.01a.9.9 0 1 1 0 1.8H9a.9.9 0 0 1-.9-.9M14.1 10a.9.9 0 0 1 .9-.9h.01a.9.9 0 1 1 0 1.8H15a.9.9 0 0 1-.9-.9M12 15.1a2.6 2.6 0 0 0-1.857.78.9.9 0 0 1-1.286-1.26 4.4 4.4 0 0 1 6.286 0 .9.9 0 0 1-1.286 1.26A2.599 2.599 0 0 0 12 15.1"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgSad;
