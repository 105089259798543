import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgAlertColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#FFF4E5"
        d="M8.7 3h6.6c.3 0 .5.1.7.3L20.7 8c.2.2.3.4.3.7v6.6c0 .3-.1.5-.3.7L16 20.7c-.2.2-.4.3-.7.3H8.7c-.3 0-.5-.1-.7-.3L3.3 16c-.2-.2-.3-.4-.3-.7V8.7c0-.3.1-.5.3-.7L8 3.3c.2-.2.4-.3.7-.3"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M8.455 3.396a.694.694 0 0 0-.207.152l-4.7 4.7a.694.694 0 0 0-.152.207.584.584 0 0 0-.045.245v6.6c0 .109.018.184.045.245a.695.695 0 0 0 .152.207l4.7 4.7c.08.079.145.124.207.152.06.027.136.045.245.045h6.6a.583.583 0 0 0 .245-.045.697.697 0 0 0 .207-.152l4.7-4.7a.697.697 0 0 0 .152-.207.583.583 0 0 0 .045-.245V8.7a.583.583 0 0 0-.045-.245.696.696 0 0 0-.152-.207l-4.7-4.7a.695.695 0 0 0-.207-.152.584.584 0 0 0-.245-.045H8.7a.584.584 0 0 0-.245.045m-.285-.642c.164-.073.339-.105.53-.105h6.6c.191 0 .366.032.53.105.163.072.298.177.418.298l4.7 4.7c.121.12.226.255.298.418.073.164.105.339.105.53v6.6c0 .191-.032.366-.105.53a1.39 1.39 0 0 1-.298.418l-4.7 4.7a1.39 1.39 0 0 1-.418.298c-.164.073-.339.105-.53.105H8.7c-.191 0-.366-.032-.53-.105a1.39 1.39 0 0 1-.418-.298l-4.7-4.7a1.389 1.389 0 0 1-.298-.418 1.282 1.282 0 0 1-.105-.53V8.7c0-.191.032-.366.105-.53.072-.163.177-.298.298-.418l4.7-4.7c.12-.121.255-.226.418-.298"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12 7.649c.194 0 .351.157.351.351v4a.351.351 0 1 1-.702 0V8c0-.194.157-.351.351-.351M11.649 16c0-.194.157-.351.351-.351h.01a.351.351 0 0 1 0 .702H12a.351.351 0 0 1-.351-.351"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgAlertColored;
