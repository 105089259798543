import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCopy = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M10 8.9A1.1 1.1 0 0 0 8.9 10v8a1.1 1.1 0 0 0 1.1 1.1h8a1.1 1.1 0 0 0 1.1-1.1v-8A1.1 1.1 0 0 0 18 8.9zM7.1 10A2.9 2.9 0 0 1 10 7.1h8a2.9 2.9 0 0 1 2.9 2.9v8a2.9 2.9 0 0 1-2.9 2.9h-8A2.9 2.9 0 0 1 7.1 18z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3.95 3.95A2.9 2.9 0 0 1 6 3.1h8A2.9 2.9 0 0 1 16.9 6v2a.9.9 0 0 1-1.8 0V6A1.1 1.1 0 0 0 14 4.9H6A1.1 1.1 0 0 0 4.9 6v8A1.1 1.1 0 0 0 6 15.1h2a.9.9 0 1 1 0 1.8H6A2.9 2.9 0 0 1 3.1 14V6a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCopy;
