import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMaps = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3.471 14.062c.486-.548 1.766-1.513 3.84-1.513v-1.8c-2.988 0-4.864 1.57-5.488 2.507a.9.9 0 0 0-.152.5v6.493a.9.9 0 0 0 1.477.691c.61-.509 2.343-1.546 4.162-1.546 1.48 0 2.772.699 4.246 1.547l.185.107c1.348.778 2.91 1.68 4.696 1.68 2.831 0 4.663-1.438 5.29-2.3a.9.9 0 0 0 .173-.53v-6.143a.9.9 0 0 0-1.208-.846c-.167.061-.365.147-.591.244-.873.376-2.159.93-3.664.93-.514 0-1.117-.177-1.851-.509l-.742 1.64c.814.369 1.696.67 2.593.67 1.412 0 2.737-.413 3.663-.774v4.458c-.494.503-1.731 1.36-3.663 1.36-1.292 0-2.476-.68-3.983-1.547-1.422-.819-3.114-1.787-5.144-1.787-1.458 0-2.819.484-3.839 1.011zM12.049 6.933a1.206 1.206 0 1 0 0 2.412 1.206 1.206 0 0 0 0-2.412M9.043 8.139a3.006 3.006 0 1 1 6.012 0 3.006 3.006 0 0 1-6.012 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12.049 3.071a5.098 5.098 0 0 0-2.818.849 5.034 5.034 0 0 0-1.864 2.255 4.986 4.986 0 0 0-.288 2.9 5.012 5.012 0 0 0 1.384 2.572l3.166 3.142a.598.598 0 0 0 .84 0l3.165-3.142a5.012 5.012 0 0 0 1.385-2.572 4.985 4.985 0 0 0-.288-2.9 5.034 5.034 0 0 0-1.864-2.255 5.098 5.098 0 0 0-2.818-.849m4.854 9.853a6.812 6.812 0 0 0 1.881-3.495 6.786 6.786 0 0 0-.391-3.947 6.834 6.834 0 0 0-2.531-3.062 6.898 6.898 0 0 0-7.626 0 6.834 6.834 0 0 0-2.53 3.062 6.786 6.786 0 0 0-.392 3.947 6.811 6.811 0 0 0 1.881 3.495l3.166 3.143a2.398 2.398 0 0 0 3.377 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMaps;
