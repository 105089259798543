import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgGraphic = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3 2.1a.9.9 0 0 1 .9.9v17.1H21a.9.9 0 0 1 0 1.8H3a.9.9 0 0 1-.9-.9V3a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9 7.9a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2M6.1 9a2.9 2.9 0 1 1 5.8 0 2.9 2.9 0 0 1-5.8 0M19 5.9a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2M16.1 7a2.9 2.9 0 1 1 5.8 0 2.9 2.9 0 0 1-5.8 0M14 13.9a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2M11.1 15a2.9 2.9 0 1 1 5.8 0 2.9 2.9 0 0 1-5.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.592 9.922a.9.9 0 0 1 1.266.13l2.34 2.88a.9.9 0 1 1-1.396 1.136l-2.34-2.88a.9.9 0 0 1 .13-1.267M18.399 7.977a.9.9 0 0 1 .291 1.238l-2.837 4.587a.9.9 0 0 1-1.53-.947l2.837-4.586a.9.9 0 0 1 1.238-.292"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgGraphic;
