import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgUserFill = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.45 14C2.45 7.621 7.621 2.45 14 2.45c6.379 0 11.55 5.171 11.55 11.55 0 6.379-5.171 11.55-11.55 11.55-6.379 0-11.55-5.171-11.55-11.55"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14 9.217a2.45 2.45 0 1 0 0 4.9 2.45 2.45 0 0 0 0-4.9m-4.55 2.45a4.55 4.55 0 1 1 9.1 0 4.55 4.55 0 0 1-9.1 0M18.495 20.433a3.616 3.616 0 0 0-2.161-.716h-4.667a3.617 3.617 0 0 0-3.465 2.576 1.05 1.05 0 1 1-2.012-.605 5.716 5.716 0 0 1 5.477-4.071h4.666a5.717 5.717 0 0 1 5.48 4.08 1.05 1.05 0 1 1-2.013.601 3.616 3.616 0 0 0-1.305-1.865"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgUserFill;
