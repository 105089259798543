import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCheckColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#FFF4E5"
        d="M18 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M6 4.4A1.6 1.6 0 0 0 4.4 6v12A1.6 1.6 0 0 0 6 19.6h12a1.6 1.6 0 0 0 1.6-1.6V6A1.6 1.6 0 0 0 18 4.4zM3.6 6A2.4 2.4 0 0 1 6 3.6h12A2.4 2.4 0 0 1 20.4 6v12a2.4 2.4 0 0 1-2.4 2.4H6A2.4 2.4 0 0 1 3.6 18z"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M15.283 9.717a.4.4 0 0 1 0 .566l-4 4a.4.4 0 0 1-.566 0l-2-2a.4.4 0 0 1 .566-.566L11 13.434l3.717-3.717a.4.4 0 0 1 .566 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCheckColored;
