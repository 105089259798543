import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgGraph = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#0F1625"
        fillRule="evenodd"
        d="M6 5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1zM3.879 3.879A3 3 0 0 1 6 3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6a3 3 0 0 1 .879-2.121"
        clipRule="evenodd"
      />
      <path
        fill="#0F1625"
        fillRule="evenodd"
        d="M12 7a1 1 0 0 1 1 1v7.724a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1m4 1.38a1 1 0 0 1 1 1v6.344a1 1 0 1 1-2 0V9.38a1 1 0 0 1 1-1m-8 2.482a1 1 0 0 1 1 1V16a1 1 0 1 1-2 0v-4.138a1 1 0 0 1 1-1"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgGraph;
