import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLogoSecondary = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 871 288"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#fff"
        d="M871 103.191V89h-71v112h70.554v-14.191h-56.047v-39.027h47.791v-14.189h-47.791v-30.402zM759.636 179.775c-7.733 7.247-15.976 10.338-27.559 10.338-12.292 0-22.609-4.296-30.662-12.771-8.079-8.498-12.176-19.267-12.176-32.008 0-8.448 1.835-16.147 5.451-22.883 3.606-6.718 8.76-12.14 15.317-16.124 6.551-3.98 13.768-5.997 21.453-5.997 7.045 0 13.594 1.538 19.467 4.576 3.712 1.92 5.79 3.157 8.825 6.203l1.168 1.171 9.523-10.585-.697-.727c-3.398-3.349-6.729-5.915-12.036-8.725C749.884 88.1 741.007 86 731.322 86c-16.049 0-29.599 5.68-40.272 16.884C680.399 114.065 675 128.023 675 144.37c0 15.505 4.453 28.937 13.238 39.93 10.519 13.072 25.06 19.7 43.222 19.7 9.533 0 18.295-1.97 26.042-5.856a53.541 53.541 0 0 0 12.1-8.292l1.398-1.275-10.1-9.985zM624.7 169.111 554.976 89H550v112h14.818v-79.114L633.952 201H639V89h-14.3zM490.991 164.226h-42.458l21.266-46.096zM472.33 89h-4.968L414 201h16.142l11.691-22.73h55.591L509.269 201H525L472.814 90.033zM406.226 89l-35.517 81.046-34.476-79.961-.469-1.085H320l47.82 110.917.47 1.083h4.734L422 89zM277 201h15v-71h-15z"
      />
      <path
        fill="#00658A"
        d="M284.001 87C277.384 87 272 92.384 272 99.001c0 6.617 5.384 11.999 12.001 11.999 6.617 0 11.999-5.382 11.999-11.999C296 92.384 290.618 87 284.001 87"
      />
      <path fill="#fff" d="M202.347 89H188v112h62v-14.043h-47.653z" />
      <path
        fill="#00658A"
        d="M284.214 288H7.786A7.788 7.788 0 0 1 0 280.21V7.792C0 3.49 3.486 0 7.786 0h276.428c4.3 0 7.786 3.49 7.786 7.792V53.96a2.791 2.791 0 0 1-2.79 2.793h-9.993a2.79 2.79 0 0 1-2.789-2.793V15.582H15.572V272.42h260.856v-38.453a2.79 2.79 0 0 1 2.789-2.791h9.993a2.79 2.79 0 0 1 2.79 2.791v46.243a7.788 7.788 0 0 1-7.786 7.79"
      />
    </svg>
  );
};
export default SvgLogoSecondary;
