import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDuplicate1 = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.598 5.195a.9.9 0 0 1 .804 0l8 4a.9.9 0 0 1 0 1.61l-8 4a.9.9 0 0 1-.804 0l-8-4a.9.9 0 0 1 0-1.61zM6.012 10 12 12.994 17.988 10 12 7.006z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3.195 13.598a.9.9 0 0 1 1.207-.403L12 16.994l7.598-3.799a.9.9 0 1 1 .805 1.61l-8 4a.9.9 0 0 1-.806 0l-8-4a.9.9 0 0 1-.402-1.207"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDuplicate1;
