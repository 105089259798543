import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgJcb = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#1DD195"
        d="M17.363 13.98h1.53c.043 0 .145-.015.19-.015a.683.683 0 0 0 .538-.684.707.707 0 0 0-.539-.685c-.044-.015-.131-.015-.19-.015h-1.53z"
      />
      <path
        fill="#1DD195"
        d="M18.718 4.319a2.652 2.652 0 0 0-2.652 2.652v2.754h3.745c.087 0 .19 0 .262.014.845.044 1.472.481 1.472 1.24 0 .597-.423 1.107-1.21 1.209v.029c.86.058 1.516.539 1.516 1.282 0 .801-.729 1.326-1.69 1.326h-4.11v5.392h3.89a2.652 2.652 0 0 0 2.653-2.652V4.319z"
      />
      <path
        fill="#1DD195"
        d="M19.432 11.153a.62.62 0 0 0-.54-.627c-.028 0-.101-.014-.145-.014h-1.384v1.282h1.384c.044 0 .131 0 .146-.014a.62.62 0 0 0 .539-.627"
      />
      <path
        fill="#023DD1"
        d="M4.058 4.319A2.652 2.652 0 0 0 1.406 6.97v6.543c.743.364 1.516.597 2.288.597.918 0 1.414-.554 1.414-1.311V9.71H7.38v3.075c0 1.195-.743 2.171-3.264 2.171-1.53 0-2.725-.335-2.725-.335v5.581h3.89a2.652 2.652 0 0 0 2.653-2.652V4.32z"
      />
      <path
        fill="#EF4444"
        d="M11.388 4.319A2.652 2.652 0 0 0 8.736 6.97v3.468c.67-.568 1.836-.933 3.716-.845 1.005.043 2.084.32 2.084.32v1.122a5.044 5.044 0 0 0-2.011-.582c-1.428-.103-2.288.597-2.288 1.821 0 1.239.86 1.938 2.288 1.822.83-.059 1.472-.321 2.01-.583v1.122s-1.063.277-2.083.32c-1.88.088-3.046-.277-3.716-.845v6.12h3.89a2.652 2.652 0 0 0 2.653-2.652V4.32z"
      />
    </svg>
  );
};
export default SvgJcb;
