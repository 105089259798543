import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgUsers = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.627 15.072a4.455 4.455 0 0 1 3.15-1.305h7.112a4.455 4.455 0 0 1 4.455 4.455V20a.9.9 0 1 1-1.8 0v-1.778a2.655 2.655 0 0 0-2.655-2.655H5.778a2.655 2.655 0 0 0-2.656 2.655V20a.9.9 0 1 1-1.8 0v-1.778c0-1.181.47-2.315 1.305-3.15M9.333 4.9a2.656 2.656 0 1 0 0 5.311 2.656 2.656 0 0 0 0-5.311M4.878 7.556a4.456 4.456 0 1 1 8.91 0 4.456 4.456 0 0 1-8.91 0M18.24 14.557a.9.9 0 0 1 1.096-.646 4.455 4.455 0 0 1 3.342 4.31V20a.9.9 0 0 1-1.8 0v-1.777a2.656 2.656 0 0 0-1.992-2.57.9.9 0 0 1-.646-1.096M14.684 3.892a.9.9 0 0 1 1.095-.648 4.456 4.456 0 0 1 0 8.632.9.9 0 0 1-.447-1.743 2.656 2.656 0 0 0 0-5.146.9.9 0 0 1-.648-1.095"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgUsers;
