import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDoctorWithClipboard = ({
  title,
  titleId,
  size = 16,
  className,
}: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 328 285"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="url(#doctor-with-clipboard_svg__a)"
        d="M24 201c0-77.32 62.68-140 140-140s140 62.68 140 140v89H24z"
      />
      <path
        fill="#FFB573"
        d="M82.302 117.846c-.168.66-.505 1.321-1.179 1.816l-5.054 4.128c-1.01.825-2.358.99-3.537.495l-5.054-2.312c-4.885-2.146-9.265-5.283-12.971-9.41l-1.18-1.321c-2.021-2.312-1.684-5.779.674-7.76l5.222-4.293c2.022-1.65 5.054-1.65 7.076 0l6.064 5.119c0 .33 10.95 6.934 9.94 13.538"
      />
      <path
        fill="#E0E0E0"
        d="M85.504 120.488s-.337-4.788-1.685-7.429c-4.211-.826-14.993 7.924-16.34 11.557.842 1.981 6.064 6.108 6.064 6.108 3.201-3.632 9.434-7.759 11.96-10.236"
      />
      <path
        fill="#BECED3"
        d="M129.471 93.246c-1.516 4.953-3.032 9.906-4.717 14.859-1.684 4.953-3.369 9.906-5.053 14.694-1.685 4.788-3.538 9.741-5.56 14.199-1.01 2.311-2.021 4.458-2.863 6.439-.506.991-1.011 1.816-1.348 2.476l-.337.331-.168.165s0 .165 0 0c.168-.165.336-.33.505-.33l1.011-.826c.168-.165.674-.33 1.347-.495.674-.165 1.18 0 1.517 0 .505.165-.337.165-1.517-.661-2.358-1.32-5.559-4.127-8.423-7.264-2.863-3.137-5.727-6.769-8.423-10.567a69.65 69.65 0 0 1-3.874-5.778l-1.853-2.972-1.685-2.807-21.394 10.897c.674 1.321 1.348 2.477 1.853 3.632l2.021 3.467 4.043 6.935c2.864 4.622 5.897 9.08 9.603 13.538 1.853 2.311 3.706 4.458 5.896 6.604s4.717 4.458 7.749 6.439c3.032 1.981 6.907 4.127 12.466 5.118 2.695.495 5.896.33 9.097-.33 3.032-.661 5.896-2.146 8.254-3.632.337-.165.674-.496 1.011-.826.674-.66 1.348-1.156 1.853-1.816.505-.495.842-.991 1.348-1.486l.505-.66.505-.661.843-1.321c1.01-1.485 1.853-2.971 2.695-4.292 1.516-2.807 2.864-5.449 4.212-7.925 2.526-5.118 4.716-10.236 6.906-15.354 2.19-5.119 4.212-10.237 6.233-15.19a414.445 414.445 0 0 0 5.728-15.354z"
      />
      <path
        fill="#355A6B"
        d="M161.647 346.842c19.879-61.583 35.208-136.704 35.208-136.704l-59.634-5.779s-3.875 96.585-7.581 139.181c-3.874 44.412-19.036 147.601-19.036 147.601l18.194 1.485c-.169.166 30.996-90.64 32.849-145.784"
      />
      <path
        fill="#000"
        d="m173.44 260.494 8.254 14.529a1385.833 1385.833 0 0 1-14.656 53.823c.843-28.727 3.875-61.748 6.402-68.352"
        opacity={0.2}
      />
      <path
        fill="#355A6B"
        d="M219.597 342.714c-1.011-63.399 3.032-105.665-2.527-137.034l-54.581-.826s13.309 100.547 20.047 142.483c6.738 42.101 15.161 145.289 15.161 145.289h16.846c.169 0 14.825-100.877 5.054-149.912"
      />
      <path
        fill="#fff"
        d="M214.712 79.213c-19.205-2.312-46.832-2.147-65.362.99-9.434 1.651-16.846 9.246-18.194 18.492-3.875 27.406-.506 75.121 1.853 123.165 42.115 14.199 86.757-.165 86.757-.165 1.516-9.741-1.853-41.44-4.212-58.446 3.538-17.501 10.95-37.643 15.667-60.922 2.527-11.227-5.054-21.794-16.509-23.114"
      />
      <path
        fill="#FFB573"
        d="m197.529 43.056-23.752 17.666c2.863 7.43 3.706 15.189-8.592 17.996-3.032 3.962 2.359 8.585 7.412 10.731 8.255-.99 19.879-3.632 24.932-10.566-1.684-5.118-2.19-24.765 0-35.827"
      />
      <path
        fill="#2A4856"
        d="M167.206 85.157s1.179 3.632 4.548 6.769c3.369 0 8.255-3.633 8.255-3.633s-1.853-5.283-4.549-6.274c-6.738 1.486-8.254 3.137-8.254 3.137"
      />
      <path
        fill="#2A4856"
        d="M174.956 89.944c-2.021-1.816-3.706-1.156-3.706-1.156-4.38 8.09-11.287 57.62-13.982 72.149 1.179 8.751 2.864 11.723 5.222 21.794 2.19-7.76 5.222-12.383 8.591-20.968-1.01-10.567 2.359-58.611 3.875-71.82"
      />
      <path
        fill="#E19D61"
        d="m173.608 60.722.168-.165 13.309-9.906c1.347 0 3.874-.166 2.863 9.08-.673 5.779-9.77 12.053-15.666 13.373 2.021-3.467 1.01-7.924-.674-12.382 0 .165 0 0 0 0"
      />
      <path
        fill="#263238"
        d="M157.604 21.757c-4.717 7.264-1.516 18.491 2.19 22.124 6.738-12.218 5.222-25.591-2.19-22.124"
      />
      <path
        fill="#FFB573"
        d="M195.507 29.186c.337 13.704 1.348 21.629-5.054 29.058-9.602 11.227-27.29 6.44-31.839-6.934-4.043-11.887-4.043-32.195 9.097-38.634 12.972-6.439 27.459 2.807 27.796 16.51"
      />
      <path
        fill="#263238"
        d="M192.138 39.918c-6.907-.66-8.928-12.382-4.717-21.628-8.591-2.146-17.014 1.32-22.742 3.467-5.728 2.146-14.319 2.477-14.319-4.623 3.032 1.651 8.76-.99 9.434-3.797-6.233.826-6.907-8.75-3.369-10.401 0 4.953 16.677 1.816 25.437-1.156 16.677-5.779 26.28 3.632 21.226 12.218 12.971 6.273 5.054 18.49-5.222 28.067-2.527-1.486-5.728-2.147-5.728-2.147"
      />
      <path
        fill="#263238"
        d="M187.927 34.305c1.011 9.245-25.774 14.033-30.322 13.703-1.348 7.925-7.076 13.208-1.348 18.161 5.391 4.788 25.606 6.274 32.681-3.797 7.075-10.071 1.516-27.242 1.516-27.242z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M164.177 51.817s2.091.73 6.66-.147c3.563-.657 7.59-3.216 7.59-3.216s-2.013 4.459-5.886 5.994c-3.872 1.535-8.364-2.631-8.364-2.631"
        clipRule="evenodd"
      />
      <path
        fill="#263238"
        d="M198.877 13.668c6.401.99 8.423-5.118 6.064-9.906 8.928 3.962 1.853 14.529-6.064 9.906"
      />
      <path
        fill="#FFB573"
        d="M201.909 38.763c-.842 3.302-3.032 5.779-5.559 7.264-3.706 2.147-6.57-.825-6.57-4.788.169-3.467 2.022-8.915 6.233-9.41 4.212-.496 6.907 3.137 5.896 6.934"
      />
      <path
        fill="#B2C4CB"
        d="m197.024 75.416 6.739 1.816c0 1.651-1.853 4.788-6.57 8.916-4.38 3.797-13.982 10.236-18.194 10.236.506-4.953-1.011-12.052-3.369-14.529 5.391.33 16.004-2.807 21.394-6.439M175.461 81.854c-4.717 1.816-9.939 8.75-11.455 13.374-2.527-1.321-5.223-10.732-3.706-16.676 2.021-1.32 4.885-1.65 7.58-1.32.337 1.98 1.853 4.292 7.581 4.622"
      />
      <path
        fill="#D4DEE2"
        d="M204.258 76.943c-16.846 22.124-20.879 149.706-11.446 260.984 24.764 4.127 49.696-30.379 49.696-30.379s-16.341-111.939-12.129-147.766c4.211-35.827 9.097-56.629 7.917-67.361-1.179-10.401-21.909-13.662-34.038-15.478M161.254 77.697c-11.118 38.469-26.223 173.056-21.169 253.79-9.434-7.594-20.047-24.93-19.879-31.204.169-5.944 4.212-111.113 5.896-126.798.843-25.591.169-64.224 3.37-79.249 3.2-14.859 20.327-17.53 31.782-16.539"
      />
      <path
        fill="#2A4856"
        d="m153.811 199.664 41.483-1.253c4.168-.117 6.301-3.17 4.736-6.77l-26.162-60.289c-1.566-3.6-6.262-6.438-10.43-6.32l-41.484 1.252c-4.168.118-6.301 3.17-4.735 6.771l26.162 60.288c1.565 3.601 6.262 6.438 10.43 6.321"
      />
      <path
        fill="#fff"
        d="M153.331 199.927c-4.286 0-9.001-2.915-10.606-6.614l-26.162-60.288c-.783-1.82-.724-3.601.156-4.99.959-1.507 2.779-2.387 5.029-2.466l41.484-1.252c4.364-.137 9.256 2.837 10.88 6.614l26.162 60.289c.783 1.819.724 3.6-.156 4.99-.959 1.506-2.779 2.387-5.029 2.465l-41.484 1.252zm.254-.978v.489zl41.484-1.252c1.918-.059 3.444-.783 4.227-2.016.704-1.115.743-2.563.078-4.09l-26.162-60.288c-1.487-3.444-5.929-6.184-9.96-6.027l-41.484 1.252c-1.918.059-3.444.783-4.227 2.016-.704 1.115-.743 2.563-.078 4.089l26.162 60.289c1.487 3.444 5.929 6.144 9.96 6.027"
      />
      <path
        fill="#FFB573"
        d="M182.199 172.33c.336-.661.505-1.486.336-2.147l-1.179-6.273c-.168-1.156-1.179-2.147-2.358-2.477l-5.391-1.321c-5.222-1.321-10.781-1.486-16.004-.66l-1.684.33c-3.032.495-5.054 3.467-4.38 6.439l1.348 6.439c.505 2.642 2.863 4.458 5.559 4.293l7.917-.331c-.168 0 12.466 1.651 15.836-4.292"
      />
      <path
        fill="#E0E0E0"
        d="M181.187 159.452s-4.548-1.651-7.58-1.651c-2.527 3.467 1.179 16.675 3.874 19.482 2.19 0 8.086-2.972 8.086-2.972-1.853-4.293-3.2-11.557-4.38-14.859"
      />
      <path
        fill="#D4DEE2"
        d="M209.49 101.501c1.853 4.788 3.706 9.741 5.559 14.529a512.096 512.096 0 0 1 5.222 14.694c1.685 4.953 3.201 9.741 4.548 14.529.674 2.312 1.348 4.788 1.685 6.769.168.991.337 1.982.505 2.807V155.655c0-.165 0-.331.169-.496l.337-1.32c.168-.331.337-.661.505-.826.169-.165.169 0 0 .165-.505.495-1.853 1.156-3.538 1.816-3.537 1.156-8.254 1.981-13.139 2.312-4.886.33-10.108.495-15.33.33-2.696-.165-5.222-.165-7.918-.495l-3.874-.331-3.706-.33-3.875 23.445c1.685.33 3.032.495 4.548.66l4.38.66c2.864.331 5.896.661 8.76.991 5.896.495 11.961.825 18.194.66 6.233-.165 12.634-.66 19.878-2.641 3.538-.991 7.412-2.477 11.624-5.118 2.021-1.321 4.211-3.137 6.064-5.449 1.853-2.311 3.369-5.118 4.38-8.09v-.165c.169-.33.169-.825.337-1.156.169-.825.337-1.651.337-2.641V152.353c-.168-1.816-.337-3.468-.674-5.119-.505-3.136-1.011-5.943-1.684-8.75-1.18-5.613-2.696-10.897-4.212-16.345-1.516-5.283-3.032-10.566-4.717-15.685-1.684-5.283-4.549-11.78-6.402-17.062z"
      />
      <path
        fill="url(#doctor-with-clipboard_svg__b)"
        d="M209.49 101.501c1.853 4.788 3.706 9.741 5.559 14.529a512.096 512.096 0 0 1 5.222 14.694c1.685 4.953 3.201 9.741 4.548 14.529.674 2.312 1.348 4.788 1.685 6.769.168.991.337 1.982.505 2.807V155.655c0-.165 0-.331.169-.496l.337-1.32c.168-.331.337-.661.505-.826.169-.165.169 0 0 .165-.505.495-1.853 1.156-3.538 1.816-3.537 1.156-8.254 1.981-13.139 2.312-4.886.33-10.108.495-15.33.33-2.696-.165-5.222-.165-7.918-.495l-3.874-.331-3.706-.33-3.875 23.445c1.685.33 3.032.495 4.548.66l4.38.66c2.864.331 5.896.661 8.76.991 5.896.495 11.961.825 18.194.66 6.233-.165 12.634-.66 19.878-2.641 3.538-.991 7.412-2.477 11.624-5.118 2.021-1.321 4.211-3.137 6.064-5.449 1.853-2.311 3.369-5.118 4.38-8.09v-.165c.169-.33.169-.825.337-1.156.169-.825.337-1.651.337-2.641V152.353c-.168-1.816-.337-3.468-.674-5.119-.505-3.136-1.011-5.943-1.684-8.75-1.18-5.613-2.696-10.897-4.212-16.345-1.516-5.283-3.032-10.566-4.717-15.685-1.684-5.283-4.549-11.78-6.402-17.062z"
        opacity={0.7}
      />
      <path
        fill="#263238"
        d="M175 33.316c.049 1.312-.569 2.447-1.399 2.53-.83.083-1.538-.896-1.587-2.207-.048-1.312.57-2.447 1.399-2.53.83-.082 1.539.896 1.587 2.207M160.025 35.26c.048 1.311-.57 2.446-1.4 2.53-.829.082-1.538-.896-1.587-2.208-.048-1.311.57-2.447 1.4-2.53.83-.082 1.538.896 1.587 2.208"
      />
      <path
        fill="#ED893E"
        d="M164.626 36.924s-1.928 5.866-4.176 8.852c1.741 1.421 4.541.376 4.541.376z"
      />
      <path
        fill="#263238"
        d="M178.383 25.034a.719.719 0 0 1-.568-.124c-2.585-1.988-5.198-.977-5.232-.971a.7.7 0 0 1-.944-.442.831.831 0 0 1 .483-1.005c.13-.059 3.416-1.334 6.622 1.155.325.239.362.725.109 1.071a.922.922 0 0 1-.487.319zM154.312 28.535a.614.614 0 0 1-.553-.039c-.368-.178-.49-.649-.297-1.037 1.996-3.872 5.42-4.398 5.559-4.406a.695.695 0 0 1 .792.664c.024.418-.273.807-.685.866-.136.025-2.785.46-4.365 3.534a.867.867 0 0 1-.454.401z"
      />
      <path fill="url(#doctor-with-clipboard_svg__c)" d="M0 19h328v268H0z" />
      <defs>
        <linearGradient
          id="doctor-with-clipboard_svg__a"
          x1={66.903}
          x2={138.599}
          y1={44.379}
          y2={276.061}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3BD" />
          <stop offset={0.626} stopColor="#FFF4E5" />
        </linearGradient>
        <linearGradient
          id="doctor-with-clipboard_svg__b"
          x1={215.574}
          x2={215.574}
          y1={95.427}
          y2={148.992}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4DEE2" stopOpacity={0} />
          <stop offset={1} stopColor="#B2C4CB" />
        </linearGradient>
        <linearGradient
          id="doctor-with-clipboard_svg__c"
          x1={164}
          x2={164}
          y1={188.004}
          y2={287}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgDoctorWithClipboard;
