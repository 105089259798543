import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDolar = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 3.9a8.1 8.1 0 1 0 0 16.2 8.1 8.1 0 0 0 0-16.2M2.1 12c0-5.468 4.432-9.9 9.9-9.9s9.9 4.432 9.9 9.9-4.432 9.9-9.9 9.9-9.9-4.432-9.9-9.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12.986 7.1a2.9 2.9 0 0 1 2.594 1.45.9.9 0 0 1-1.56.9A1.1 1.1 0 0 0 13 8.9h-2a1.1 1.1 0 1 0 0 2.2h2a2.9 2.9 0 1 1 0 5.8h-1.986a2.9 2.9 0 0 1-2.594-1.45.9.9 0 1 1 1.56-.9 1.1 1.1 0 0 0 1.02.55h2a1.1 1.1 0 0 0 0-2.2h-2a2.9 2.9 0 1 1 0-5.8z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 5.1a.9.9 0 0 1 .9.9v2a.9.9 0 0 1-1.8 0V6a.9.9 0 0 1 .9-.9m0 10a.9.9 0 0 1 .9.9v2a.9.9 0 0 1-1.8 0v-2a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDolar;
