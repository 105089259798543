import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDinersClub = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#163D9E"
        fillRule="evenodd"
        d="M10.129 21.152h3.34c5.213 0 9.368-4.136 9.531-9.246v-.081c-.081-5.19-4.318-9.408-9.531-9.327H9.72c-4.969.162-8.879 4.38-8.716 9.408 0 5.028 4.073 9.165 9.124 9.246m-7.495-9.327c0-4.217 3.503-7.705 7.74-7.705 4.235 0 7.738 3.488 7.738 7.705 0 4.218-3.503 7.705-7.739 7.705s-7.739-3.487-7.739-7.705m8.961 5.19v-10.3c2.851.73 4.644 3.57 3.91 6.408-.488 1.946-1.955 3.406-3.91 3.893m-6.273-6.488c-.733 2.839.978 5.759 3.83 6.489v-10.3a5.345 5.345 0 0 0-3.83 3.811"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDinersClub;
