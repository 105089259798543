import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgRefresh = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4 3a1 1 0 0 1 1 1v2.343A8.982 8.982 0 0 1 12 3c4.59 0 8.377 3.436 8.93 7.876a1 1 0 1 1-1.984.248A7.001 7.001 0 0 0 6.254 8H9a1 1 0 0 1 0 2H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1m-.062 9.008a1 1 0 0 1 1.116.868A7.001 7.001 0 0 0 17.746 16H15a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-2.343A8.982 8.982 0 0 1 12 21c-4.59 0-8.377-3.436-8.93-7.876a1 1 0 0 1 .868-1.116"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgRefresh;
