import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDiamond = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M7.552 3.104H16.483a1.9 1.9 0 0 1 1.563.828v.001l3.537 5.194a1.906 1.906 0 0 1-.164 2.356l-8 8.79a1.907 1.907 0 0 1-2.823 0l-8-8.79a.9.9 0 0 1-.122-.17 1.908 1.908 0 0 1-.027-2.188l3.54-5.192a1.903 1.903 0 0 1 1.564-.829m-3.52 7.284 7.896 8.673a.108.108 0 0 0 .16 0l8-8.79a.106.106 0 0 0 .009-.13L16.56 4.948a.1.1 0 0 0-.082-.044H7.556a.1.1 0 0 0-.082.043l-3.54 5.192a.108.108 0 0 0 .01.133.903.903 0 0 1 .089.116"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDiamond;
