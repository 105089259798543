import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgWhatsapp = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M17.264 5.854A8.1 8.1 0 0 0 5.386 16.682a.9.9 0 0 1 .09.877l-.982 2.26 3.398-.605a.9.9 0 0 1 .554.078 8.1 8.1 0 0 0 8.818-13.438M2.896 21.894c.093.011.185.007.274-.01l4.756-.848a9.9 9.9 0 1 0-4.297-3.745l-1.45 3.34a.897.897 0 0 0 .115.927.902.902 0 0 0 .602.336"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M8.51 8.01A1.4 1.4 0 0 1 10.9 9v1a1.4 1.4 0 0 1-.8 1.265 4.099 4.099 0 0 0 2.635 2.635A1.397 1.397 0 0 1 14 13.1h1a1.4 1.4 0 1 1 0 2.8h-1A5.9 5.9 0 0 1 8.1 10V9a1.4 1.4 0 0 1 .41-.99"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgWhatsapp;
