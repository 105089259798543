import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgHelpCircle = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11.9 21.8a9.9 9.9 0 1 1 9.9-9.9 9.909 9.909 0 0 1-9.9 9.9m0-18.15a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5" />
      <path d="M11.827 13.633a.83.83 0 1 1-.257-1.641c.56-.193 2.154-.917 2.154-1.944a1.89 1.89 0 0 0-1.576-1.833 1.833 1.833 0 0 0-1.284.23 1.906 1.906 0 0 0-.825 1.008.827.827 0 0 1-1.558-.55 3.52 3.52 0 0 1 1.549-1.88 3.603 3.603 0 0 1 2.402-.44 3.548 3.548 0 0 1 2.942 3.502c0 2.383-3.153 3.456-3.282 3.502a.678.678 0 0 1-.265.046M11.91 17.263a.825.825 0 1 1 0-1.65.825.825 0 0 1 0 1.65" />
    </svg>
  );
};
export default SvgHelpCircle;
