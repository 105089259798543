import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPix = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#2DD4BF"
        d="M18.157 17.83a3.212 3.212 0 0 1-2.285-.946l-3.3-3.3a.627.627 0 0 0-.867 0l-3.312 3.312c-.61.61-1.422.946-2.285.946h-.65l4.179 4.18a3.342 3.342 0 0 0 4.726 0l4.192-4.192zM6.108 6.158c.863 0 1.675.336 2.285.946l3.312 3.312c.238.24.628.24.867 0l3.3-3.3a3.212 3.212 0 0 1 2.285-.946h.397l-4.19-4.191a3.342 3.342 0 0 0-4.727 0l-4.18 4.179z"
      />
      <path
        fill="#2DD4BF"
        d="m22.021 9.637-2.533-2.533a.482.482 0 0 1-.18.036h-1.151c-.595 0-1.178.242-1.599.663l-3.3 3.3a1.58 1.58 0 0 1-1.12.463 1.58 1.58 0 0 1-1.12-.463L7.708 7.79a2.275 2.275 0 0 0-1.6-.663H4.693a.48.48 0 0 1-.17-.034L1.979 9.637a3.342 3.342 0 0 0 0 4.726l2.543 2.543a.483.483 0 0 1 .17-.034h1.416c.595 0 1.178-.242 1.599-.663l3.311-3.311c.599-.598 1.643-.599 2.24 0l3.3 3.3c.42.42 1.004.662 1.599.662h1.151c.064 0 .125.014.18.036l2.533-2.533a3.342 3.342 0 0 0 0-4.726"
      />
    </svg>
  );
};
export default SvgPix;
