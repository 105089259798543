import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCashDuplicate = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9 9.9A1.1 1.1 0 0 0 7.9 11v6A1.1 1.1 0 0 0 9 18.1h10a1.1 1.1 0 0 0 1.1-1.1v-6A1.1 1.1 0 0 0 19 9.9zM6.1 11A2.9 2.9 0 0 1 9 8.1h10a2.9 2.9 0 0 1 2.9 2.9v6a2.9 2.9 0 0 1-2.9 2.9H9A2.9 2.9 0 0 1 6.1 17z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M14 12.9a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2M11.1 14a2.9 2.9 0 1 1 5.8 0 2.9 2.9 0 0 1-5.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M2.95 4.95A2.9 2.9 0 0 1 5 4.1h10A2.9 2.9 0 0 1 17.9 7v2a.9.9 0 0 1-1.8 0V7A1.1 1.1 0 0 0 15 5.9H5A1.1 1.1 0 0 0 3.9 7v6A1.1 1.1 0 0 0 5 14.1h2a.9.9 0 1 1 0 1.8H5A2.9 2.9 0 0 1 2.1 13V7a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCashDuplicate;
