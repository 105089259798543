import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBookOpen = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M7.5 18.694a8.1 8.1 0 0 0-4.05 1.085.9.9 0 1 1-.9-1.558 9.9 9.9 0 0 1 9.45-.245 9.9 9.9 0 0 1 9.45.245.9.9 0 1 1-.9 1.558 8.1 8.1 0 0 0-8.1 0 .9.9 0 0 1-.9 0 8.1 8.1 0 0 0-4.05-1.085M7.5 5.694A8.1 8.1 0 0 0 3.45 6.78a.9.9 0 1 1-.9-1.558A9.9 9.9 0 0 1 12 4.976a9.9 9.9 0 0 1 9.45.245.9.9 0 1 1-.9 1.558 8.1 8.1 0 0 0-8.1 0 .9.9 0 0 1-.9 0A8.1 8.1 0 0 0 7.5 5.694"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3 5.1a.9.9 0 0 1 .9.9v13a.9.9 0 1 1-1.8 0V6a.9.9 0 0 1 .9-.9M12 5.1a.9.9 0 0 1 .9.9v13a.9.9 0 0 1-1.8 0V6a.9.9 0 0 1 .9-.9M21 5.1a.9.9 0 0 1 .9.9v13a.9.9 0 0 1-1.8 0V6a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBookOpen;
