import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMail = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2 7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7.984zm2 2.869V17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9.869l-6.336 4.224a3 3 0 0 1-3.328 0zm16-2.404-7.445 4.963a1 1 0 0 1-1.11 0L4 7.465V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMail;
