import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMessages = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11 3.9a.1.1 0 0 0-.1.1v6a.1.1 0 0 0 .1.1h7a.9.9 0 0 1 .636.264l1.464 1.463V4a.1.1 0 0 0-.1-.1zM9.656 2.657A1.9 1.9 0 0 1 11 2.1h9A1.9 1.9 0 0 1 21.9 4v10a.9.9 0 0 1-1.536.636L17.627 11.9H11A1.9 1.9 0 0 1 9.1 10V4c0-.504.2-.987.556-1.344"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4 10.9a.1.1 0 0 0-.1.1v7.827l1.464-1.463A.9.9 0 0 1 6 17.1h7a.1.1 0 0 0 .1-.1v-2a.9.9 0 1 1 1.8 0v2a1.9 1.9 0 0 1-1.9 1.9H6.373l-2.737 2.736A.9.9 0 0 1 2.1 21V11A1.9 1.9 0 0 1 4 9.1h2a.9.9 0 0 1 0 1.8z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMessages;
