import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgReal = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M18 3.1a.9.9 0 0 1 .9.9v2.5a.9.9 0 0 1-1.8 0V4a.9.9 0 0 1 .9-.9m0 12.5a.9.9 0 0 1 .9.9V19a.9.9 0 0 1-1.8 0v-2.5a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M19.236 5.6a3.4 3.4 0 0 1 3.044 1.7.9.9 0 0 1-1.56.9 1.6 1.6 0 0 0-1.47-.8h-2.5a1.6 1.6 0 1 0 0 3.2h2.5a3.4 3.4 0 1 1 0 6.8h-2.486a3.4 3.4 0 0 1-3.044-1.7.9.9 0 1 1 1.56-.9 1.6 1.6 0 0 0 1.47.8h2.5a1.6 1.6 0 0 0 0-3.2h-2.5a3.4 3.4 0 1 1 0-6.8zM2.1 6.5a.9.9 0 0 1 .9-.9h4.687a3.9 3.9 0 0 1 2.587.96C10.98 7.187 11.4 8.062 11.4 9v.12c0 .906-.406 1.75-1.087 2.355a3.757 3.757 0 0 1-2.491.925H3a.9.9 0 1 1 0-1.8h4.822c.503 0 .968-.179 1.296-.47.324-.288.482-.655.482-1.01V9c0-.388-.172-.785-.522-1.095a2.101 2.101 0 0 0-1.39-.505H3a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M2.1 11.5a.9.9 0 0 1 .9-.9h4.687a3.9 3.9 0 0 1 2.587.96c.706.627 1.126 1.502 1.126 2.44v2.5a.9.9 0 0 1-1.8 0V14c0-.388-.172-.785-.522-1.095a2.102 2.102 0 0 0-1.39-.505H3a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3 5.6a.9.9 0 0 1 .9.9v10a.9.9 0 1 1-1.8 0v-10a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgReal;
