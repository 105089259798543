import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgFire = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M10.656 3.169a.9.9 0 0 1 .98.195c.642.641 1.458 2.06 1.862 3.683.348 1.396.425 3.08-.292 4.64.107.023.18.003.244-.023.136-.054.358-.196.671-.546.311-.347.675-.855 1.106-1.578a.9.9 0 0 1 1.445-.139c.563.633 1.11 1.6 1.514 2.579.402.974.714 2.085.714 3.02a6.9 6.9 0 0 1-13.8 0c0-1.985.859-4.193 2.255-5.628l.102-.104C8.682 8.013 10.1 6.56 10.1 4a.9.9 0 0 1 .556-.831m.816 3.418c-.628 1.796-1.817 3.01-2.676 3.886l-.151.155L8 10l.645.628C7.589 11.713 6.9 13.464 6.9 15a5.1 5.1 0 1 0 10.2 0c0-.597-.216-1.456-.578-2.334-.154-.374-.326-.73-.502-1.046a8.51 8.51 0 0 1-.558.7c-.415.462-.855.82-1.344 1.016-1.106.441-2.021-.088-2.692-.643a.9.9 0 0 1-.172-1.197c.805-1.19.838-2.647.498-4.013a8.072 8.072 0 0 0-.28-.896"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgFire;
