import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDocumentDuplicate = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M15 2.1a.9.9 0 0 1 .9.9v4a.1.1 0 0 0 .1.1h4a.9.9 0 0 1 0 1.8h-4A1.9 1.9 0 0 1 14.1 7V3a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11 3.9A1.1 1.1 0 0 0 9.9 5v10a1.1 1.1 0 0 0 1.1 1.1h7a1.1 1.1 0 0 0 1.1-1.1V8.373L14.627 3.9zm-2.05-.95A2.9 2.9 0 0 1 11 2.1h4a.9.9 0 0 1 .636.264l5 5A.9.9 0 0 1 20.9 8v7a2.9 2.9 0 0 1-2.9 2.9h-7A2.9 2.9 0 0 1 8.1 15V5a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7 7.9A1.1 1.1 0 0 0 5.9 9v10A1.1 1.1 0 0 0 7 20.1h7a1.1 1.1 0 0 0 1.1-1.1v-2a.9.9 0 1 1 1.8 0v2a2.9 2.9 0 0 1-2.9 2.9H7A2.9 2.9 0 0 1 4.1 19V9A2.9 2.9 0 0 1 7 6.1h2a.9.9 0 0 1 0 1.8z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDocumentDuplicate;
