import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBell = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9.019 4.665a3 3 0 0 1 5.963 0A7 7 0 0 1 19 11v3.159c0 .273.109.535.302.729l1.405 1.405A1 1 0 0 1 20 18h-4a4 4 0 1 1-8 0H4a1 1 0 0 1-.707-1.707l1.405-1.405c.193-.194.302-.456.302-.73V11a7 7 0 0 1 4.018-6.335M10 18a2 2 0 0 0 4 0zm2-14a1 1 0 0 0-1 1v.341a1 1 0 0 1-.667.943A5.002 5.002 0 0 0 7 11v3.159c0 .669-.221 1.315-.623 1.841h11.246A3.032 3.032 0 0 1 17 14.159V11a5.002 5.002 0 0 0-3.333-4.716A1 1 0 0 1 13 5.341V5a1 1 0 0 0-1-1"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBell;
