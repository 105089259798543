import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgTag = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 22c-.8 0-1.6-.3-2.1-.9l-7-7c-.6-.5-.9-1.3-.9-2.1V7c0-2.8 2.2-5 5-5h5c.8 0 1.6.3 2.1.9l7 7c.6.6.9 1.3.9 2.1s-.3 1.6-.9 2.1l-7 7c-.5.6-1.3.9-2.1.9M7 4C5.3 4 4 5.3 4 7v5c0 .3.1.5.3.7l7 7c.4.4 1 .4 1.4 0l7-7c.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7l-7-7c-.2-.2-.4-.3-.7-.3zm0 4c-.5 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1" />
    </svg>
  );
};
export default SvgTag;
