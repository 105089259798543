import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgQuestionColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#FFF4E5" d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18" />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12 3.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17M2.5 12a9.5 9.5 0 1 1 19 0 9.5 9.5 0 0 1-19 0"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12 16.5a.5.5 0 0 1 .5.5v.01a.5.5 0 0 1-1 0V17a.5.5 0 0 1 .5-.5M10.697 6.793a3.1 3.1 0 1 1 2.469 5.679 1 1 0 0 0-.667 1 .5.5 0 1 1-.998.056 2 2 0 0 1 1.326-1.997 2.1 2.1 0 1 0-2.429-3.229.5.5 0 0 1-.796-.604 3.1 3.1 0 0 1 1.095-.905"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgQuestionColored;
