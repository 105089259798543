import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgReceiptMoney = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M7 3.9A1.1 1.1 0 0 0 5.9 5v14.318l1.6-1.067a.9.9 0 0 1 1.136.113L10 19.727l1.364-1.363a.9.9 0 0 1 1.272 0L14 19.727l1.364-1.363a.9.9 0 0 1 1.135-.113l1.601 1.067V5A1.1 1.1 0 0 0 17 3.9zm-2.05-.95A2.9 2.9 0 0 1 7 2.1h10A2.9 2.9 0 0 1 19.9 5v16a.9.9 0 0 1-1.4.749l-2.385-1.59-1.479 1.477a.9.9 0 0 1-1.272 0L12 20.273l-1.364 1.363a.9.9 0 0 1-1.272 0l-1.479-1.478-2.386 1.59A.9.9 0 0 1 4.1 21V5a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 5.6a.9.9 0 0 1 .9.9v.6H14a.9.9 0 0 1 0 1.8h-2.5a.6.6 0 1 0 0 1.2h1a2.4 2.4 0 0 1 .4 4.766v.634a.9.9 0 0 1-1.8 0v-.6H10a.9.9 0 1 1 0-1.8h2.5a.6.6 0 1 0 0-1.2h-1a2.4 2.4 0 0 1-.4-4.766V6.5a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgReceiptMoney;
