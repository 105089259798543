import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCornerDown = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5.1 6a.9.9 0 0 1 .9-.9h6A3.9 3.9 0 0 1 15.9 9v7.827l2.464-2.463a.9.9 0 1 1 1.272 1.272l-3.995 3.996a.898.898 0 0 1-1.277.004l-4-4a.9.9 0 1 1 1.272-1.272l2.464 2.463V9A2.1 2.1 0 0 0 12 6.9H6a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCornerDown;
