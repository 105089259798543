import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgElo = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#FFB11D"
        d="M10.008 6.456a6.145 6.145 0 0 1 1.917-.304c2.927 0 5.369 2.04 5.929 4.749L22 10.07C21.05 5.466 16.901 2 11.925 2c-1.139 0-2.235.182-3.259.517z"
      />
      <path
        fill="#163D9E"
        d="m5.072 19.801 2.483-3.206c-1.108-1.122-1.808-2.759-1.808-4.583 0-1.823.699-3.46 1.807-4.58L5.07 4.224C3.186 6.13 2 8.913 2 12.012c0 3.1 1.189 5.884 3.073 7.79"
      />
      <path
        fill="#EF4444"
        d="M17.855 13.126c-.562 2.708-3.002 4.745-5.926 4.745-.671 0-1.317-.107-1.92-.305l-1.343 3.94a10.43 10.43 0 0 0 3.263.52c4.97 0 9.117-3.464 10.072-8.067z"
      />
    </svg>
  );
};
export default SvgElo;
