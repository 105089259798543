import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCode = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M7.636 7.364a.9.9 0 0 1 0 1.272L4.273 12l3.363 3.364a.9.9 0 0 1-1.272 1.272l-4-4a.9.9 0 0 1 0-1.272l4-4a.9.9 0 0 1 1.272 0M16.364 7.364a.9.9 0 0 1 1.272 0l4 4a.9.9 0 0 1 0 1.272l-4 4a.9.9 0 1 1-1.272-1.272L19.727 12l-3.363-3.364a.9.9 0 0 1 0-1.272M14.218 3.127a.9.9 0 0 1 .655 1.091l-4 16a.9.9 0 1 1-1.746-.436l4-16a.9.9 0 0 1 1.091-.655"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCode;
