import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgVaccine = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M16.364 2.364a.9.9 0 0 1 1.272 0l4 4a.9.9 0 0 1-1.272 1.272l-4-4a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M19.636 4.364a.9.9 0 0 1 0 1.272l-4.5 4.5a.9.9 0 1 1-1.272-1.272l4.5-4.5a.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10.864 5.864a.9.9 0 0 1 1.272 0l6 6a.9.9 0 0 1-1.272 1.272l-6-6a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M13.136 6.864a.9.9 0 0 1 0 1.272L6.9 14.373V17.1h2.727l6.237-6.236a.9.9 0 1 1 1.272 1.272l-6.5 6.5A.9.9 0 0 1 10 18.9H6a.9.9 0 0 1-.9-.9v-4a.9.9 0 0 1 .264-.636l6.5-6.5a.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M6.864 11.864a.9.9 0 0 1 1.272 0l1.5 1.5a.9.9 0 0 1-1.272 1.272l-1.5-1.5a.9.9 0 0 1 0-1.272M9.864 8.864a.9.9 0 0 1 1.272 0l1.5 1.5a.9.9 0 0 1-1.272 1.272l-1.5-1.5a.9.9 0 0 1 0-1.272M6.636 17.364a.9.9 0 0 1 0 1.272l-3 3a.9.9 0 1 1-1.272-1.272l3-3a.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgVaccine;
