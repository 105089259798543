import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPatientsFill = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={9.852} cy={8.297} r={5.185} fill="#00658A" />
      <circle cx={20.222} cy={11.408} r={4.148} fill="#00658A" />
      <path
        fill="#00658A"
        d="M1.037 23.396c0-2.664 0-3.997.403-5.04a6 6 0 0 1 3.436-3.436c1.043-.402 2.354-.402 4.976-.402s3.933 0 4.976.402a6 6 0 0 1 3.436 3.437c.403 1.043.403 2.375.403 5.039 0 .456 0 .683-.07.861a1 1 0 0 1-.561.56c-.178.07-.402.07-.85.07H2.518c-.449 0-.673 0-.851-.07a1 1 0 0 1-.56-.56c-.07-.178-.07-.405-.07-.861M19.704 17.15c0-.308.25-.558.558-.558 3.7 0 6.7 3 6.7 6.7v.958a.638.638 0 0 1-.637.638h-4.621c-.943 0-1.415 0-1.707-.293-.293-.293-.293-.764-.293-1.707z"
      />
    </svg>
  );
};
export default SvgPatientsFill;
