import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgSearch = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.5 3.9a7.6 7.6 0 1 0 0 15.2 7.6 7.6 0 0 0 0-15.2m-9.4 7.6a9.4 9.4 0 1 1 18.8 0 9.4 9.4 0 0 1-18.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M17.363 17.363a.9.9 0 0 1 1.273 0l3 3a.9.9 0 1 1-1.273 1.273l-3-3a.9.9 0 0 1 0-1.273"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgSearch;
