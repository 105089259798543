import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBluetooth = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.61 3.189a.9.9 0 0 1 .952.108l5 4a.9.9 0 0 1 0 1.406L13.441 12l4.121 3.297a.9.9 0 0 1 0 1.406l-5 4A.9.9 0 0 1 11.1 20v-6.127l-3.538 2.83a.9.9 0 1 1-1.124-1.406L10.559 12 6.438 8.703a.9.9 0 0 1 1.124-1.406l3.538 2.83V4a.9.9 0 0 1 .51-.811m1.29 10.684L15.56 16l-2.66 2.127zm0-3.746V5.873L15.56 8z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBluetooth;
