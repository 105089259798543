import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgFilter = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5.202 4.15A.9.9 0 0 1 5.5 4.1h13a.9.9 0 0 1 .298.05 1.9 1.9 0 0 1 .95 2.85.885.885 0 0 1-.082.105L14.9 12.348V19a.9.9 0 0 1-1.44.72l-4-3A.9.9 0 0 1 9.1 16v-3.652L4.334 7.105a.899.899 0 0 1-.082-.104 1.9 1.9 0 0 1 .95-2.85m.532 1.82a.1.1 0 0 1 .007-.07h12.518a.102.102 0 0 1 .006.07l-4.931 5.425A.9.9 0 0 0 13.1 12v5.2l-2.2-1.65V12a.9.9 0 0 0-.234-.605z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgFilter;
