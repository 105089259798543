import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgRegister = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M6 4.9A2.1 2.1 0 0 0 3.9 7v10c0 1.16.94 2.1 2.1 2.1h12a2.1 2.1 0 0 0 2.1-2.1V7A2.1 2.1 0 0 0 18 4.9zM2.1 7A3.9 3.9 0 0 1 6 3.1h12A3.9 3.9 0 0 1 21.9 7v10a3.9 3.9 0 0 1-3.9 3.9H6A3.9 3.9 0 0 1 2.1 17z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9 8.9a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2M6.1 10a2.9 2.9 0 1 1 5.8 0 2.9 2.9 0 0 1-5.8 0M14.1 8a.9.9 0 0 1 .9-.9h2a.9.9 0 0 1 0 1.8h-2a.9.9 0 0 1-.9-.9M14.1 12a.9.9 0 0 1 .9-.9h2a.9.9 0 0 1 0 1.8h-2a.9.9 0 0 1-.9-.9M6.1 16a.9.9 0 0 1 .9-.9h10a.9.9 0 0 1 0 1.8H7a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgRegister;
