import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLoveFill = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M21.936 8.574a5.482 5.482 0 0 0-5.482-5.482c-1.837 0-3.459.907-4.454 2.294a5.472 5.472 0 0 0-4.454-2.294 5.482 5.482 0 0 0-5.482 5.482c0 .429.054.844.148 1.245.76 4.73 6.02 9.719 9.788 11.089 3.768-1.37 9.027-6.36 9.787-11.089.095-.4.15-.816.15-1.245" />
    </svg>
  );
};
export default SvgLoveFill;
