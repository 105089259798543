import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCalculator = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M6 3.9A1.1 1.1 0 0 0 4.9 5v14A1.1 1.1 0 0 0 6 20.1h12a1.1 1.1 0 0 0 1.1-1.1V5A1.1 1.1 0 0 0 18 3.9zM3.1 5A2.9 2.9 0 0 1 6 2.1h12A2.9 2.9 0 0 1 20.9 5v14a2.9 2.9 0 0 1-2.9 2.9H6A2.9 2.9 0 0 1 3.1 19z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9 7.9a.1.1 0 0 0-.1.1v1a.1.1 0 0 0 .1.1h6a.1.1 0 0 0 .1-.1V8a.1.1 0 0 0-.1-.1zM7.1 8c0-1.05.85-1.9 1.9-1.9h6c1.05 0 1.9.85 1.9 1.9v1a1.9 1.9 0 0 1-1.9 1.9H9A1.9 1.9 0 0 1 7.1 9zM8 13.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V14a.9.9 0 0 1 .9-.9M12 13.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V14a.9.9 0 0 1 .9-.9M16 13.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V14a.9.9 0 0 1 .9-.9M8 16.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V17a.9.9 0 0 1 .9-.9M12 16.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V17a.9.9 0 0 1 .9-.9M16 16.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V17a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCalculator;
