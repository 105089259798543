import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCalendarFill = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4.117 8.167A3.383 3.383 0 0 1 7.5 4.783h14a3.383 3.383 0 0 1 3.383 3.384v14A3.383 3.383 0 0 1 21.5 25.55h-14a3.383 3.383 0 0 1-3.383-3.383z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M19.05 2.45c.58 0 1.05.47 1.05 1.05v4.667a1.05 1.05 0 1 1-2.1 0V3.5c0-.58.47-1.05 1.05-1.05M9.833 2.45c.58 0 1.05.47 1.05 1.05v4.667a1.05 1.05 0 1 1-2.1 0V3.5c0-.58.47-1.05 1.05-1.05"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.783 12.833c0-.58.47-1.05 1.05-1.05h9.334a1.05 1.05 0 1 1 0 2.1H9.833c-.58 0-1.05-.47-1.05-1.05"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCalendarFill;
