import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgWhatsappColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#FFF4E5" d="m3 21 1.65-3.8a9 9 0 1 1 3.4 2.9z" />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M17.524 5.55A8.5 8.5 0 0 0 5.059 16.912a.5.5 0 0 1 .05.487l-1.28 2.945 4.133-.736a.5.5 0 0 1 .308.043A8.5 8.5 0 0 0 17.524 5.55M11.85 2.518A9.5 9.5 0 1 1 7.98 20.621l-4.891.871a.5.5 0 0 1-.547-.691l1.54-3.547a9.5 9.5 0 0 1 7.77-14.736"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M8.793 8.293A1 1 0 0 1 10.5 9v1a1 1 0 0 1-.889.994 4.5 4.5 0 0 0 3.395 3.395A1 1 0 0 1 14 13.5h1a1 1 0 0 1 0 2h-1A5.5 5.5 0 0 1 8.5 10V9a1 1 0 0 1 .293-.707"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgWhatsappColored;
