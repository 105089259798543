import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgVisa = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 25 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#133385"
        fillRule="evenodd"
        d="M6.023 15.58H3.946L2.39 9.638c-.074-.273-.231-.515-.462-.629A6.761 6.761 0 0 0 .023 8.38v-.229h3.346c.462 0 .808.344.866.743l.808 4.286 2.076-5.029h2.02zm4.27 0H8.33L9.946 8.15h1.962zm4.153-5.37c.058-.4.404-.63.808-.63a3.655 3.655 0 0 1 1.904.343l.346-1.6a4.965 4.965 0 0 0-1.788-.343c-1.904 0-3.29 1.029-3.29 2.457 0 1.086.982 1.657 1.675 2 .749.343 1.038.572.98.915 0 .514-.577.742-1.154.742a4.946 4.946 0 0 1-2.019-.457l-.346 1.6c.692.286 1.442.4 2.135.4 2.134.057 3.46-.971 3.46-2.514 0-1.943-2.711-2.057-2.711-2.914m9.577 5.37L22.465 8.15h-1.673a.868.868 0 0 0-.808.572L17.1 15.58h2.02l.402-1.085h2.482l.23 1.085zm-2.942-5.428.576 2.8h-1.615z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgVisa;
