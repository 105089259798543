import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDislikeFill = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#00658A"
        fillRule="evenodd"
        d="M16.967 3.1c.727-.048 1.39.26 1.892.763.504.504.844 1.193 1.017 1.932a.72.72 0 0 1 .006.029l1 5A.901.901 0 0 1 20.9 11a2.9 2.9 0 0 1-2.9 2.9h-2.1V18a2.9 2.9 0 0 1-5.8 0v-1A3.1 3.1 0 0 0 7 13.9H4A1.9 1.9 0 0 1 2.1 12V5A1.9 1.9 0 0 1 4 3.1h2a1.9 1.9 0 0 1 1.579.843A3.9 3.9 0 0 1 10 3.1z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDislikeFill;
