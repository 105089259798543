import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgClick = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.1 12a.9.9 0 0 1 .9-.9h3a.9.9 0 1 1 0 1.8H3a.9.9 0 0 1-.9-.9M12 2.1a.9.9 0 0 1 .9.9v3a.9.9 0 0 1-1.8 0V3a.9.9 0 0 1 .9-.9M4.964 4.964a.9.9 0 0 1 1.272 0l2.2 2.2a.9.9 0 0 1-1.272 1.272l-2.2-2.2a.9.9 0 0 1 0-1.272M19.036 4.964a.9.9 0 0 1 0 1.272l-2.2 2.2a.9.9 0 0 1-1.272-1.272l2.2-2.2a.9.9 0 0 1 1.272 0M8.436 15.564a.9.9 0 0 1 0 1.272l-2.2 2.2a.9.9 0 1 1-1.272-1.272l2.2-2.2a.9.9 0 0 1 1.272 0M11.364 11.364a.9.9 0 0 1 .92-.218l9 3a.9.9 0 0 1 .119 1.659l-3.732 1.866-1.866 3.732a.9.9 0 0 1-1.659-.118l-3-9a.9.9 0 0 1 .218-.921m2.059 2.059 1.744 5.231 1.028-2.056a.9.9 0 0 1 .402-.403l2.057-1.028z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgClick;
