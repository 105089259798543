import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPatients = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#00658A"
        fillRule="evenodd"
        d="M4.468 8.353a5.36 5.36 0 0 0 5.353 5.353 5.36 5.36 0 0 0 5.352-5.353c0-2.951-2.4-5.353-5.352-5.353a5.359 5.359 0 0 0-5.353 5.353m2.035 0A3.321 3.321 0 0 1 9.82 5.035a3.321 3.321 0 0 1 3.317 3.318 3.321 3.321 0 0 1-3.317 3.317 3.321 3.321 0 0 1-3.318-3.317M15.958 11.488a4.313 4.313 0 0 0 4.308 4.308 4.313 4.313 0 0 0 4.308-4.308 4.313 4.313 0 0 0-4.308-4.308 4.313 4.313 0 0 0-4.308 4.308m2.035-.001a2.276 2.276 0 0 1 2.273-2.273 2.276 2.276 0 0 1 2.273 2.273 2.276 2.276 0 0 1-2.273 2.273 2.276 2.276 0 0 1-2.273-2.273M25.15 24.179c0 .56.456 1.018 1.018 1.018h.002a1.02 1.02 0 0 0 1.018-1.018v-.156c0-.282 0-.522-.002-.732-.003-.494-.013-.84-.053-1.148a6.406 6.406 0 0 0-5.508-5.507c-.029-.005-.06-.008-.09-.011a9.007 9.007 0 0 0-.863-.041h-.013a1.048 1.048 0 0 0-1.044 1.052c0 .562.458 1.042 1.044 1.092 2.665.227 4.164 1.466 4.455 3.68.023.18.032.402.035.883zM16.794 24.177c0 .56.457 1.017 1.018 1.017v-.001c.561 0 1.018-.456 1.018-1.017v-.888c0-2.337-.015-3.502-.34-4.459a6.435 6.435 0 0 0-4.001-4.001c-1-.34-2.226-.34-4.668-.34-2.443 0-3.669 0-4.669.34a6.435 6.435 0 0 0-4 4.001c-.326.957-.34 2.122-.34 4.46v.887a1.018 1.018 0 0 0 2.034 0v-.888c.002-2.424.032-3.217.231-3.805a4.388 4.388 0 0 1 2.729-2.728c.597-.203 1.392-.232 4.014-.232 2.622 0 3.417.029 4.014.232a4.388 4.388 0 0 1 2.728 2.728c.2.588.23 1.382.232 3.805z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPatients;
