import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLight = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 2.1a.9.9 0 0 1 .9.9v1a.9.9 0 0 1-1.8 0V3a.9.9 0 0 1 .9-.9M4.964 4.964a.9.9 0 0 1 1.272 0l.7.7a.9.9 0 0 1-1.272 1.272l-.7-.7a.9.9 0 0 1 0-1.272m14.072 0a.9.9 0 0 1 0 1.272l-.7.7a.9.9 0 0 1-1.272-1.272l.7-.7a.9.9 0 0 1 1.272 0M2.1 12a.9.9 0 0 1 .9-.9h1a.9.9 0 1 1 0 1.8H3a.9.9 0 0 1-.9-.9m17 0a.9.9 0 0 1 .9-.9h1a.9.9 0 0 1 0 1.8h-1a.9.9 0 0 1-.9-.9M12 7.9a4.1 4.1 0 0 0-2.412 7.419 4.399 4.399 0 0 1 1.313 3.734 1.1 1.1 0 0 0 2.198 0 4.4 4.4 0 0 1 1.268-3.693.891.891 0 0 1 .093-.08A4.1 4.1 0 0 0 12 7.9m-3.595 8.778a5.9 5.9 0 1 1 7.19 0 2.6 2.6 0 0 0-.705 2.19c.007.044.01.088.01.132a2.9 2.9 0 0 1-5.8 0 .9.9 0 0 1 .01-.132 2.6 2.6 0 0 0-.705-2.19"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M8.8 17a.9.9 0 0 1 .9-.9h4.6a.9.9 0 0 1 0 1.8H9.7a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgLight;
