import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLike = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M13 4.9A1.1 1.1 0 0 0 11.9 6v1a4.9 4.9 0 0 1-4 4.817V17a2.1 2.1 0 0 0 2.1 2.1h7c.026 0 .05.001.076.003.122.01.3-.029.51-.238.215-.216.42-.575.535-1.057l.976-4.884A1.1 1.1 0 0 0 18 11.9h-3a.9.9 0 0 1-.9-.9V6A1.1 1.1 0 0 0 13 4.9M7.579 20.057A1.902 1.902 0 0 1 6 20.9H4A1.9 1.9 0 0 1 2.1 19v-7A1.9 1.9 0 0 1 4 10.1h3A3.1 3.1 0 0 0 10.1 7V6a2.9 2.9 0 1 1 5.8 0v4.1H18a2.9 2.9 0 0 1 2.9 2.9c0 .06-.006.118-.017.177l-1 5c-.002.004-.002.01-.003.014l-.004.014c-.173.739-.513 1.428-1.017 1.932-.502.503-1.165.811-1.892.763H10a3.9 3.9 0 0 1-2.421-.843M6.1 11.9H4a.1.1 0 0 0-.1.1v7a.1.1 0 0 0 .1.1h2a.1.1 0 0 0 .1-.1z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgLike;
