import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDashboard = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 11.9a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2M9.1 13a2.9 2.9 0 1 1 5.8 0 2.9 2.9 0 0 1-5.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M16.136 8.864a.9.9 0 0 1 0 1.272l-2.05 2.05a.9.9 0 1 1-1.272-1.272l2.05-2.05a.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 4.854A8.1 8.1 0 0 0 6.723 19.1h10.554A8.1 8.1 0 0 0 12 4.854m-5.732.029A9.9 9.9 0 0 1 18.16 20.705a.9.9 0 0 1-.56.195H6.4a.9.9 0 0 1-.56-.195 9.9 9.9 0 0 1 .428-15.822"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDashboard;
