import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCornerUp = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M8.94 4.102a.897.897 0 0 0-.576.262l-4 4a.9.9 0 0 0 1.272 1.272L8.1 7.173V15a3.9 3.9 0 0 0 3.9 3.9h6a.9.9 0 0 0 0-1.8h-6A2.1 2.1 0 0 1 9.9 15V7.173l2.464 2.463a.9.9 0 0 0 1.272-1.272L9.641 4.368a.903.903 0 0 0-.702-.266"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCornerUp;
