import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMoneyColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#FFF4E5" d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18" />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12 3.438a8.562 8.562 0 1 0 0 17.124 8.562 8.562 0 0 0 0-17.124M2.563 12a9.437 9.437 0 1 1 18.874 0 9.437 9.437 0 0 1-18.874 0"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12.993 7.562a2.438 2.438 0 0 1 2.186 1.22.437.437 0 0 1-.758.436A1.563 1.563 0 0 0 13 8.438h-2a1.563 1.563 0 0 0 0 3.124h2a2.438 2.438 0 1 1 0 4.876h-1.993a2.438 2.438 0 0 1-2.186-1.22.438.438 0 0 1 .758-.437 1.562 1.562 0 0 0 1.421.781h2a1.562 1.562 0 1 0 0-3.124h-2a2.437 2.437 0 1 1 0-4.876z"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12 5.563c.242 0 .438.195.438.437v2a.438.438 0 0 1-.876 0V6c0-.242.196-.437.438-.437m0 10c.242 0 .438.195.438.437v2a.438.438 0 0 1-.876 0v-2c0-.242.196-.437.438-.437"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMoneyColored;
