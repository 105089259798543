import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgWorld = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 3.9a8.1 8.1 0 1 0 0 16.2 8.1 8.1 0 0 0 0-16.2M2.1 12c0-5.468 4.432-9.9 9.9-9.9s9.9 4.432 9.9 9.9-4.432 9.9-9.9 9.9-9.9-4.432-9.9-9.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M2.7 9a.9.9 0 0 1 .9-.9h16.8a.9.9 0 1 1 0 1.8H3.6a.9.9 0 0 1-.9-.9M2.7 15a.9.9 0 0 1 .9-.9h16.8a.9.9 0 1 1 0 1.8H3.6a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11.977 2.236a.9.9 0 0 1 .287 1.24 16.1 16.1 0 0 0 0 17.048.9.9 0 1 1-1.527.953 17.9 17.9 0 0 1 0-18.953.9.9 0 0 1 1.24-.288"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12.024 2.236a.9.9 0 0 1 1.24.288 17.9 17.9 0 0 1 0 18.953.9.9 0 1 1-1.528-.954 16.1 16.1 0 0 0 0-17.047.9.9 0 0 1 .287-1.24"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgWorld;
