import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgInvite = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M8.535 3.535a4.9 4.9 0 1 1 6.93 6.93 4.9 4.9 0 0 1-6.93-6.93M12 3.9a3.1 3.1 0 1 0 0 6.2 3.1 3.1 0 0 0 0-6.2M9 15.9A3.1 3.1 0 0 0 5.9 19v2a.9.9 0 1 1-1.8 0v-2A4.9 4.9 0 0 1 9 14.1h4a.9.9 0 0 1 0 1.8zM13.148 16.564a2.383 2.383 0 1 1 3.37 3.37 2.383 2.383 0 0 1-3.37-3.37m1.685.902a.783.783 0 1 0 0 1.567.783.783 0 0 0 0-1.567M19.482 13.398a2.383 2.383 0 1 1 3.37 3.37 2.383 2.383 0 0 1-3.37-3.37m1.685.902a.783.783 0 1 0 0 1.567.783.783 0 0 0 0-1.567M19.482 19.731a2.383 2.383 0 1 1 3.37 3.37 2.383 2.383 0 0 1-3.37-3.37m1.685.902a.783.783 0 1 0 0 1.567.783.783 0 0 0 0-1.567"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M20.453 15.404a.8.8 0 0 1-.345 1.077l-3.483 1.794a.8.8 0 1 1-.733-1.422l3.483-1.794a.8.8 0 0 1 1.078.345"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M15.547 18.57a.8.8 0 0 1 1.078-.345l3.483 1.794a.8.8 0 1 1-.733 1.423l-3.483-1.795a.8.8 0 0 1-.345-1.077"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgInvite;
