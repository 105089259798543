import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgVolume = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M14.28 7.46a.9.9 0 0 1 1.26-.18 5.9 5.9 0 0 1 0 9.44.9.9 0 0 1-1.08-1.44 4.1 4.1 0 0 0 0-6.56.9.9 0 0 1-.18-1.26"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M17 4.434a.9.9 0 0 1 1.266-.134 9.9 9.9 0 0 1 0 15.4.9.9 0 0 1-1.132-1.4 8.1 8.1 0 0 0 0-12.6A.9.9 0 0 1 17 4.434M10.1 5.195 6.71 9.553A.9.9 0 0 1 6 9.9H4a.1.1 0 0 0-.1.1v4a.1.1 0 0 0 .1.1h2a.9.9 0 0 1 .71.348l3.39 4.357zm-.53-1.903a1.7 1.7 0 0 1 2.33 1.77v13.876a1.7 1.7 0 0 1-3.152 1.06L5.56 15.9H4A1.9 1.9 0 0 1 2.1 14v-4A1.9 1.9 0 0 1 4 8.1h1.56l3.188-4.099a1.7 1.7 0 0 1 .822-.71"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgVolume;
