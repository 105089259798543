import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgAura = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#163D9E"
        fillRule="evenodd"
        d="M.053 4h23.894v7.43C21.06 10.307 11.867 7.688 0 11.002V4z"
        clipRule="evenodd"
      />
      <path
        fill="#FFB11D"
        fillRule="evenodd"
        d="M.053 19.822h23.894v-5.987c-4.33-1.443-11.974-3.581-23.894-.534z"
        clipRule="evenodd"
      />
      <path
        fill="#EF4444"
        fillRule="evenodd"
        d="M23.947 13.835c-4.33-1.443-11.974-3.581-23.894-.534v-2.299c11.867-3.26 21.06-.641 23.947.428z"
        clipRule="evenodd"
      />
      <path
        fill="#FFB11D"
        fillRule="evenodd"
        d="M7.697 9.612c.588-1.657 1.925-3.046 4.276-3.046 2.352 0 3.902 1.336 4.33 3.1-2.993-.267-5.024-.32-8.606-.054"
        clipRule="evenodd"
      />
      <path
        fill="#163D9E"
        stroke="#163D9E"
        strokeMiterlimit={22.93}
        strokeWidth={0.22}
        d="m5.399 16.775 1.603-2.405h.588l1.71 2.405h-.64l-.482-.748H6.414l-.48.748zm1.229-.962h1.39l-.428-.642-.32-.48c-.054.16-.107.32-.214.48zm5.452.962v-.267c-.214.214-.534.267-.962.267-.16 0-.32 0-.481-.053-.107 0-.214-.054-.32-.16-.054-.054-.108-.108-.161-.215 0-.106-.054-.16-.054-.267v-1.069h.535v.962c0 .107 0 .214.053.321.054.107.107.16.214.16.107.054.267.054.374.054.16 0 .268 0 .428-.054.107-.053.214-.107.267-.16.054-.107.107-.214.107-.32v-.91h.535v1.711zm1.71 0v-1.71h.482v.267c.107-.107.213-.16.32-.267.107-.054.214-.054.321-.054.16 0 .374.054.535.107l-.16.267c-.108-.053-.268-.053-.375-.053-.107 0-.214 0-.32.053-.054.054-.161.107-.214.16-.054.108-.107.214-.107.321v.91zm4.384-.214c-.16.107-.375.16-.535.214-.16.053-.374.053-.588.053-.32 0-.588-.053-.748-.16-.16-.107-.267-.214-.267-.374 0-.107.053-.16.106-.214.054-.053.16-.107.268-.16.107-.054.267-.054.374-.107.107 0 .267-.054.428-.054.32 0 .587-.053.908-.106v-.054a.294.294 0 0 0-.16-.267 1.92 1.92 0 0 0-.588-.107c-.16 0-.374 0-.535.053a.645.645 0 0 0-.267.268l-.481-.054c.053-.107.107-.214.214-.32.16-.107.267-.16.427-.16.214-.054.482-.054.695-.054.214 0 .428 0 .642.053a.99.99 0 0 1 .374.107.737.737 0 0 1 .16.214c0 .107.054.16 0 .267v.374c0 .16 0 .321.054.535 0 .053.053.16.107.214h-.535s-.053-.054-.053-.16Zm-.054-.641c-.267.053-.534.107-.855.107-.16 0-.32.053-.428.053a.405.405 0 0 0-.213.107c-.054.054-.054.107-.054.16 0 .054.054.16.16.214.16.054.321.107.428.054.16 0 .374 0 .535-.054.106-.053.267-.107.32-.213.054-.107.107-.214.107-.268z"
      />
    </svg>
  );
};
export default SvgAura;
