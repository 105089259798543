import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBank = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#B4BEC6"
        fillRule="evenodd"
        d="M2.1 21a.9.9 0 0 1 .9-.9h18a.9.9 0 1 1 0 1.8H3a.9.9 0 0 1-.9-.9M2.1 10a.9.9 0 0 1 .9-.9h18a.9.9 0 1 1 0 1.8H3a.9.9 0 0 1-.9-.9M11.646 2.173a.9.9 0 0 1 .709 0l7 3a.9.9 0 1 1-.71 1.654L12 3.98 5.355 6.827a.9.9 0 0 1-.71-1.654z"
        clipRule="evenodd"
      />
      <path
        fill="#B4BEC6"
        fillRule="evenodd"
        d="M4 9.1a.9.9 0 0 1 .9.9v11a.9.9 0 0 1-1.8 0V10a.9.9 0 0 1 .9-.9M20 9.1a.9.9 0 0 1 .9.9v11a.9.9 0 0 1-1.8 0V10a.9.9 0 0 1 .9-.9M8 13.1a.9.9 0 0 1 .9.9v3a.9.9 0 0 1-1.8 0v-3a.9.9 0 0 1 .9-.9M12 13.1a.9.9 0 0 1 .9.9v3a.9.9 0 0 1-1.8 0v-3a.9.9 0 0 1 .9-.9M16 13.1a.9.9 0 0 1 .9.9v3a.9.9 0 0 1-1.8 0v-3a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBank;
