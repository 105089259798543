import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCart = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M6 17.9a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2M3.1 19a2.9 2.9 0 1 1 5.8 0 2.9 2.9 0 0 1-5.8 0M17 17.9a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2M14.1 19a2.9 2.9 0 1 1 5.8 0 2.9 2.9 0 0 1-5.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3.1 3a.9.9 0 0 1 .9-.9h2a.9.9 0 0 1 .9.9v13.1H17a.9.9 0 0 1 0 1.8H6a.9.9 0 0 1-.9-.9V3.9H4a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5.102 4.936a.9.9 0 0 1 .962-.834l14 1a.9.9 0 0 1 .827 1.025l-1 7A.9.9 0 0 1 19 13.9H6a.9.9 0 1 1 0-1.8h12.22l.753-5.271-13.037-.931a.9.9 0 0 1-.834-.962"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCart;
