import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDownload = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4 16.1a.9.9 0 0 1 .9.9v2A1.1 1.1 0 0 0 6 20.1h12a1.1 1.1 0 0 0 1.1-1.1v-2a.9.9 0 1 1 1.8 0v2a2.9 2.9 0 0 1-2.9 2.9H6A2.9 2.9 0 0 1 3.1 19v-2a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M6.364 10.364a.9.9 0 0 1 1.272 0L12 14.727l4.364-4.363a.9.9 0 1 1 1.272 1.272l-5 5a.9.9 0 0 1-1.272 0l-5-5a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 3.1a.9.9 0 0 1 .9.9v12a.9.9 0 1 1-1.8 0V4a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDownload;
