import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDiscover = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#34423D"
        d="M2.03 10.102h-1V13.6h1c.527 0 .909-.127 1.246-.4.4-.331.636-.827.636-1.343-.006-1.037-.776-1.755-1.883-1.755m.8 2.633c-.216.191-.49.28-.934.28H1.71V10.7h.185c.438 0 .706.076.935.28.235.21.375.534.375.871 0 .337-.14.674-.375.884M4.904 10.102h-.68V13.6h.68zM6.57 11.444c-.406-.153-.527-.255-.527-.439 0-.223.216-.388.509-.388.203 0 .375.083.547.286l.356-.464a1.538 1.538 0 0 0-1.03-.388c-.617 0-1.094.432-1.094 1.005 0 .483.222.732.858.96.267.096.401.16.47.198.135.089.204.216.204.362 0 .287-.222.496-.527.496-.325 0-.586-.165-.745-.464l-.439.426c.312.465.694.668 1.209.668.706 0 1.209-.47 1.209-1.151.012-.566-.223-.82-1-1.107M7.792 11.857c0 1.03.808 1.826 1.845 1.826.293 0 .547-.057.852-.204v-.801c-.273.273-.515.382-.82.382-.687 0-1.177-.497-1.177-1.209 0-.674.502-1.202 1.145-1.202.324 0 .572.114.852.394v-.801a1.738 1.738 0 0 0-.84-.217 1.842 1.842 0 0 0-1.857 1.832M15.909 12.455l-.935-2.353h-.745l1.483 3.587h.369l1.507-3.587h-.738zM17.9 13.6h1.934v-.591H18.58v-.942h1.208v-.591H18.58V10.7h1.254v-.598H17.9zM22.537 11.139c0-.655-.452-1.03-1.24-1.03h-1.012v3.498h.68V12.2h.09l.941 1.406h.84l-1.1-1.476c.515-.108.8-.458.8-.992m-1.374.579h-.197v-1.063h.21c.426 0 .655.178.655.522 0 .35-.23.54-.668.54"
      />
      <path
        fill="#FB923C"
        d="M12.582 13.728a1.864 1.864 0 1 0 0-3.728 1.864 1.864 0 0 0 0 3.727"
      />
      <path
        fill="#FB923C"
        d="M12.582 13.728a1.864 1.864 0 1 0 0-3.728 1.864 1.864 0 0 0 0 3.727"
        opacity={0.65}
      />
      <path
        fill="#34423D"
        d="M22.97 10.235c0-.063-.045-.095-.115-.095h-.102v.305h.076v-.12l.09.12h.089l-.102-.127c.038-.006.063-.038.063-.083m-.128.045h-.012v-.083h.012c.038 0 .058.013.058.038 0 .032-.02.045-.058.045"
      />
      <path
        fill="#34423D"
        d="M22.868 10.025a.269.269 0 0 0-.267.268c0 .146.12.267.267.267.146 0 .267-.121.267-.267a.269.269 0 0 0-.267-.268m0 .49a.22.22 0 0 1-.217-.222.22.22 0 0 1 .217-.223c.114 0 .21.102.21.223 0 .12-.096.222-.21.222"
      />
    </svg>
  );
};
export default SvgDiscover;
