import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgGift = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4 8.9a.1.1 0 0 0-.1.1v2a.1.1 0 0 0 .1.1h16a.1.1 0 0 0 .1-.1V9a.1.1 0 0 0-.1-.1zM2.1 9c0-1.05.85-1.9 1.9-1.9h16c1.05 0 1.9.85 1.9 1.9v2a1.9 1.9 0 0 1-1.9 1.9H4A1.9 1.9 0 0 1 2.1 11z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 7.1a.9.9 0 0 1 .9.9v13a.9.9 0 0 1-1.8 0V8a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5 11.1a.9.9 0 0 1 .9.9v7A1.1 1.1 0 0 0 7 20.1h10a1.1 1.1 0 0 0 1.1-1.1v-7a.9.9 0 1 1 1.8 0v7a2.9 2.9 0 0 1-2.9 2.9H7A2.9 2.9 0 0 1 4.1 19v-7a.9.9 0 0 1 .9-.9M9.544 4.945C8.869 4.195 8.158 3.89 7.516 3.9H7.5a1.6 1.6 0 1 0 0 3.2.9.9 0 0 1 0 1.8 3.4 3.4 0 0 1-.008-6.8c1.284-.02 2.46.609 3.39 1.641.426.474.8 1.031 1.118 1.65a7.739 7.739 0 0 1 1.118-1.65c.93-1.032 2.106-1.66 3.39-1.641a3.4 3.4 0 0 1-.008 6.8.9.9 0 1 1 0-1.8 1.6 1.6 0 1 0 0-3.2h-.016c-.642-.011-1.353.296-2.028 1.045-.679.755-1.245 1.882-1.581 3.267a.9.9 0 0 1-1.75 0c-.336-1.385-.902-2.512-1.581-3.267"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgGift;
