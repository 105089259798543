import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgUploadCloud = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.364 11.364a.9.9 0 0 1 1.272 0l4 4a.9.9 0 1 1-1.272 1.272L12 13.273l-3.364 3.363a.9.9 0 1 1-1.272-1.272z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 11.1a.9.9 0 0 1 .9.9v8a.9.9 0 1 1-1.8 0v-8a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M8.971 3.106a8.166 8.166 0 0 1 8.007 5.45h.48a5.444 5.444 0 0 1 5.274 4.106 5.453 5.453 0 0 1-2.67 6.128.9.9 0 1 1-.863-1.58 3.648 3.648 0 0 0 1.788-4.104 3.65 3.65 0 0 0-3.529-2.75m0 0h-1.145a.9.9 0 0 1-.872-.674A6.38 6.38 0 0 0 11.84 5.44a6.367 6.367 0 0 0-7.712 2.075 6.382 6.382 0 0 0 .37 7.987.9.9 0 1 1-1.349 1.191A8.18 8.18 0 0 1 8.971 3.106"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11.364 11.364a.9.9 0 0 1 1.272 0l4 4a.9.9 0 1 1-1.272 1.272L12 13.273l-3.364 3.363a.9.9 0 1 1-1.272-1.272z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgUploadCloud;
