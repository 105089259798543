import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgAtSymbol = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 8.9a3.1 3.1 0 1 0 0 6.2 3.1 3.1 0 0 0 0-6.2M7.1 12a4.9 4.9 0 1 1 9.8 0 4.9 4.9 0 0 1-9.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M8.649 2.673A9.9 9.9 0 0 1 21.9 12L21 12h.9v1.5a3.4 3.4 0 0 1-6.8 0V12a.9.9 0 1 1 1.8 0v1.5a1.6 1.6 0 0 0 3.2 0v-1.501a8.1 8.1 0 1 0-4.95 7.452.9.9 0 0 1 .7 1.658A9.901 9.901 0 1 1 8.649 2.673"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgAtSymbol;
