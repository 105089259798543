import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgSofa = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M9.333 4.55A2.45 2.45 0 0 0 6.883 7v4.667a1.05 1.05 0 0 1-2.1 0V7a4.55 4.55 0 0 1 4.55-4.55h9.334A4.55 4.55 0 0 1 23.217 7v4.667a1.05 1.05 0 1 1-2.1 0V7a2.45 2.45 0 0 0-2.45-2.45z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M22.645 12.764a2.45 2.45 0 0 0-2.928 2.403V17.5a1.05 1.05 0 1 1-2.1 0v-2.333a4.55 4.55 0 1 1 5.6 4.427v.24a3.383 3.383 0 0 1-3.384 3.383H8.167a3.383 3.383 0 0 1-3.384-3.384v-.24a4.553 4.553 0 0 1-3.412-5.314 4.55 4.55 0 0 1 9.012.888V17.5a1.05 1.05 0 1 1-2.1 0v-2.333a2.45 2.45 0 1 0-2.45 2.45c.58 0 1.05.47 1.05 1.05v1.166c0 .71.575 1.284 1.284 1.284h11.666c.71 0 1.284-.575 1.284-1.284v-1.166c0-.58.47-1.05 1.05-1.05a2.45 2.45 0 0 0 .478-4.853"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M8.283 14c0-.58.47-1.05 1.05-1.05h9.334a1.05 1.05 0 1 1 0 2.1H9.333c-.58 0-1.05-.47-1.05-1.05M8.167 21.117c.58 0 1.05.47 1.05 1.05V24.5a1.05 1.05 0 1 1-2.1 0v-2.333c0-.58.47-1.05 1.05-1.05M19.833 21.117c.58 0 1.05.47 1.05 1.05V24.5a1.05 1.05 0 1 1-2.1 0v-2.333c0-.58.47-1.05 1.05-1.05"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgSofa;
