import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDocument = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M14 2.1a.9.9 0 0 1 .9.9v4a.1.1 0 0 0 .1.1h4a.9.9 0 0 1 0 1.8h-4A1.9 1.9 0 0 1 13.1 7V3a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7 3.9A1.1 1.1 0 0 0 5.9 5v3a.9.9 0 0 1-1.8 0V5A2.9 2.9 0 0 1 7 2.1h7a.9.9 0 0 1 .636.264l5 5A.9.9 0 0 1 19.9 8v11a2.9 2.9 0 0 1-2.9 2.9h-5a.9.9 0 1 1 0-1.8h5a1.1 1.1 0 0 0 1.1-1.1V8.373L13.627 3.9z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M6 11.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2M2.1 14a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4.759 16.138a.9.9 0 0 1 .603 1.12l-.901 3.006 1.137-.569a.9.9 0 0 1 .805 0l1.136.569-.901-3.005a.9.9 0 1 1 1.724-.518l1.5 5a.9.9 0 0 1-1.264 1.064L6 21.506l-2.597 1.299a.9.9 0 0 1-1.265-1.064l1.5-5a.9.9 0 0 1 1.12-.603"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDocument;
