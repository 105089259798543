import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgHome = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.364 2.364a.9.9 0 0 1 1.272 0l9 9A.9.9 0 0 1 21 12.9h-2a.9.9 0 0 1-.159-1.786L12 4.273l-6.841 6.841A.9.9 0 0 1 5 12.9H3a.9.9 0 0 1-.636-1.536z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5 11.1a.9.9 0 0 1 .9.9v7A1.1 1.1 0 0 0 7 20.1h10a1.1 1.1 0 0 0 1.1-1.1v-7a.9.9 0 1 1 1.8 0v7a2.9 2.9 0 0 1-2.9 2.9H7A2.9 2.9 0 0 1 4.1 19v-7a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.1 12a.9.9 0 0 1 .9-.9h4a.9.9 0 0 1 .9.9v4a.9.9 0 0 1-.9.9h-4a.9.9 0 0 1-.9-.9zm1.8.9v2.2h2.2v-2.2z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgHome;
