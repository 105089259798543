import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDisconnected = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M21.636 2.364a.9.9 0 0 1 0 1.272L19.75 5.524a4.44 4.44 0 0 1 .369 4.183 4.436 4.436 0 0 1-.986 1.434l-1.496 1.495a.9.9 0 0 1-1.272 0l-5-5a.9.9 0 0 1 0-1.272l1.495-1.496a4.437 4.437 0 0 1 5.617-.616l1.888-1.888a.9.9 0 0 1 1.272 0m-3.772 3.772a.897.897 0 0 1-.165-.226 2.637 2.637 0 0 0-3.552.216l-.01.01-.864.864L17 10.727l.864-.863.01-.01A2.637 2.637 0 0 0 18.09 6.3a.898.898 0 0 1-.226-.165M7.636 11.364a.9.9 0 0 0-1.272 0l-1.496 1.495a4.437 4.437 0 0 0-.617 5.617l-1.887 1.888a.9.9 0 0 0 1.272 1.272l1.888-1.887a4.441 4.441 0 0 0 4.183.369 4.437 4.437 0 0 0 1.434-.986l1.495-1.496a.9.9 0 0 0 0-1.272L12.273 16l1.363-1.364a.9.9 0 0 0-1.272-1.272L11 14.727 9.273 13l1.363-1.364a.9.9 0 0 0-1.272-1.272L8 11.727zm-.272 2.272 3 3 .363.364-.863.864-.01.01a2.637 2.637 0 0 1-3.553.216.9.9 0 0 0-.39-.391 2.634 2.634 0 0 1 .215-3.552l.01-.01.864-.864zm13.272 1.728a.9.9 0 0 0-1.272 0L18 16.727l-1.364-1.363a.9.9 0 1 0-1.272 1.272L16.727 18l-1.363 1.364a.9.9 0 1 0 1.272 1.272L18 19.273l1.364 1.363a.9.9 0 1 0 1.272-1.272L19.273 18l1.363-1.364a.9.9 0 0 0 0-1.272"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDisconnected;
