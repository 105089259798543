import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMailOpen = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.5 2.251a.9.9 0 0 1 1 0l9 6a.9.9 0 0 1 0 1.498l-9 6a.9.9 0 0 1-1 0l-9-6a.9.9 0 0 1 0-1.498zM4.623 9 12 13.918 19.378 9 12 4.082z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3 8.1a.9.9 0 0 1 .9.9v10A1.1 1.1 0 0 0 5 20.1h14a1.1 1.1 0 0 0 1.1-1.1V9a.9.9 0 1 1 1.8 0v10a2.9 2.9 0 0 1-2.9 2.9H5A2.9 2.9 0 0 1 2.1 19V9a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.636 12.364a.9.9 0 0 1 0 1.272l-6 6a.9.9 0 1 1-1.272-1.272l6-6a.9.9 0 0 1 1.272 0M14.364 12.364a.9.9 0 0 1 1.272 0l6 6a.9.9 0 0 1-1.272 1.272l-6-6a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgMailOpen;
