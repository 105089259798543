import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgSuccessColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#FFF4E5" d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18" />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M12 3.457a8.543 8.543 0 1 0 0 17.086 8.543 8.543 0 0 0 0-17.086M2.543 12a9.457 9.457 0 1 1 18.914 0 9.457 9.457 0 0 1-18.914 0"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M15.323 9.677a.457.457 0 0 1 0 .646l-4 4a.456.456 0 0 1-.646 0l-2-2a.457.457 0 1 1 .646-.646L11 13.354l3.677-3.677a.457.457 0 0 1 .646 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgSuccessColored;
