import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgFacebook = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#facebook_svg__a)">
        <path
          fill="#4867AA"
          fillRule="evenodd"
          d="M24 12c0-6.628-5.372-12-12-12S0 5.372 0 12c0 5.99 4.388 10.954 10.126 11.854v-8.385H7.078V12h3.048V9.356c0-3.007 1.79-4.668 4.532-4.668 1.313 0 2.686.234 2.686.234v2.952h-1.512c-1.492 0-1.956.926-1.956 1.875V12h3.327l-.531 3.468h-2.796v8.386C19.612 22.954 24 17.989 24 12"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="facebook_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgFacebook;
