import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBackspace = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M8.364 5.364A.9.9 0 0 1 9 5.1h11A1.9 1.9 0 0 1 21.9 7v10a1.9 1.9 0 0 1-1.9 1.9H9a.9.9 0 0 1-.636-.264l-5-5a2.4 2.4 0 0 1 0-3.273zM9.373 6.9l-4.715 4.715a.6.6 0 0 0 0 .77L9.373 17.1H20a.1.1 0 0 0 .1-.1V7a.1.1 0 0 0-.1-.1z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11.364 9.364a.9.9 0 0 1 1.272 0L14 10.727l1.364-1.363a.9.9 0 0 1 1.272 1.272L15.273 12l1.363 1.364a.9.9 0 1 1-1.272 1.272L14 13.273l-1.364 1.363a.9.9 0 1 1-1.272-1.272L12.727 12l-1.363-1.364a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBackspace;
