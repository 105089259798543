import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgTabled = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4.9 4.006v-.003zm0-.003a.101.101 0 0 1 .027-.033.33.33 0 0 1 .216-.07h13.714a.33.33 0 0 1 .216.07.101.101 0 0 1 .027.033v15.994a.101.101 0 0 1-.027.033.33.33 0 0 1-.216.07H5.143a.33.33 0 0 1-.216-.07.101.101 0 0 1-.027-.033zm0 15.991v.003zm14.2 0v.003zm0-15.988v-.003zM3.1 4c0-1.158 1.031-1.9 2.043-1.9h13.714c1.012 0 2.043.742 2.043 1.9v16c0 1.159-1.031 1.9-2.043 1.9H5.143C4.13 21.9 3.1 21.159 3.1 20z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 16.9a.1.1 0 1 0 0 .2.1.1 0 0 0 0-.2m-1.9.1a1.9 1.9 0 1 1 3.8 0 1.9 1.9 0 0 1-3.8 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgTabled;
