import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLogin = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.95 3.95A2.9 2.9 0 0 1 5 3.1h7A2.9 2.9 0 0 1 14.9 6v2a.9.9 0 0 1-1.8 0V6A1.1 1.1 0 0 0 12 4.9H5A1.1 1.1 0 0 0 3.9 6v12A1.1 1.1 0 0 0 5 19.1h7a1.1 1.1 0 0 0 1.1-1.1v-2a.9.9 0 1 1 1.8 0v2a2.9 2.9 0 0 1-2.9 2.9H5A2.9 2.9 0 0 1 2.1 18V6a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10.636 8.364a.9.9 0 0 1 0 1.272L9.173 11.1H20a.9.9 0 1 1 0 1.8H9.173l1.463 1.464a.9.9 0 1 1-1.272 1.272l-2.996-2.995a.895.895 0 0 1-.266-.702.897.897 0 0 1 .262-.575l3-3a.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgLogin;
