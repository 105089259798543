import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCube = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.559 2.216a.9.9 0 0 1 .882 0l8 4.5a.9.9 0 0 1 .459.784v9a.9.9 0 0 1-.459.784l-8 4.5a.9.9 0 0 1-.882 0l-8-4.5A.9.9 0 0 1 3.1 16.5v-9a.9.9 0 0 1 .459-.784zM4.9 8.026v7.948l7.1 3.993 7.1-3.993V8.026L12 4.033z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M20.784 7.059a.9.9 0 0 1-.343 1.225l-8 4.5a.9.9 0 1 1-.882-1.568l8-4.5a.9.9 0 0 1 1.225.343"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 11.1a.9.9 0 0 1 .9.9v9a.9.9 0 0 1-1.8 0v-9a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3.216 7.059a.9.9 0 0 1 1.225-.343l8 4.5a.9.9 0 1 1-.882 1.568l-8-4.5a.9.9 0 0 1-.343-1.225"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCube;
