import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgStore = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.1 21a.9.9 0 0 1 .9-.9h18a.9.9 0 0 1 0 1.8H3a.9.9 0 0 1-.9-.9M4.195 2.598A.9.9 0 0 1 5 2.1h14a.9.9 0 0 1 .805.498l2 4A.901.901 0 0 1 21.9 7v1a3.9 3.9 0 0 1-6.658 2.758c-.09-.09-.17-.19-.242-.293a2.125 2.125 0 0 1-.242.293 3.9 3.9 0 0 1-5.516 0c-.09-.09-.17-.19-.242-.293a2.122 2.122 0 0 1-.242.293A3.9 3.9 0 0 1 2.1 8V7a.9.9 0 0 1 .095-.402zM9.992 7.9c.03.173.066.354.11.534.068.275.146.524.23.723.091.214.162.307.183.328a2.1 2.1 0 0 0 2.97 0c.02-.021.091-.114.182-.328.085-.2.163-.448.23-.723.044-.18.081-.36.11-.534zm-1.984 0H3.9V8a2.1 2.1 0 0 0 3.585 1.485c.02-.021.092-.114.182-.328.085-.2.163-.448.23-.723.044-.18.081-.36.11-.534M4.456 6.1h15.088l-1.1-2.2H5.556zM20.1 7.9h-4.108c.03.173.066.354.11.534.068.275.146.524.23.723.091.214.162.307.183.328A2.1 2.1 0 0 0 20.1 8z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5 9.95a.9.9 0 0 1 .9.9V21a.9.9 0 1 1-1.8 0V10.85a.9.9 0 0 1 .9-.9M19 9.95a.9.9 0 0 1 .9.9V21a.9.9 0 0 1-1.8 0V10.85a.9.9 0 0 1 .9-.9M11 15.9A1.1 1.1 0 0 0 9.9 17v4a.9.9 0 1 1-1.8 0v-4a2.9 2.9 0 0 1 2.9-2.9h2a2.9 2.9 0 0 1 2.9 2.9v4a.9.9 0 0 1-1.8 0v-4a1.1 1.1 0 0 0-1.1-1.1z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgStore;
