import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPaperClip = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.364 3.364a5.143 5.143 0 1 1 7.272 7.272l-6.5 6.5a3.021 3.021 0 0 1-4.272-4.272l6.5-6.5a.9.9 0 1 1 1.272 1.272l-6.5 6.5a1.221 1.221 0 1 0 1.728 1.728l6.5-6.5a3.342 3.342 0 1 0-4.728-4.728l-6.5 6.5a5.464 5.464 0 0 0 7.728 7.728l6.5-6.5a.9.9 0 1 1 1.272 1.272l-6.5 6.5A7.264 7.264 0 1 1 4.864 9.864z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPaperClip;
