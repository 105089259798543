import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgLove = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M8.422 5.912a4.1 4.1 0 0 0-3.294 7.015l.005.005L12 19.734l6.867-6.8c.02-.02.04-.039.061-.056a4.1 4.1 0 1 0-6.209-5.33.9.9 0 0 1-1.441-.004 4.1 4.1 0 0 0-2.856-1.631m11.646 8.364-7.435 7.364a.9.9 0 0 1-1.266 0l-7.498-7.426a5.899 5.899 0 0 1 8.135-8.547 5.903 5.903 0 0 1 5.782-1.278 5.9 5.9 0 0 1 2.345 9.831.922.922 0 0 1-.063.056"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgLove;
