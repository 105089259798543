import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCreditCard = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M6 5.9A2.1 2.1 0 0 0 3.9 8v8c0 1.16.94 2.1 2.1 2.1h12a2.1 2.1 0 0 0 2.1-2.1V8A2.1 2.1 0 0 0 18 5.9zM2.1 8A3.9 3.9 0 0 1 6 4.1h12A3.9 3.9 0 0 1 21.9 8v8a3.9 3.9 0 0 1-3.9 3.9H6A3.9 3.9 0 0 1 2.1 16z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M2.1 10a.9.9 0 0 1 .9-.9h18a.9.9 0 0 1 0 1.8H3a.9.9 0 0 1-.9-.9M6.1 15a.9.9 0 0 1 .9-.9h.01a.9.9 0 1 1 0 1.8H7a.9.9 0 0 1-.9-.9M10.1 15a.9.9 0 0 1 .9-.9h2a.9.9 0 0 1 0 1.8h-2a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCreditCard;
