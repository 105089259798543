import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgZip = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M7 3.9A1.1 1.1 0 0 0 5.9 5v14.001a1.1 1.1 0 0 0 .55.955.9.9 0 1 1-.9 1.558A2.9 2.9 0 0 1 4.1 19V5A2.9 2.9 0 0 1 7 2.1h7a.9.9 0 0 1 .636.264l5 5A.9.9 0 0 1 19.9 8v11a2.9 2.9 0 0 1-2.9 2.9h-1a.9.9 0 1 1 0-1.8h1a1.1 1.1 0 0 0 1.1-1.1V8.373L13.627 3.9z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11 17.9A1.1 1.1 0 0 0 9.9 19v2a.1.1 0 0 0 .1.1h2a.1.1 0 0 0 .1-.1v-2a1.1 1.1 0 0 0-1.1-1.1m-2.05-.95A2.9 2.9 0 0 1 13.9 19v2a1.9 1.9 0 0 1-1.9 1.9h-2A1.9 1.9 0 0 1 8.1 21v-2a2.9 2.9 0 0 1 .85-2.05M9.1 5a.9.9 0 0 1 .9-.9h1a.9.9 0 0 1 0 1.8h-1a.9.9 0 0 1-.9-.9M11.1 7a.9.9 0 0 1 .9-.9h1a.9.9 0 0 1 0 1.8h-1a.9.9 0 0 1-.9-.9M9.1 9a.9.9 0 0 1 .9-.9h1a.9.9 0 0 1 0 1.8h-1a.9.9 0 0 1-.9-.9M11.1 11a.9.9 0 0 1 .9-.9h1a.9.9 0 0 1 0 1.8h-1a.9.9 0 0 1-.9-.9M9.1 13a.9.9 0 0 1 .9-.9h1a.9.9 0 0 1 0 1.8h-1a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11.1 15a.9.9 0 0 1 .9-.9h1a.9.9 0 0 1 0 1.8h-1a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgZip;
