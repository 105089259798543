import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBluetoothOff = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.364 2.364a.9.9 0 0 1 1.272 0l18 18a.9.9 0 1 1-1.272 1.272l-18-18a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11.61 3.189a.9.9 0 0 1 .952.108l5 4a.9.9 0 0 1 0 1.406l-2.776 2.22a.9.9 0 0 1-1.124-1.406L15.559 8 12.9 5.873V8a.9.9 0 0 1-1.8 0V4a.9.9 0 0 1 .51-.811m-.195 8.127-4.975 3.98a.9.9 0 0 0 1.124 1.406l3.536-2.829V20a.9.9 0 0 0 1.462.703L17 17.153a.9.9 0 0 0-1.124-1.406l-2.976 2.38V12.06a.9.9 0 0 0-1.485-.743"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBluetoothOff;
