import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgFavicon = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 274 274"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M25.89 248.11V27.616c0-.604 0-.907.117-1.137a1.08 1.08 0 0 1 .472-.472c.23-.117.533-.117 1.137-.117h168.499c4.229 0 6.344 0 7.959-.823a7.55 7.55 0 0 0 3.3-3.3c.823-1.616.823-3.73.823-7.96v-1.725c0-4.23 0-6.344-.823-7.959a7.55 7.55 0 0 0-3.3-3.3C202.459 0 200.344 0 196.115 0H22.438C14.584 0 10.657 0 7.657 1.528a14.024 14.024 0 0 0-6.129 6.129C0 10.657 0 14.584 0 22.437v229.125c0 7.854 0 11.781 1.528 14.781a14.018 14.018 0 0 0 6.129 6.128c3 1.529 6.927 1.529 14.78 1.529h229.125c7.854 0 11.781 0 14.781-1.529a14.013 14.013 0 0 0 6.128-6.128c1.529-3 1.529-6.927 1.529-14.781V77.885c0-4.229 0-6.344-.823-7.959a7.55 7.55 0 0 0-3.3-3.3c-1.615-.823-3.73-.823-7.959-.823h-1.726c-4.229 0-6.343 0-7.959.823a7.55 7.55 0 0 0-3.3 3.3c-.823 1.615-.823 3.73-.823 7.959v168.499c0 .604 0 .906-.117 1.137a1.087 1.087 0 0 1-.472.472c-.231.117-.533.117-1.137.117zm222.1 10.706.008-.07zm10.757-10.818.069-.008zM26.002 258.746l.008.07zm0-243.492.008-.07zM15.184 26.01l.07-.008z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M250.5 47c12.979 0 23.5-10.521 23.5-23.5S263.479 0 250.5 0 227 10.521 227 23.5 237.521 47 250.5 47"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgFavicon;
