import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCast = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.1 19a.9.9 0 0 1 .9-.9h.01a.9.9 0 1 1 0 1.8H3a.9.9 0 0 1-.9-.9M2.1 15a.9.9 0 0 1 .9-.9A4.9 4.9 0 0 1 7.9 19a.9.9 0 1 1-1.8 0A3.1 3.1 0 0 0 3 15.9a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M2.1 11a.9.9 0 0 1 .9-.9 8.9 8.9 0 0 1 8.9 8.9.9.9 0 0 1-1.8 0A7.1 7.1 0 0 0 3 11.9a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M6 4.1h12A3.9 3.9 0 0 1 21.9 8v8a3.9 3.9 0 0 1-3.9 3.9h-3a.9.9 0 1 1 0-1.8h3a2.1 2.1 0 0 0 2.1-2.1V8A2.1 2.1 0 0 0 18 5.9H6.005a2.1 2.1 0 0 0-1.956 1.4.9.9 0 1 1-1.698-.6 3.9 3.9 0 0 1 3.64-2.6z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCast;
