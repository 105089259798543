import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgWifi = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M11.1 18a.9.9 0 0 1 .9-.9h.01a.9.9 0 1 1 0 1.8H12a.9.9 0 0 1-.9-.9M12 14.9a3.1 3.1 0 0 0-2.192.909.9.9 0 0 1-1.272-1.273 4.9 4.9 0 0 1 6.928 0 .9.9 0 0 1-1.272 1.273A3.1 3.1 0 0 0 12 14.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 10.9a7.1 7.1 0 0 0-5.02 2.08.9.9 0 1 1-1.273-1.273 8.9 8.9 0 0 1 12.586 0 .9.9 0 1 1-1.272 1.272A7.098 7.098 0 0 0 12 10.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgWifi;
