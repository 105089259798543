import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgStar = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 1c.346 0 .662.206.816.53l2.906 6.168 6.499.986a.922.922 0 0 1 .735.648.983.983 0 0 1-.231.977l-4.71 4.8 1.11 6.778a.974.974 0 0 1-.361.931.875.875 0 0 1-.958.072l-5.8-3.2-5.812 3.2a.875.875 0 0 1-.958-.072.974.974 0 0 1-.362-.931l1.11-6.778-4.709-4.8a.984.984 0 0 1-.23-.977.922.922 0 0 1 .734-.648l6.499-.986 2.906-6.167A.906.906 0 0 1 12 1m0 3.104L9.697 8.99a.914.914 0 0 1-.685.52l-5.146.782 3.73 3.801a.98.98 0 0 1 .262.844l-.88 5.37 4.606-2.535a.873.873 0 0 1 .847 0l4.59 2.533-.88-5.368a.98.98 0 0 1 .263-.844l3.73-3.801-5.146-.781a.913.913 0 0 1-.685-.521z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgStar;
