import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgTicket = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M15 4.1a.9.9 0 0 1 .9.9v2a.9.9 0 0 1-1.8 0V5a.9.9 0 0 1 .9-.9M15 10.1a.9.9 0 0 1 .9.9v2a.9.9 0 0 1-1.8 0v-2a.9.9 0 0 1 .9-.9M15 16.1a.9.9 0 0 1 .9.9v2a.9.9 0 0 1-1.8 0v-2a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5 5.9A1.1 1.1 0 0 0 3.9 7v2.243a2.9 2.9 0 0 1 0 5.514V17A1.1 1.1 0 0 0 5 18.1h14a1.1 1.1 0 0 0 1.1-1.1v-2.243a2.899 2.899 0 0 1 0-5.514V7A1.1 1.1 0 0 0 19 5.9zm-2.05-.95A2.9 2.9 0 0 1 5 4.1h14A2.9 2.9 0 0 1 21.9 7v3a.9.9 0 0 1-.9.9 1.1 1.1 0 1 0 0 2.2.9.9 0 0 1 .9.9v3a2.9 2.9 0 0 1-2.9 2.9H5A2.9 2.9 0 0 1 2.1 17v-3a.9.9 0 0 1 .9-.9 1.1 1.1 0 0 0 0-2.2.9.9 0 0 1-.9-.9V7a2.9 2.9 0 0 1 .85-2.05"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgTicket;
