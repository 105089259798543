import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgVideo = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M19.955 8.53 15.9 10.555v2.888l4.055 2.027m0-6.942a.1.1 0 0 1 .05-.01zm-.804-1.61A1.9 1.9 0 0 1 21.9 8.618v6.764a1.903 1.903 0 0 1-1.814 1.897 1.9 1.9 0 0 1-.935-.198l-4.553-2.276A.9.9 0 0 1 14.1 14v-4a.9.9 0 0 1 .498-.805zm.854 1.6a.1.1 0 0 1 .048.014zm.048.014a.1.1 0 0 1 .034.037zm.034.037a.099.099 0 0 1 .013.048zm.013.048v6.764"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5 6.9A1.1 1.1 0 0 0 3.9 8v8A1.1 1.1 0 0 0 5 17.1h8a1.1 1.1 0 0 0 1.1-1.1V8A1.1 1.1 0 0 0 13 6.9zM2.1 8A2.9 2.9 0 0 1 5 5.1h8A2.9 2.9 0 0 1 15.9 8v8a2.9 2.9 0 0 1-2.9 2.9H5A2.9 2.9 0 0 1 2.1 16z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgVideo;
