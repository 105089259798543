import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCalendarClockColored = ({
  title,
  titleId,
  size = 16,
  className,
}: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#FFF4E5"
        fillRule="evenodd"
        d="M5 21h10.75l.029-.06A3.75 3.75 0 0 1 19 14.214V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M5 5.5A1.5 1.5 0 0 0 3.5 7v12A1.5 1.5 0 0 0 5 20.5h6.795a.5.5 0 0 1 0 1H5A2.5 2.5 0 0 1 2.5 19V7A2.5 2.5 0 0 1 5 4.5h12A2.5 2.5 0 0 1 19.5 7v4a.5.5 0 0 1-1 0V7A1.5 1.5 0 0 0 17 5.5z"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M18 14.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7M13.5 18a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0M15 2.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V3a.5.5 0 0 1 .5-.5M7 2.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V3a.5.5 0 0 1 .5-.5M2.5 11a.5.5 0 0 1 .5-.5h16a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
        clipRule="evenodd"
      />
      <path
        fill="#F5AE45"
        fillRule="evenodd"
        d="M18 15.996a.5.5 0 0 1 .5.5v1.297l.854.853a.5.5 0 0 1-.708.707l-1-1A.5.5 0 0 1 17.5 18v-1.504a.5.5 0 0 1 .5-.5"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgCalendarClockColored;
