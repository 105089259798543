import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgBuilding = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.1 21a.9.9 0 0 1 .9-.9h18a.9.9 0 0 1 0 1.8H3a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M13.473 2.234A.9.9 0 0 1 13.9 3v18a.9.9 0 0 1-1.8 0V4.456l-6.2 3.1V21a.9.9 0 1 1-1.8 0V7a.9.9 0 0 1 .497-.805l8-4a.9.9 0 0 1 .876.04"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12.251 6.5a.9.9 0 0 1 1.248-.249l6 4A.9.9 0 0 1 19.9 11v10a.9.9 0 1 1-1.8 0v-9.518l-5.6-3.733a.9.9 0 0 1-.249-1.248M9 8.1a.9.9 0 0 1 .9.9v.01a.9.9 0 0 1-1.8 0V9a.9.9 0 0 1 .9-.9M9 11.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V12a.9.9 0 0 1 .9-.9M9 14.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V15a.9.9 0 0 1 .9-.9M9 17.1a.9.9 0 0 1 .9.9v.01a.9.9 0 1 1-1.8 0V18a.9.9 0 0 1 .9-.9"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgBuilding;
