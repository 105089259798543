import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDoctorWithStethoscope = ({
  title,
  titleId,
  size = 16,
  className,
}: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 328 317"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#doctor-with-stethoscope_svg__a)">
        <path
          fill="url(#doctor-with-stethoscope_svg__b)"
          d="M24 233c0-77.32 62.68-140 140-140s140 62.68 140 140v89H24z"
        />
        <path
          fill="#263238"
          d="M157.767 70.358c-9.576 1.71-10.786.832-10.907-6.052-.121-6.883-3.601-18.366 1.24-21.966a2.624 2.624 0 0 1 2.537-.613 2.62 2.62 0 0 1 1.805 1.884 3.148 3.148 0 0 1 3.949-1.831c.196-1.422.423-5.068 3.025-6.051 2.603-.984 4.993 1.376 4.993 1.376s-.197-5.612 3.585-5.068a5.688 5.688 0 0 1 4.66 4.403s2.178-3.177 5.128-1.922c2.95 1.256 2.33 4.947 2.33 4.947s3.132-1.513 5.462.197c2.329 1.71.635 4.448.635 4.448s2.118-5.446 6.157-2.436c3.631 2.723.333 6.99.333 6.99s3.994-.651 4.962 1.361c3.268 6.853-9.183 14.508-9.591 26.082-19.123 7.23-30.303-5.75-30.303-5.75"
        />
        <path
          fill="#FFB573"
          d="m88.751 254.289-6.384-1.18-8.442 8.064a4.527 4.527 0 0 0-1.407 3.404l.121 6.596a4.538 4.538 0 0 0 4.16 4.463l2.935.257a4.539 4.539 0 0 0 4.811-3.344l1.513-5.385s8.336-9.849 2.693-12.875"
        />
        <path
          fill="#295C6C"
          d="M153.229 133.655c-4.282 11.089-8.548 22.224-12.663 33.389-4.115 11.164-8.199 22.375-12.027 33.403l-.681 2.043-.469 1.331c-.181.499-.363 1.014-.529 1.407-.711 1.8-1.422 3.283-2.133 4.78-1.422 3.026-2.86 5.734-4.312 8.472-2.92 5.447-5.93 10.696-8.971 15.931a1907.818 1907.818 0 0 1-18.366 31.119l-19.259-11.982c6.354-9.879 12.481-20.075 18.351-30.257 2.89-5.113 5.734-10.242 8.381-15.325 1.317-2.541 2.587-5.083 3.707-7.564.529-1.195 1.059-2.421 1.407-3.419.121-.288.166-.484.242-.681l.242-.787.696-2.208c3.646-11.619 7.473-22.844 11.165-34.236l11.074-34.054z"
        />
        <path
          fill="#fff"
          d="M153.229 133.655c-4.282 11.089-8.548 22.224-12.663 33.389-4.115 11.164-8.199 22.375-12.027 33.403l-.681 2.043-.469 1.331c-.181.499-.363 1.014-.529 1.407-.711 1.8-1.422 3.283-2.133 4.78-1.422 3.026-2.86 5.734-4.312 8.472-2.92 5.447-5.93 10.696-8.971 15.931a1907.818 1907.818 0 0 1-18.366 31.119l-19.259-11.982c6.354-9.879 12.481-20.075 18.351-30.257 2.89-5.113 5.734-10.242 8.381-15.325 1.317-2.541 2.587-5.083 3.707-7.564.529-1.195 1.059-2.421 1.407-3.419.121-.288.166-.484.242-.681l.242-.787.696-2.208c3.646-11.619 7.473-22.844 11.165-34.236l11.074-34.054z"
          opacity={0.6}
        />
        <path
          fill="#fff"
          d="M203.834 133.261c-10.59 47.64-7.776 94.659-8.911 104.841l-72.42-4.781c-7.564-57.488.877-94.159 5.673-109.197a13.614 13.614 0 0 1 11.256-9.425 208.41 208.41 0 0 1 32.435-1.014 120.985 120.985 0 0 1 21.725 3.298 13.617 13.617 0 0 1 10.242 16.278"
        />
        <path
          fill="#FFB573"
          d="M182.003 81.401c-2.39 8.972-5.219 24.856-.666 32.89.712 1.255-.65 2.329-.711 3.782-.166 4.448-6.187 5.779-16.959 5.839-10.771.061-15.385-4.432-12.45-8.668a3.024 3.024 0 0 1 1.906-1.241c10.091-2.254 10.287-10.166 8.91-17.715z"
        />
        <path
          fill="#2A4856"
          d="m162.321 121.159-2.315.227-3.918 2.587a7.094 7.094 0 0 0 .787 4.539 27.78 27.78 0 0 0 6.913.469 7.304 7.304 0 0 0 3.495-3.601l-2.874-3.676z"
        />
        <path
          fill="#B2C4CB"
          d="M162.321 121.159a38.882 38.882 0 0 1 6.581 12.193c10.756-5.052 17.019-17.125 17.019-17.125l-5.189-4.539s-12.496 4.297-18.411 9.471M162.321 121.159a39.805 39.805 0 0 0-10.711 9.925c-7.564-4.645-4.675-17.156-4.675-17.156l6.127-1.21s3.238 3.404 9.259 8.441"
        />
        <path
          fill="#000"
          d="m173.864 87.468-11.8 8.805c.357 1.82.555 3.667.59 5.521 4.251-.317 10.363-4.538 11.104-8.956.355-1.77.391-3.588.106-5.37"
          opacity={0.2}
        />
        <path
          fill="#FFB573"
          d="M184.166 66.576c-1.709 13.358-2.027 21.18-9.258 27.7-10.878 9.667-27.02 2.436-29.334-11.165-2.088-12.239 1.059-32.027 14.538-36.49a18.365 18.365 0 0 1 24.054 19.955"
        />
        <path
          fill="#263238"
          d="M180.899 78.648c-3.026-2.92-1.135-16.899.393-20.908-7.564.726-32.012.696-31.089-6.051.923-6.748 9.819-12.481 24.357-7.746 12.103 3.949 15.129 9.985 15.129 14.917.181 10.136-8.79 19.788-8.79 19.788"
        />
        <path
          fill="#FFB573"
          d="M188.856 78.8a11.858 11.858 0 0 1-6.838 5.779c-3.857 1.195-5.764-2.36-4.674-6.173.968-3.419 4.16-8.184 8.018-7.7 3.858.484 5.265 4.69 3.494 8.094"
        />
        <path
          fill="#355A6B"
          d="m80.99 516.104 22.436 4.947s21.815-85.491 28.29-127.276c1.982-12.708 6.581-30.847 10.772-49.924 5.279-24.055 11.921-50.076 17.367-70.454 5.749-21.467 10.015-36.898 10.015-36.898l-47.367-3.132s-22.042 111.815-26.49 152.98C91.368 429.13 80.99 516.104 80.99 516.104"
        />
        <path
          fill="#000"
          d="M142.487 343.821c5.28-24.055 11.922-50.076 17.368-70.454l-4.977-10.59c-11.755 14.04-14.766 57.473-12.391 81.044"
          opacity={0.2}
        />
        <path
          fill="#355A6B"
          d="M145.074 234.819s9.804 114.266 13.616 153.827c4.236 43.328 15.598 132.087 15.598 132.087h22.783s1.029-94.523-3.026-136.369c-.574-82.268.863-146.247.863-146.247z"
        />
        <path
          fill="#263238"
          d="M164.636 68.966c0 1.089-.711 1.936-1.422 1.89-.711-.045-1.241-.968-1.165-2.057.075-1.09.711-1.936 1.422-1.891.711.045 1.24.968 1.165 2.058M152.291 68.21c0 1.089-.711 1.936-1.422 1.89-.711-.045-1.241-.968-1.165-2.057.075-1.09.711-1.936 1.422-1.89.711.044 1.24.907 1.165 2.057"
        />
        <path
          fill="#ED893E"
          d="M155.12 68.966a39.553 39.553 0 0 1-5.825 8.986c1.801 1.937 5.144 1.332 5.144 1.332z"
        />
        <path
          fill="#263238"
          d="M158.857 82.506a9.075 9.075 0 0 0 6.399-3.177.32.32 0 0 0 0-.439.334.334 0 0 0-.454 0 8.698 8.698 0 0 1-7.277 2.935.316.316 0 0 0-.348.287.332.332 0 0 0 .303.349c.458.042.917.058 1.377.045M167.646 65.184a.727.727 0 0 0 .303-.106.649.649 0 0 0 .197-.892 6.436 6.436 0 0 0-5.053-3.026.65.65 0 0 0 0 1.286 5.116 5.116 0 0 1 3.978 2.42.627.627 0 0 0 .575.318M146.194 63.565a.647.647 0 0 0 .514-.318 5.103 5.103 0 0 1 3.813-2.602.665.665 0 0 0 .605-.68.653.653 0 0 0-.681-.606 6.381 6.381 0 0 0-4.856 3.313.636.636 0 0 0 .242.863.544.544 0 0 0 .363.03"
        />
        <path
          fill="#295C6C"
          d="M182.23 113.111a1.072 1.072 0 0 0-1.171.672 1.069 1.069 0 0 0-.069.478c.529 8.109-12.103 22.693-15.129 66.369-1.8 26.565-3.449 109.394-2.239 152.48a3.722 3.722 0 0 0 4.735 3.464c22.33-6.233 32.874 8.881 37.171-8.941-.227-21.346-6.687-97.987-5.023-116.156 1.664-18.17 8.578-67.534 10.303-80.696 1.528-11.437-22.33-16.535-28.578-17.67"
        />
        <path
          fill="#fff"
          d="M182.23 113.111a1.072 1.072 0 0 0-1.171.672 1.069 1.069 0 0 0-.069.478c.529 8.109-12.103 22.693-15.129 66.369-1.8 26.565-3.449 109.394-2.239 152.48a3.722 3.722 0 0 0 4.735 3.464c22.33-6.233 32.874 8.881 37.171-8.941-.227-21.346-6.687-97.987-5.023-116.156 1.664-18.17 8.578-67.534 10.303-80.696 1.528-11.437-22.33-16.535-28.578-17.67"
          opacity={0.7}
        />
        <path
          fill="#295C6C"
          d="M196.95 150.13c-.983 6.747-1.362 34.009 5.129 47.125 1.089-9.077 2.511-19.864 3.888-30.257z"
          opacity={0.2}
        />
        <path
          fill="#FFB573"
          d="m206.224 268.026-6.445.757-5.703 10.181a4.527 4.527 0 0 0-.363 3.661l2.057 6.264a4.538 4.538 0 0 0 5.295 3.025l2.874-.605a4.539 4.539 0 0 0 3.631-4.629l-.181-5.567s5.113-11.816-1.165-13.087"
        />
        <path
          fill="#295C6C"
          d="M209.764 125.697c2.058 6.052 3.964 12.027 5.855 18.079 1.891 6.051 3.782 12.103 5.567 18.154 1.785 6.051 3.51 12.239 5.098 18.548a254.835 254.835 0 0 1 2.209 9.697c.318 1.679.635 3.389.893 5.204.121.938.257 1.8.333 2.844l.136 1.513.075 1.71c.03.574.03 1.15 0 1.724 0 .605 0 1.18-.075 1.649l-.136 1.513-.167 1.362c-.212 1.83-.484 3.494-.756 5.159-.545 3.298-1.18 6.474-1.831 9.621-1.301 6.294-2.753 12.451-4.221 18.578-1.467 6.127-3.025 12.239-4.538 18.336l-4.539 18.29-21.906-5.945c3.389-11.816 6.475-23.843 9.334-35.794 1.392-6.052 2.693-11.982 3.858-17.897.575-3.026 1.12-5.901 1.513-8.745.227-1.422.409-2.829.53-4.1.117-1.004.158-2.015.121-3.025v-.636l-.106-.817c0-.514-.182-1.195-.273-1.785a80.455 80.455 0 0 0-.786-3.994 170.362 170.362 0 0 0-2.164-8.456c-1.513-5.734-3.434-11.544-5.385-17.323-3.858-11.558-8.2-23.192-12.678-34.508z"
        />
        <path
          fill="#fff"
          d="M209.764 125.697c2.058 6.052 3.964 12.027 5.855 18.079 1.891 6.051 3.782 12.103 5.567 18.154 1.785 6.051 3.51 12.239 5.098 18.548a254.835 254.835 0 0 1 2.209 9.697c.318 1.679.635 3.389.893 5.204.121.938.257 1.8.333 2.844l.136 1.513.075 1.71c.03.574.03 1.15 0 1.724 0 .605 0 1.18-.075 1.649l-.136 1.513-.167 1.362c-.212 1.83-.484 3.494-.756 5.159-.545 3.298-1.18 6.474-1.831 9.621-1.301 6.294-2.753 12.451-4.221 18.578-1.467 6.127-3.025 12.239-4.538 18.336l-4.539 18.29-21.906-5.945c3.389-11.816 6.475-23.843 9.334-35.794 1.392-6.052 2.693-11.982 3.858-17.897.575-3.026 1.12-5.901 1.513-8.745.227-1.422.409-2.829.53-4.1.117-1.004.158-2.015.121-3.025v-.636l-.106-.817c0-.514-.182-1.195-.273-1.785a80.455 80.455 0 0 0-.786-3.994 170.362 170.362 0 0 0-2.164-8.456c-1.513-5.734-3.434-11.544-5.385-17.323-3.858-11.558-8.2-23.192-12.678-34.508z"
          opacity={0.7}
        />
        <path
          fill="#295C6C"
          d="M152.805 111.976a1.752 1.752 0 0 1 1.377 2.859c-3.601 4.175-5.87 9.909-10.136 28.305-5.144 22.194-16.097 133.857-18.154 188.577a3.143 3.143 0 0 1-3.707 3.026c-10.68-1.967-18.003 3.207-19.167-3.646-1.286-7.565 3.509-79.818 12.481-113.797-1.407-30.136 3.676-90.589 13.827-98.622 5.507-4.463 17.216-6.596 23.479-6.702"
        />
        <path
          fill="#fff"
          d="M152.805 111.976a1.752 1.752 0 0 1 1.377 2.859c-3.601 4.175-5.87 9.909-10.136 28.305-5.144 22.194-16.097 133.857-18.154 188.577a3.143 3.143 0 0 1-3.707 3.026c-10.68-1.967-18.003 3.207-19.167-3.646-1.286-7.565 3.509-79.818 12.481-113.797-1.407-30.136 3.676-90.589 13.827-98.622 5.507-4.463 17.216-6.596 23.479-6.702"
          opacity={0.7}
        />
        <path
          fill="#2A4856"
          d="M158.69 126.969s-13.071 36.717-17.67 67.488c-.363 8.366 3.616 24.569 6.838 31.634 6.732-14.66 10.938-23.298 11.604-31.634 1.074-12.542 2.844-67.246 2.844-67.246zM186.042 170.644a.602.602 0 0 1-.568-.463.603.603 0 0 1 .296-.671l2.481-1.301a12.82 12.82 0 0 0 6.869-12.86l-2.693-24.387a8.142 8.142 0 0 0-7.065-7.19 8.137 8.137 0 0 0-8.639 5.193l-8.714 22.92a12.83 12.83 0 0 0 3.404 14.175l2.088 1.891a.608.608 0 0 1 0 .848.604.604 0 0 1-.847 0l-2.088-1.876a14.049 14.049 0 0 1-4.252-7.181 14.047 14.047 0 0 1 .576-8.326l8.789-22.874a9.347 9.347 0 0 1 9.912-5.923 9.353 9.353 0 0 1 8.107 8.222l2.692 24.372a14.034 14.034 0 0 1-7.564 14.07l-2.481 1.286a.483.483 0 0 1-.303.075"
        />
        <path
          fill="#2A4856"
          d="M193.546 136.287a.587.587 0 0 1-.59-.514 8.958 8.958 0 0 0-8.184-7.353c-3.359-.423-7.928.636-9.758 5.053a.61.61 0 0 1-.56.413.6.6 0 0 1-.578-.387.603.603 0 0 1 .018-.479 10.863 10.863 0 0 1 20.242 2.586.59.59 0 0 1-.499.666zM185.407 123.777a.595.595 0 0 1-.531-.403.594.594 0 0 1-.029-.233c.182-2.95-.242-8.32-3.025-9.712a.603.603 0 0 1-.258-.817.605.605 0 0 1 .817-.257c4.025 2.072 3.661 9.969 3.601 10.862a.59.59 0 0 1-.575.56M174.439 168.557c-.151 1.135-.863 1.967-1.604 1.861-.741-.106-1.225-1.074-1.074-2.194.151-1.119.847-1.966 1.589-1.861.741.106 1.225 1.075 1.089 2.194M139.265 144.033c-.953 2.406-3.026 3.858-4.539 3.238-1.512-.621-2.102-3.026-1.149-5.492.953-2.466 3.025-3.858 4.538-3.237 1.513.62 2.088 3.086 1.15 5.491"
        />
        <path
          fill="#2A4856"
          d="M134.288 142.475h-.121a.6.6 0 0 1-.469-.711c1.997-9.939 10.257-25.718 17.534-30.257a.598.598 0 0 1 .485-.14.603.603 0 0 1 .488.778.615.615 0 0 1-.338.376c-7.035 4.433-15.038 19.667-16.989 29.455a.605.605 0 0 1-.59.499"
        />
        <path
          fill="#fff"
          d="M140.99 144.533c-.696 2.072-2.481 3.358-3.994 2.859-1.513-.499-2.194-2.602-1.513-4.69.681-2.088 2.481-3.358 3.994-2.859 1.513.499 2.209 2.602 1.513 4.69"
        />
        <path
          fill="#2A4856"
          d="M141.096 144.745c-.923 2.556-2.633 4.296-3.798 3.872-1.164-.423-1.361-2.844-.423-5.415.938-2.572 2.632-4.297 3.797-3.873 1.165.423 1.362 2.844.424 5.416M187.389 170.22c-.136 1.12-.863 1.952-1.604 1.861-.741-.091-1.225-1.089-1.074-2.209.151-1.119.862-1.951 1.604-1.86.741.09 1.225 1.074 1.074 2.208"
        />
        <path fill="url(#doctor-with-stethoscope_svg__c)" d="M0 51h328v268H0z" />
      </g>
      <defs>
        <linearGradient
          id="doctor-with-stethoscope_svg__b"
          x1={66.903}
          x2={138.599}
          y1={76.379}
          y2={308.061}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE3BD" />
          <stop offset={0.626} stopColor="#FFF4E5" />
        </linearGradient>
        <linearGradient
          id="doctor-with-stethoscope_svg__c"
          x1={164}
          x2={164}
          y1={220.004}
          y2={319}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <clipPath id="doctor-with-stethoscope_svg__a">
          <path fill="#fff" d="M0 0h328v317H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgDoctorWithStethoscope;
