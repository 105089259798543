import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgArrowLeft = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4.1 12a.9.9 0 0 1 .9-.9h14a.9.9 0 0 1 0 1.8H5a.9.9 0 0 1-.9-.9"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4.364 11.364a.9.9 0 0 1 1.272 0l6 6a.9.9 0 0 1-1.272 1.272l-6-6a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M11.636 5.364a.9.9 0 0 1 0 1.272l-6 6a.9.9 0 1 1-1.272-1.272l6-6a.9.9 0 0 1 1.272 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgArrowLeft;
