import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDatabaseExport = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5.226 5.506c-.294.26-.326.43-.326.494s.032.234.326.494c.293.26.768.535 1.433.785C7.982 7.775 9.87 8.1 12 8.1c2.13 0 4.018-.325 5.34-.821.666-.25 1.141-.525 1.434-.785.294-.26.326-.43.326-.494s-.032-.234-.326-.494c-.293-.26-.768-.535-1.433-.785C16.018 4.225 14.13 3.9 12 3.9c-2.13 0-4.018.325-5.34.821-.666.25-1.141.525-1.434.785m.801-2.47C7.6 2.446 9.711 2.1 12 2.1c2.289 0 4.4.346 5.973.936.783.294 1.479.665 1.995 1.123.516.457.932 1.077.932 1.841s-.416 1.384-.932 1.841c-.516.458-1.212.83-1.995 1.123-1.573.59-3.684.936-5.973.936-2.289 0-4.4-.346-5.973-.936-.783-.294-1.479-.665-1.995-1.123C3.516 7.384 3.1 6.764 3.1 6s.416-1.384.932-1.841c.516-.458 1.212-.83 1.995-1.123"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M20.09 5c-.503 0-.91.41-.91.917v8.148c0 .506.407.917.91.917.503 0 .91-.41.91-.917V5.917A.913.913 0 0 0 20.09 5M3.91 5c-.503 0-.91.41-.91.917v6.11c0 .779.42 1.41.942 1.876.522.467 1.226.845 2.018 1.144 1.59.6 3.726.953 6.04.953 1.113 0 2.18-.082 3.16-.231a.916.916 0 0 0 .764-1.044.911.911 0 0 0-1.036-.769c-.887.135-1.861.21-2.888.21-2.154 0-4.063-.33-5.4-.836-.673-.254-1.154-.534-1.45-.799-.298-.265-.33-.438-.33-.503V5.917A.913.913 0 0 0 3.91 5"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M3.939 11a.928.928 0 0 0-.939.917v6.11c0 .779.434 1.41.972 1.876.538.467 1.264.845 2.08 1.144 1.64.6 3.843.953 6.23.953.272 0 .544-.004.814-.014a.926.926 0 0 0 .903-.95.93.93 0 0 0-.972-.882c-.243.009-.493.013-.746.013-2.22 0-4.19-.332-5.57-.837-.693-.254-1.188-.534-1.494-.799-.307-.265-.34-.438-.34-.503v-6.111A.928.928 0 0 0 3.94 11M18.36 15.27a.925.925 0 0 0 0 1.306l1.497 1.5h-4.936A.922.922 0 0 0 14 19c0 .51.412.923.92.923h4.937l-1.497 1.501a.925.925 0 0 0 0 1.306c.36.36.943.36 1.302 0l3.068-3.077a.92.92 0 0 0 0-1.306l-3.068-3.077a.919.919 0 0 0-1.302 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgDatabaseExport;
