import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgFrame = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.364 2.364a.9.9 0 0 1 1.272 0l18 18a.9.9 0 1 1-1.272 1.272l-18-18a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7.1 4a.9.9 0 0 1 .9-.9h1a.9.9 0 0 1 .636.264L12.373 6.1H19A2.9 2.9 0 0 1 21.9 9v8a.9.9 0 1 1-1.8 0V9A1.1 1.1 0 0 0 19 7.9h-7a.9.9 0 0 1-.636-.264L8.627 4.9H8a.9.9 0 0 1-.9-.9m-2.109-.194a.9.9 0 0 1-.446 1.192A1.1 1.1 0 0 0 3.9 6v11A1.1 1.1 0 0 0 5 18.1h14a.9.9 0 1 1 0 1.8H5A2.9 2.9 0 0 1 2.1 17V6a2.9 2.9 0 0 1 1.7-2.64.9.9 0 0 1 1.191.446"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgFrame;
