import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPill = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M16 3.95a4.05 4.05 0 0 0-2.864 1.186l-8 8a4.05 4.05 0 0 0 5.728 5.728l8-8A4.05 4.05 0 0 0 16 3.95M3.864 11.864a5.85 5.85 0 0 0 8.272 8.272l8-8a5.85 5.85 0 1 0-8.272-8.272z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M7.864 7.864a.9.9 0 0 1 1.272 0l7 7a.9.9 0 0 1-1.272 1.272l-7-7a.9.9 0 0 1 0-1.272"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPill;
